import { type StaticImageData } from 'next/image'
import { type FC, type JSX } from 'react'

import { StaticImage } from '../image'

import FacebookIcon from './facebook.svg'
import InstagramIcon from './instagram.svg'
import MailIcon from './mail.svg'
import PinterestIcon from './pinterest.svg'
import TwitterIcon from './twitter.svg'
import WhatsAppIcon from './whatsapp.svg'
import YoutubeIcon from './youtube.svg'

export enum SOCIAL_MEDIA_NAMES {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  MAIL = 'mail',
  PINTEREST = 'pinterest',
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
  YOUTUBE = 'youtube',
}

const ICON_MAP: Record<SOCIAL_MEDIA_NAMES, StaticImageData> = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  mail: MailIcon,
  pinterest: PinterestIcon,
  twitter: TwitterIcon,
  whatsapp: WhatsAppIcon,
  youtube: YoutubeIcon,
}

type SocialMediaImageProps = {
  iconName: SOCIAL_MEDIA_NAMES
}
export const SocialMediaImage: FC<SocialMediaImageProps> = ({
  iconName,
}): JSX.Element | null => {
  return (
    <StaticImage
      alt={iconName}
      className="hover:brightness-90 hover:saturate-100"
      height="44"
      src={ICON_MAP[iconName]}
      width="44"
    />
  )
}
