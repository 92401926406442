'use client'

import { clsx } from 'clsx'
import { type FC } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import { FooterLegalTextIframe } from './FotterLegalTextIframe'

interface FooterLegalTextProps {
  className?: string
  legalTextIframeSrc: string
}

const FooterLegalText: FC<FooterLegalTextProps> = ({
  className = '',
  legalTextIframeSrc,
}) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  })

  return (
    <div className={clsx('flex justify-center', className)} ref={ref}>
      {isIntersecting ? (
        <FooterLegalTextIframe legalTextIframeSrc={legalTextIframeSrc} />
      ) : null}
    </div>
  )
}

export { FooterLegalText }
