import { clsx } from 'clsx'
import { type FC, useState } from 'react'

import { IconProfileUserAvatar } from '@redteclab/icons'

import { useGlobalConfigContext } from '../../../../../global-config'
import {
  urlResolverGetAccount,
  urlResolverGetLogin,
} from '../../../../../url-handling'
import { useUserApiSwrSessionV1Customer } from '../../../../../user-data'

import { HeaderProfileFlyout } from './HeaderProfileFlyout'
import { HeaderProfileLoginStatusDependentLabel } from './HeaderProfileLoginStatusDependentLabel'

const HeaderProfile: FC = () => {
  const { data: userData } = useUserApiSwrSessionV1Customer()
  const globalConfig = useGlobalConfigContext()

  const isLoggedIn = Boolean(userData?.customerNumber)
  const [showFlyout, setShowFlyout] = useState<boolean>(false)

  return (
    <>
      {
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          className="relative z-50 flex"
          onMouseEnter={(): void => {
            setShowFlyout(true)
          }}
          onMouseLeave={(): void => {
            setShowFlyout(false)
          }}
        >
          <a
            className="flex flex-col items-center justify-end px-1.5"
            data-qa-id="account-button"
            href={
              isLoggedIn
                ? urlResolverGetAccount(globalConfig)
                : urlResolverGetLogin(globalConfig)
            }
          >
            <IconProfileUserAvatar
              className={clsx(
                'mb-2 size-6 fill-dark-primary-max',
                showFlyout
                  ? 'fill-light-primary-strong'
                  : 'fill-dark-primary-max',
              )}
            />
            <HeaderProfileLoginStatusDependentLabel
              isLoggedIn={isLoggedIn}
              showFlyout={showFlyout}
            />
          </a>
          {showFlyout ? <HeaderProfileFlyout isLoggedIn={isLoggedIn} /> : null}
        </li>
      }
      <div
        className={clsx(
          'fixed left-0 top-0 z-40 size-full bg-backdrop transition-all',
          showFlyout ? 'block' : 'hidden',
        )}
      />
    </>
  )
}

export { HeaderProfile }
