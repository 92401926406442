'use client'

import { ClickAwayListener } from '@mui/base'
import { clsx } from 'clsx'
import { type FC, useEffect, useState } from 'react'

import { useDisableMobileBodyScroll } from '../../../../../../generic-hooks'
import { useOffCanvasHeaderMenuContext } from '../../../OffCanvasHeaderMenuContext'

import { OffCanvasHeaderMenuRootSlide } from './OffCanvasHeaderMenuRootSlide'
import { OffCanvasHeaderMenuSlide } from './OffCanvasHeaderMenuSlide'

const FIRST_SLIDE_Z_INDEX = 1
const ANIMATION_SLIDE_DELAY = 10
const ANIMATION_DURATION = 300

const useSlidingTransition = (
  menuHasOpenState: boolean,
): {
  isSlidingIn: boolean
  isVisible: boolean
} => {
  /*
   * transition does not work on hidden elements
   * we need to display it before starting the transition, and we need to wait for animation to end before hiding it
   */
  const [isVisible, setIsVisible] = useState(false)
  // we need to delay the transition a bit so the element will be displayed
  const [isSlidingIn, setIsSlidingIn] = useState(false)

  useEffect(() => {
    if (menuHasOpenState) {
      setIsVisible(menuHasOpenState)
      setTimeout(() => {
        setIsSlidingIn(menuHasOpenState)
      }, ANIMATION_SLIDE_DELAY)

      return
    }

    setTimeout(() => {
      setIsVisible(menuHasOpenState)
    }, ANIMATION_DURATION)
    setIsSlidingIn(menuHasOpenState)
  }, [isVisible, menuHasOpenState])

  return {
    isSlidingIn,
    isVisible,
  }
}

export const OffCanvasHeaderMenu: FC = () => {
  const offCanvasHeaderMenuContext = useOffCanvasHeaderMenuContext()
  const { isOpen } = offCanvasHeaderMenuContext.openControls
  const animationControls = useSlidingTransition(isOpen)

  useDisableMobileBodyScroll(isOpen)

  const onClickAway = (): void => {
    if (isOpen) {
      offCanvasHeaderMenuContext.openControls.close()
    }
  }

  const menu = (
    <nav
      aria-hidden={!isOpen}
      className={clsx(
        'fixed left-0 top-0 z-20 flex h-full w-10/12 overflow-hidden border-r border-r-light-primary-low bg-light-primary-low transition-transform duration-300 ease-in-out',
        !animationControls.isVisible && 'hidden',
        animationControls.isSlidingIn ? 'translate-x-0' : '-translate-x-full',
      )}
    >
      <OffCanvasHeaderMenuRootSlide />
      {offCanvasHeaderMenuContext.slidesControls.slides.map(
        (cmsMenuNode, index) => {
          return (
            <OffCanvasHeaderMenuSlide
              key={cmsMenuNode.caption}
              menuNode={cmsMenuNode}
              zIndex={FIRST_SLIDE_Z_INDEX + index}
            />
          )
        },
      )}
    </nav>
  )

  if (!isOpen) {
    return menu
  }

  return (
    <>
      <div
        className={clsx(
          'absolute left-0 top-0 z-10 h-screen w-full bg-backdrop transition-opacity duration-300',
          animationControls.isSlidingIn ? 'opacity-100' : 'opacity-0',
        )}
      />
      <ClickAwayListener onClickAway={onClickAway}>{menu}</ClickAwayListener>
    </>
  )
}
