import { clsx } from 'clsx'
import {
  type AnchorHTMLAttributes,
  type FC,
  type PropsWithChildren,
} from 'react'

type TagProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  hasBorder?: boolean
}

export const Tag: FC<PropsWithChildren<TagProps>> = ({
  children,
  className,
  hasBorder = false,
  ...rest
}) => (
  <a
    {...rest}
    className={clsx(
      'inline-block py-1 text-xs text-dark-primary-max hover:text-dark-hover-brand',
      hasBorder &&
        'rounded-full border border-dark-primary-min px-3 hover:border-dark-hover-brand',
      className,
    )}
  >
    {children}
  </a>
)
