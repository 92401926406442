import { type FC } from 'react'

import { useGlobalConfigContext } from '../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../image'
import { assetUrlGetPrefixed } from '../../../../../url-handling'

import { type FooterFixedSizeIllustrationProps } from './FooterFixedSizeIllustrationProps'

export type FooterFixedSizeIllustrationType =
  | 'fastDelivery'
  | 'freeDelivery'
  | 'ownBrands'
  | 'productRange'
  | 'ratings'

const getIllustrationPath = (
  assetsPrefix: string,
  iconName?: FooterFixedSizeIllustrationType,
): string => {
  let namePart = ''

  switch (iconName) {
    case 'fastDelivery': {
      namePart = 'hexagon-fast-delivery'
      break
    }
    case 'freeDelivery': {
      namePart = 'hexagon-free-delivery'
      break
    }
    case 'ownBrands': {
      namePart = 'own-brands'
      break
    }
    case 'productRange': {
      namePart = 'hexagon-huge-product-range'
      break
    }
    case 'ratings': {
      namePart = 'ratings'
      break
    }
    default: {
      break
    }
  }

  return namePart
    ? assetUrlGetPrefixed(
        assetsPrefix,
        `/images/illustrations/illustration-${namePart}.png`,
      )
    : ''
}

const FooterFixedSizeIllustration: FC<FooterFixedSizeIllustrationProps> = ({
  name,
}) => {
  const { assetsPrefix } = useGlobalConfigContext()

  return (
    <NextImageWithFallback
      alt={name ?? ''}
      fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
      height={100}
      src={getIllustrationPath(assetsPrefix, name)}
      width={100}
    />
  )
}

export { FooterFixedSizeIllustration }
