export const EXPERIMENTS_TRACK_EVENT_PDP_CSW_PRODUCT = `Click on Product of CSW`
export const EXPERIMENTS_TRACK_EVENT_CTA_BUTTON_ON_ALL_CSW = `Click on add to cart button on CSW`
export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_OVERVIEW_DOWNLOAD_INVOICE = `Account Overview - Click on download invoice`
export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_OVERVIEW_HELP_WITH_MY_ORDER = `Account Overview - Click on help with my order`
export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_OVERVIEW_LAST_ORDER_DETAIL = `Account Overview - Click on last order detail`

export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_OVERVIEW_LAST_ORDER_TRACKING = `Account Overview - Click on last order tracking`

export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_OVERVIEW_VIEW_ALL_ORDERS = `Account Overview - Click on view all orders`
export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_MY_DATA_LINK = `Account Overview - Click on my data section link`
export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_SERVICES_LINK = `Account Overview - Click on services section link`

export const EXPERIMENTS_TRACK_EVENT_ACCOUNT_VISITOR = `Account Overview unique visitor`

export const EXPERIMENTS_TRACK_EVENT_SEARCH_BOX_SUGGESTED_PRODUCT_CLICK =
  'SearchBox - Click on suggested product'

export const EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER =
  'HEADER - Click on advertising flyout banner'
export const EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER_CLOSED =
  'HEADER - Click on advertising flyout banner close icon'

export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_HISTORY =
  'SearchBox - Click on query history'
export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_PRODUCT =
  'SearchBox - Click on pre query suggested product'
export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_HISTORY_DELETE =
  'SearchBox - Click on history delete'
export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_CATEGORY =
  'SearchBox - Click on category suggestion'
export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_BRAND =
  'SearchBox - Click on brand suggestion'
export const EXPERIMENTS_TRACK_EVENT_SERP_PRE_QUERY_SUGGESTIONS_POPULAR_QUERY =
  'SearchBox - Click on popular query suggestion'

export const EXPERIMENTS_TRACK_EVENT_AFTER_QUERY_SUGGEST_LAYER_QUERY_SUGGESTION =
  'SearchBox - Click on auto suggest query suggestion'
export const EXPERIMENTS_TRACK_EVENT_AFTER_QUERY_SUGGEST_LAYER_PRODUCT =
  'SearchBox - Click on suggested product'

export const EXPERIMENTS_TRACK_EVENT_PDP_STOCK_INFORMATION =
  'PDP - Click on availability status for more detailed info'
export const EXPERIMENTS_TRACK_EVENT_PDP_ADD_TO_CART_MP_PRODUCT =
  'PDP - Click add-2-cart MP Product'
export const EXPERIMENTS_TRACK_EVENT_PDP_SHOW_SELLER_MODAL =
  'PDP - Click show seller modal'

export const EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLOSE_MODAL =
  'Exit Intent popup closed'
export const EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLICK =
  'Exit Intent popup clicked'

export const EXPERIMENTS_TRACK_EVENT_ERX_HERO_REDEEM_PRESCRIPTION =
  'ERX - Hero. Click on redeem prescription now'
export const EXPERIMENTS_TRACK_EVENT_ERX_CONTENT_REDEEM_PRESCRIPTION =
  'ERX - Content. Click on redeem prescription now'
export const EXPERIMENTS_TRACK_EVENT_ERX_MORE_INFORMATION =
  'ERX - Click on more information'
export const EXPERIMENTS_TRACK_EVENT_BREADCRUMBS = 'PDP - Click on breadcumb'
export const EXPERIMENTS_TRACK_EVENT_FREE_GIFT_PROMOTION_ADD_TO_CART =
  'Click on add-to-cart button'
export const EXPERIMENTS_TRACK_EVENT_STICKY_BUY_BOX = `Click on sticky buybox CTA`
export const EXPERIMENTS_TRACK_EVENT_PDP_DELIVERY_OPTIONS_OTC =
  'PDP - Delivery options - OTC'
export const EXPERIMENTS_TRACK_EVENT_PDP_DELIVERY_OPTIONS_NOW_DELIVERY =
  'PDP - Delivery options - Now delivery'
export const EXPERIMENTS_TRACK_EVENT_PDP_DELIVERY_OPTIONS_NOW_CC =
  'PDP - Delivery options - Now CC'

export const EXPERIMENTS_TRACK_EVENT_NFC_CTA_CLICK = 'NFC LP - CTA click'

export const EXPERIMENTS_TRACK_EVENT_NL_MODAL_SUBMIT = 'Newsletter modal submit'
export const EXPERIMENTS_TRACK_EVENT_NL_MODAL_CLOSED = 'Newsletter modal closed'
export const EXPERIMENTS_TRACK_EVENT_NL_MODAL_INPUT_CLICKED =
  'Newsletter modal input field clicked'
export const EXPERIMENT_TRACK_EVENT_OWN_BRANDS_OVERLAY_ADD_TO_CART =
  'Overlay - Click on add-2-cart CTA'
export const EXPERIMENT_TRACK_EVENT_OWN_BRANDS_OVERLAY_CLICK_ON_CLOSE =
  'Overlay - Click on close'
export const EXPERIMENT_TRACK_EVENT_CLICK_ON_CTA =
  'Overlay - Click on CTA button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_CHATBOT_WIDGET_CTA =
  'Contact Page - Click on Chatbot CTA'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_WIDGET_CTA =
  'Contact Page - Click on FAQ CTA'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_ORDERS_FIRST =
  'Contact Page - Click first order'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_ORDERS_SECOND =
  'Contact Page - Click second order'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_ORDERS_THIRD =
  'Contact Page - Click third order'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_CALLBACK_WIDGET =
  'Contact Page - Click callback widget'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_PHONE_WIDGET =
  'Contact Page - Click phone widget'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_EMAIL_FORM_WIDGET =
  'Contact Page - Click email and form widget'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_EMAIL_CLICK =
  'Contact Page - Click email on form modal'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_SEND_FORM_CLICK =
  'Contact Page - Click send message on form modal'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_POST_WIDGET =
  'Contact Page - Click postal address widget'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CTA =
  'Contact Page - Click on all questions FAQ CTA'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_CANCEL_ORDER =
  'Contact Page - Click on FAQ card cancel order'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_SHIPPING_COST =
  'Contact Page - Click on FAQ card shipping cost'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_DELIVERY_INFO =
  'Contact Page - Click on FAQ card delivery info'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_DELIVERY_ZONES =
  'Contact Page - Click on FAQ card delivery zones'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_RETURN_ORDER =
  'Contact Page - Click on FAQ card return order'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_REDEEM_VOUCHER =
  'Contact Page - Click on FAQ card redeem voucher'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_FAQ_CARD_PAYMENT_METHODS =
  'Contact Page - Click on FAQ card payment methods'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_CHATBOT_TEXT =
  'Contact Page - Click on chatbot text button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_PHONE_TEXT =
  'Contact Page - Click on phone text button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_EMAIL_FORM_TEXT =
  'Contact Page - Click on email form text button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_CALLBACK_TEXT =
  'Contact Page - Click on callback text button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_POST_TEXT =
  'Contact Page - Click on postal address text button'

export const EXPERIMENTS_TRACK_EVENT_CONTACT_CALLBACK_CTA =
  'Contact Page - Click on callback section CTA'

export const EXPERIMENTS_TRACK_EVENT_PHARMA_CHATBOT_TEXT =
  'Contact Page - Click on open pharma chatbot text'

export const EXPERIMENTS_TRACK_EVENT_PHARMA_CHATBOT_CTA =
  'Contact Page - Click on open pharma chatbot CTA'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN =
  'HEADER - Click on account icon logged in'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN =
  'HEADER - Click on account icon not logged in'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON =
  'HEADER - Click on search icon'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON =
  'HEADER - Click on cart icon'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO =
  'HEADER - Click on logo'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER =
  'HEADER - Click on burger menu'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN =
  'HEADER - Click on my account inside burger logged in'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN =
  'HEADER - Click on my account inside burger not logged in'
