'use client'

import { useEffect } from 'react'

export const useDisableMobileBodyScroll = (isDisabled: boolean): void => {
  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return undefined
    }

    if (isDisabled) {
      body.classList.add('overflow-hidden', 'tablet:overflow-auto')
    }

    return (): void => {
      body.classList.remove('overflow-hidden', 'tablet:overflow-auto')
    }
  }, [isDisabled])
}
