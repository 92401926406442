'use client'

import { mutate, type SWRResponse } from 'swr'
import useImmutableSwr from 'swr/immutable'

import {
  CartControllerV4ApiFactory,
  type CartMiniCartV4,
} from '@redteclab/api/clients/bully'

import {
  buildClientSideBullyRequestConfig,
  useBullyApi,
} from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const userApiSwrGetMiniCartKey = (): string => 'useUserApiSwrGetMiniCart'

/**
 * fetches a number of cart entries of current user
 */
export const useUserApiSwrGetMiniCart = (): SWRResponse<CartMiniCartV4> => {
  const globalConfig = useGlobalConfigContext()
  const client = useBullyApi(CartControllerV4ApiFactory)

  const fetcher = async (): Promise<CartMiniCartV4> => {
    const response = await client.languageCartV4TenantMinicartGet(
      {
        language: globalConfig.language,
        tenant: globalConfig.tenantAndEnv,
      },
      buildClientSideBullyRequestConfig(globalConfig),
    )

    return response.data
  }

  return useImmutableSwr(userApiSwrGetMiniCartKey(), fetcher)
}

/**
 * triggers refetching of any active swr for the query
 */
export const userApiSwrRefetchMiniCart = async (): Promise<
  CartMiniCartV4 | undefined
> => {
  return mutate<CartMiniCartV4>(userApiSwrGetMiniCartKey())
}
