import { type ComponentType, type FC, type SVGProps } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconPrescription } from '@redteclab/icons'

import { useExpaContext } from '../../../../expa'
import { IconPrimaryArrow } from '../../../../icons'
import { type HeaderExtensionData } from '../../config/PerTenantHeaderConfiguration.type'

const getHeaderExtensionIcon = (
  icon: HeaderExtensionData['icon'],
): ComponentType<SVGProps<SVGSVGElement>> | null => {
  if (!icon) {
    return null
  }

  switch (icon) {
    case 'prescription': {
      return IconPrescription
    }
    case 'primaryArrow': {
      return IconPrimaryArrow
    }
    default: {
      return null
    }
  }
}

export const HeaderExtension: FC<{
  headerExtension: HeaderExtensionData | null
}> = ({ headerExtension }) => {
  const expa = useExpaContext()

  if (!headerExtension) {
    return null
  }

  const { hideWhenExpa, href, icon } = headerExtension

  /**
   * usecase e.g. for CH - when gglp hide prescription ribbon
   * https://jira.shop-apotheke.com/browse/WSAWA-5470
   */
  if (expa && hideWhenExpa?.includes(expa)) {
    return null
  }

  const Icon = getHeaderExtensionIcon(icon)

  return (
    <a
      className="sticky top-[58px] z-[19] flex min-h-[35px] items-center bg-light-secondary-high tablet:top-[72px]"
      href={href}
    >
      <div className="mx-auto inline-flex w-full max-w-5xl items-start px-3.5 py-0.5">
        {Icon ? <Icon className="mr-1 shrink-0 text-2xl" /> : null}
        <span className="hidden py-0.5 tablet:inline-block">
          <FormattedMessage id="header.extension.long" />
        </span>
        <span className="inline-block py-0.5 tablet:hidden">
          <FormattedMessage id="header.extension.short" />
        </span>
      </div>
    </a>
  )
}
