import { clsx } from 'clsx'
import { type ComponentProps, forwardRef, type ReactElement } from 'react'

type DividerProps = Omit<ComponentProps<'div'>, 'children'> & {
  /**
   * The content of the component
   */
  children?: ReactElement | number | string | null

  /**
   * If `true`, the divider will have a darker color
   */
  dark?: boolean
}

/**
 * A divider is a thin line that groups content in lists and layouts
 */
export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  function Divider(props, ref) {
    const { children, className, dark } = props

    if (!children) {
      return <hr className="divider" />
    }

    return (
      <div
        {...props}
        className={clsx(
          'divider divider_text',
          dark && 'divider_dark',
          className,
        )}
        ref={ref}
      >
        {children}
      </div>
    )
  },
)
