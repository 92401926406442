import {
  type ModalTypeMap,
  Modal as MuiModal,
  type PolymorphicComponent,
  type PolymorphicProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

import { ModalBackdrop } from './ModalBackdrop'

export type ModalProps<
  RootComponentType extends ElementType = ModalTypeMap['defaultComponent'],
> = PolymorphicProps<ModalTypeMap, RootComponentType>

type ModalComponent = PolymorphicComponent<ModalTypeMap>

/**
 * The Modal component lets you create dialogs, drawers, popovers, lightboxes, and other elements that force the user to take action before continuing.
 */
export const Modal = forwardRef(function Modal(
  props: ModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className, slots } = props

  return (
    <MuiModal
      {...props}
      className={clsx('modal', className)}
      ref={ref}
      slots={{ backdrop: ModalBackdrop, ...slots }}
    />
  )
}) as ModalComponent
