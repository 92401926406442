import { clsx } from 'clsx'
import { type FC } from 'react'

import {
  type CmsComponentMenu,
  type CmsComponentMenuAction,
  CmsComponentMenuActionSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

import { useGlobalConfigContext } from '../../../../../../../global-config'
import {
  headerCmsComponentMenuActionAccessorIsExpandable,
  headerCmsComponentMenuActionAccessorRootNodeHrefToNodeName,
} from '../../../../../model/headerCmsComponentMenuActionAccessor'
import { useOffCanvasHeaderMenuContext } from '../../../../OffCanvasHeaderMenuContext'
import { OffCanvasHeaderMenuListItem } from '../OffCanvasHeaderMenuListItem'

interface OffCanvasHeaderMenuRootSlideMenuNodeRendererProps {
  cmsComponentMenu: CmsComponentMenu
  isHighlighted: boolean
  isSecond: boolean
}

/**
 * reurns true if the item should NOT be rendered
 */
const itemShallNotBeRendered = (item: CmsComponentMenuAction): boolean =>
  item.href === '/abo-produkte/'

export const OffCanvasHeaderMenuRootSlideMenuNodeRenderer: FC<
  OffCanvasHeaderMenuRootSlideMenuNodeRendererProps
> = ({ cmsComponentMenu, isHighlighted, isSecond }) => {
  const offCanvasHeaderMenuContext = useOffCanvasHeaderMenuContext()
  const { languages } = useGlobalConfigContext()

  return (
    <ul
      className={clsx(
        isHighlighted && 'bg-light-primary-low',
        isSecond && 'grow',
      )}
    >
      {cmsComponentMenu.items?.map((item) => {
        if (
          item.schema !==
          CmsComponentMenuActionSchemaEnum.CmsComponentMenuAction
        ) {
          return null
        }

        if (itemShallNotBeRendered(item)) {
          return null
        }

        const isExpandable =
          headerCmsComponentMenuActionAccessorIsExpandable(item)

        return (
          <OffCanvasHeaderMenuListItem
            className={clsx(!isHighlighted && 'font-bold')}
            iconType={item.icon}
            isExpandable={isExpandable}
            key={item.title}
            link={item.href}
            onExpandClick={(event): void => {
              if (!isExpandable) {
                return
              }
              event.preventDefault()
              offCanvasHeaderMenuContext.slidesControls.goNextSlide(
                {
                  /**
                   * on root slide nodes are fetched by category path
                   */
                  categoryPath:
                    headerCmsComponentMenuActionAccessorRootNodeHrefToNodeName({
                      item,
                      languages,
                    }),
                },
                item.href,
              )
            }}
            title={item.title}
          />
        )
      })}
    </ul>
  )
}
