import { clsx } from 'clsx'
import { type FC } from 'react'

import { FooterAppStores } from './FooterAppStores'
import { FooterSocial } from './FooterSocial/FooterSocial'
import { type FooterSocialAndAppsProps } from './FooterSocialAndAppsProps'

const FooterSocialAndApps: FC<FooterSocialAndAppsProps> = ({
  appLinksItems,
  className = '',
  socialLinksItems,
}) => {
  return (
    <div
      className={clsx(
        'mb-3.5 flex flex-wrap items-center justify-between border-b border-b-dark-primary-min',
        className,
      )}
    >
      <FooterSocial socialLinksItems={socialLinksItems} />
      <FooterAppStores appLinksItems={appLinksItems} />
    </div>
  )
}

export { FooterSocialAndApps }
