'use client'

import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
} from 'react'

import { type AppsFlyerBannerConfig } from '../config/getAppsFlyerConfig'

type AppsFlyerContextValue = {
  config: AppsFlyerBannerConfig
}

const AppsFlyerContext = createContext<AppsFlyerContextValue | null>(null)

export const AppsFlyerContextProvider: FC<{
  children: ReactNode
  config: AppsFlyerBannerConfig
}> = ({ children, config }) => {
  const value = useMemo(() => ({ config }), [config])

  return (
    <AppsFlyerContext.Provider value={value}>
      {children}
    </AppsFlyerContext.Provider>
  )
}

export const useAppFlyerContext = (): AppsFlyerContextValue => {
  const context = useContext(AppsFlyerContext)
  if (!context) {
    throw new Error('APP_FLYER_CONTEXT_NOT_FOUND')
  }

  return context
}
