import { clsx } from 'clsx'
import { type FC } from 'react'

import { type CmsIcons } from '@redteclab/api/clients/content-management-system'

import { HeaderInlineIcon } from '../HeaderInlineIcon'

import styles from './HeaderCategoriesMenuIconButton.module.css'

export const HeaderCategoriesMenuIconButton: FC<{
  icon: CmsIcons | undefined
  menuIsShown: boolean
  title: string | undefined
}> = ({ icon, menuIsShown, title }) => {
  return (
    <button
      // we need z-index so the categories button will be above the loading indicator
      className={clsx(
        'relative z-10 flex h-14 items-center fill-dark-primary-max pr-3.5 font-medium text-dark-primary-max hover:fill-dark-hover-brand hover:text-dark-hover-brand',
        menuIsShown && styles['header-categories-menu-icon-button__arrow-up'],
      )}
      data-qa-id="form-categories"
      type="button"
    >
      <HeaderInlineIcon className="m-3.5 text-3xl" icon={icon} />
      {title}
    </button>
  )
}
