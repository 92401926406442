import { type FC } from 'react'

import {
  type CmsBlockMenuItemsInner,
  CmsComponentMenuActionSchemaEnum,
  CmsComponentMenuSchemaEnum,
  CmsComponentMenuSeparatorSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

import { HeaderCategoriesMenu } from './HeaderCategoriesMenu'
import { HeaderInlineIcon } from './HeaderInlineIcon'

export const HeaderNavigationItemRenderer: FC<{
  item: CmsBlockMenuItemsInner
}> = ({ item }) => {
  switch (item.schema) {
    case CmsComponentMenuActionSchemaEnum.CmsComponentMenuAction: {
      return (
        <li>
          <a
            className="group flex h-14 items-center whitespace-nowrap fill-dark-primary-max px-3.5 font-medium text-dark-primary-max hover:fill-dark-hover-brand hover:text-dark-hover-brand"
            href={item.href}
          >
            <HeaderInlineIcon className="mr-2 text-3xl" icon={item.icon} />
            {item.title}
          </a>
        </li>
      )
    }
    case CmsComponentMenuSchemaEnum.CmsComponentMenu: {
      return (
        <li>
          <HeaderCategoriesMenu categoriesData={item} />
        </li>
      )
    }
    case CmsComponentMenuSeparatorSchemaEnum.CmsComponentMenuSeparator: {
      return <li className="my-4 border-r border-dark-primary-max" />
    }
    default: {
      return null
    }
  }
}
