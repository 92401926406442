import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { type MetadataContent } from '@redteclab/api/clients/adserver-api'

import { AD_BANNER_LOCAL_STORAGE_USER_CLOSED_FLAG_KEY } from './adServerConst'

/**
 * Controls if the banner shall be shown and handles the case when user closes it.
 * when user closes banner, it's flight id is written to local storage,
 * if banners flight id flagged in local storage, banner shall not be shown.
 */
export const useAdServerCloseBannerControls = ({
  metaDataContent,
}: {
  metaDataContent: MetadataContent | undefined
}): {
  handleClose: () => void
  showBanner: boolean
  showCloseButton: boolean
} => {
  const [showBanner, setShowBanner] = useState(true)

  const [userClosedBannerFlag, setUserClosedBannerFlag] = useLocalStorage(
    AD_BANNER_LOCAL_STORAGE_USER_CLOSED_FLAG_KEY,
    '',
    {
      initializeWithValue: false,
    },
  )

  const flagUserClosedBanner = useCallback(
    (flightId: string): void => {
      const adBannerStorageNewValue = userClosedBannerFlag
        ? `${userClosedBannerFlag},${flightId}`
        : flightId

      setUserClosedBannerFlag(adBannerStorageNewValue)
    },
    [setUserClosedBannerFlag, userClosedBannerFlag],
  )

  const handleClose = useCallback((): void => {
    setShowBanner(false)

    if (metaDataContent) {
      flagUserClosedBanner(`${metaDataContent.flightId}`)
    }
  }, [flagUserClosedBanner, metaDataContent, setShowBanner])

  const userHadClosedBanner = useCallback(
    (flightId: string): boolean => {
      if (Array.isArray(userClosedBannerFlag)) {
        return userClosedBannerFlag.includes(flightId)
      }

      return userClosedBannerFlag === flightId
    },
    [userClosedBannerFlag],
  )

  useEffect(() => {
    if (!metaDataContent) {
      return
    }

    const shouldShowBanner = !userHadClosedBanner(`${metaDataContent.flightId}`)
    if (showBanner !== shouldShowBanner) {
      setShowBanner(shouldShowBanner)
    }
  }, [metaDataContent, showBanner, userHadClosedBanner])

  return {
    handleClose,
    showBanner,
    showCloseButton: metaDataContent?.customData?.closeButton ?? false,
  }
}
