import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { useHeaderDataContext } from '../../standard-shop-layout/header/headerConfigContext'
import { TENANT } from '../../tenant/tenantTypes'

export const HeaderMobileIconListExperimentLogo: FC = () => {
  const publicConfig = useGlobalConfigContext()
  const { tenant } = publicConfig
  const headerData = useHeaderDataContext()

  const logo = headerData.shopLogos.mobile

  const IMAGE_HEIGHT_BELGIUM = 52

  return (
    <Image
      alt="logo"
      className={clsx(tenant === TENANT.AT && 'mt-1')}
      height={tenant === TENANT.BE ? IMAGE_HEIGHT_BELGIUM : undefined}
      src={logo}
    />
  )
}
