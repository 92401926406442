'use client'

import { clsx } from 'clsx'
import { type FC, useEffect, useMemo, useState } from 'react'

import { IconClose } from '@redteclab/icons'

import { useExperimentsContext } from '../../experiments/components/ExperimentsContext'
import {
  EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER,
  EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER_CLOSED,
  experimentsTrackEvent,
} from '../../experiments/eventTracking'
import { EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER } from '../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../experiments/model/EXPERIMENT_VARIATION'
import { experimentAccessorGetExperimentData } from '../../experiments/model/experimentAccessor'
import { useGlobalConfigContext } from '../../global-config'
import { urlResolverGetSearchPage } from '../../url-handling'

import { AdvertisingFlyoutBannerButton } from './AdvertisingFlyoutBannerButton'
import { type AdvertisingFlyoutBannerConfiguration } from './config/getAdvertisingFlyoutBannerConfigurationByTenant'
import { ImageWithLink } from './ImageWithLink'

const EXECUTION_TIMEOUT = 15_000

const isBannerLogicExecuted = (): boolean => {
  try {
    return Boolean(
      sessionStorage.getItem(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER),
    )
  } catch {
    return true
  }
}

const setBannerLogicExecuted = (): void => {
  try {
    sessionStorage.setItem(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER, 'true')
  } catch {
    // noop
  }
}

type AdvertisingFlyoutBannerProps = {
  advertisingFlyoutBannerConfig: AdvertisingFlyoutBannerConfiguration | null
}

// eslint-disable-next-line max-lines-per-function
export const AdvertisingFlyoutBanner: FC<AdvertisingFlyoutBannerProps> = ({
  advertisingFlyoutBannerConfig,
}) => {
  const { experiments } = useExperimentsContext()
  const config = useGlobalConfigContext()

  const experimentData = useMemo(() => {
    const advertisingFlyoutBannerExperimentData =
      experimentAccessorGetExperimentData(
        experiments,
        EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER,
      )
    const isExperimentEnabled = advertisingFlyoutBannerExperimentData?.isEnabled
    const variant = advertisingFlyoutBannerExperimentData?.variant

    return {
      isExperimentEnabled,
      variant,
    }
  }, [experiments])

  const { isExperimentEnabled, variant } = experimentData
  const bannerLink = urlResolverGetSearchPage(config, {
    queryParams: {
      // eslint-disable-next-line id-length
      b: '1',
      query: 'redcare',
    },
  })
  const { addExperimentKeyToActivate } = useExperimentsContext()
  const [shouldDisplayButton, setShouldDisplayButton] = useState(false)
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(false)

  useEffect(() => {
    if (isBannerLogicExecuted()) {
      setShouldDisplayButton(true)

      return undefined
    }

    const buttonTimeout = window.setTimeout(() => {
      setShouldDisplayButton(true)
      addExperimentKeyToActivate(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(buttonTimeout)
    }
  }, [addExperimentKeyToActivate])

  useEffect(() => {
    if (isBannerLogicExecuted() || !shouldDisplayButton) {
      return undefined
    }

    const bannerShowTimeout = window.setTimeout(() => {
      setShouldDisplayButton(false)
      setShouldDisplayBanner(true)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(bannerShowTimeout)
    }
  }, [shouldDisplayButton])

  useEffect(() => {
    if (isBannerLogicExecuted() || !shouldDisplayBanner) {
      return undefined
    }

    const bannerHideTimeout = window.setTimeout(() => {
      setBannerLogicExecuted()

      setShouldDisplayButton(true)
      setShouldDisplayBanner(false)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(bannerHideTimeout)
    }
  }, [shouldDisplayBanner])

  if (
    !advertisingFlyoutBannerConfig ||
    !isExperimentEnabled ||
    variant === EXPERIMENT_VARIATION.DEFAULT
  ) {
    return undefined
  }

  return (
    <aside
      className={clsx(
        'fixed top-1/2 z-30 h-0',
        variant === EXPERIMENT_VARIATION.V2 && 'left-0',
        variant === EXPERIMENT_VARIATION.V1 && 'right-0',
      )}
    >
      {shouldDisplayButton ? (
        <AdvertisingFlyoutBannerButton
          className={clsx(
            variant === EXPERIMENT_VARIATION.V1 &&
              '-rotate-90 mobile-sm:translate-x-[44%] desktop:translate-x-[40%]',
            variant === EXPERIMENT_VARIATION.V2 &&
              'rotate-90 mobile-sm:translate-x-[-44%] desktop:translate-x-[-40%]',
          )}
          onClick={(): void => {
            setBannerLogicExecuted()
            setShouldDisplayBanner(true)
            setShouldDisplayButton(false)
          }}
        />
      ) : null}
      {shouldDisplayBanner ? (
        <div className="flex -translate-y-1/2 cursor-pointer flex-row items-center justify-end">
          <ImageWithLink
            link={bannerLink}
            onClick={(): void => {
              experimentsTrackEvent(
                config,
                EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER,
              )
              setBannerLogicExecuted()
            }}
            src={advertisingFlyoutBannerConfig.banner}
          />
          <IconClose
            className="absolute right-0 top-0 size-6 fill-dark-info-strong"
            onClick={(): void => {
              experimentsTrackEvent(
                config,
                EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER_CLOSED,
              )
              setBannerLogicExecuted()
              setShouldDisplayBanner(false)
              setShouldDisplayButton(true)
            }}
          />
        </div>
      ) : null}
    </aside>
  )
}
