import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, type FC } from 'react'

import { CircularProgress } from 'base-ui'

type LoadingProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'>

/**
 * Indicates the loading state, occupying all available space within the container element
 */
export const Loading: FC<LoadingProps> = (props) => {
  const { className, ...rest } = props

  return (
    <div className={clsx('loading', className)} {...rest}>
      <CircularProgress className="loading__progress" />
    </div>
  )
}
