'use client'

import { useCallback, useState } from 'react'

export type OpenableControls = {
  handleClose: () => void
  handleOpen: () => void
  handleToggle: () => void
  isOpen: boolean
}

/**
 * responsible for controlling generic state of components that have open/closed state
 *
 * @example
 * const accordionControls = useOpenableControls()
 * <Accordion isOpen={accordionControls.isOpen} onClose={accordionControls.handleClose}>
 *
 * const modalControls = useOpenableControls()
 * <Modal isOpen={modalControls.isOpen} onClose={modalControls.handleClose}>
 */
export const useOpenableControls = (
  initialState?: boolean,
): OpenableControls => {
  const [isOpen, setIsOpen] = useState(initialState ?? false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleToggle = useCallback(() => {
    setIsOpen((currentIsOPen) => !currentIsOPen)
  }, [])

  return {
    handleClose,
    handleOpen,
    handleToggle,
    isOpen,
  }
}
