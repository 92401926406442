import { usePathname } from 'next/navigation'
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useGlobalConfigContext } from '../../global-config'

type StickyBannerContextType = {
  forceHidden: boolean
  /**
   * this state is used by other components that need to be able to hide sticky banner, regardless of it's own visibility conditions
   * @remark other visibility conditions are in sticky banner itself
   */
  setForceHidden: (value: boolean) => void
}

const StickyBannerContext = createContext<null | StickyBannerContextType>(null)

export const StickyBannerContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const pathname = usePathname()
  const [forceHidden, setForceHidden] = useState(false)
  const prevPathnameRef = useRef(pathname)

  // reset forced hiding on pathname change
  useEffect(() => {
    // skip if path is same
    if (pathname === prevPathnameRef.current) {
      return
    }
    setForceHidden(false)
    prevPathnameRef.current = pathname
  }, [pathname])

  const value = useMemo(
    () => ({
      forceHidden,
      setForceHidden: (newValue: boolean): void => {
        if (newValue !== forceHidden) {
          setForceHidden(newValue)
        }
      },
    }),
    [forceHidden, setForceHidden],
  )

  return (
    <StickyBannerContext.Provider value={value}>
      {children}
    </StickyBannerContext.Provider>
  )
}

export const useStickyBannerContext = (): StickyBannerContextType | null => {
  const { deploymentStage } = useGlobalConfigContext()
  const context = useContext(StickyBannerContext)

  if (context === null) {
    if (deploymentStage === 'production') {
      throw new Error(
        'useStickyBannerContext must be used within StickyBannerContextProvider',
      )
    }

    return null
  }

  return context
}
