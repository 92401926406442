import {
  type SelectPopupSlotProps,
  type WithOptionalOwnerState,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
} from 'react'

export const Popup = forwardRef(function Popup<
  OptionValue extends NonNullable<unknown>,
  Multiple extends boolean,
>(
  props: ComponentPropsWithoutRef<'div'> &
    WithOptionalOwnerState<SelectPopupSlotProps<OptionValue, Multiple>>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- variable is not needed for rendering
    ownerState,
    ...rest
  } = props

  return <div {...rest} className={clsx('select-popup', className)} ref={ref} />
})
