'use client'

import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

import {
  type CmsComponentMenu,
  type FockNode,
} from '@redteclab/api/clients/content-management-system'

import {
  type CategoryOrCodeIdentifier,
  useHeaderApiSwrFetchHeaderMenuChildNodes,
} from '../api/swr/useHeaderApiSwrFetchHeaderMenuChildNodes'

type OffCanvasHeaderMenuContextValue = {
  openControls: {
    close: () => void
    handleMenuVisibilityToggle: () => void
    isOpen: boolean
    open: () => void
  }
  slidesControls: {
    goNextSlide: (
      identifierTuple: {
        categoryPath?: string
        code?: string
      },
      href: string,
    ) => void
    isLoading: boolean
    popSlide: () => void
    rootData: CmsComponentMenu[]
    slides: FockNode[]
  }
}

const OffCanvasHeaderMenuContext =
  createContext<OffCanvasHeaderMenuContextValue | null>(null)

/**
 * controls isOpen state for the off-canvas header menu
 */
const useOpenControls = (): OffCanvasHeaderMenuContextValue['openControls'] => {
  const [isOpen, setIsOpen] = useState(false)

  return useMemo(() => {
    return {
      close: (): void => {
        setIsOpen(false)
      },
      handleMenuVisibilityToggle: (): void => {
        setIsOpen(!isOpen)
      },
      isOpen,
      open: (): void => {
        setIsOpen(true)
      },
    }
  }, [isOpen])
}

/**
 * manages state of slides data and it's removal
 */
const useSlidesControls = (
  menuData: CmsComponentMenu[],
): OffCanvasHeaderMenuContextValue['slidesControls'] => {
  const [cache, setCache] = useState<Record<string, FockNode | undefined>>({})
  const [slides, setSlides] = useState<FockNode[]>([])
  /**
   * some nodes are fetched by categoryPath and some by code,
   * this one contains either of them and will be used by swr to fetch next slide node
   */
  const [identifierTuple, setIdentifierTuple] =
    useState<CategoryOrCodeIdentifier>()
  const [fallbackLinkOnFetchFail, setFallbackLinkOnFetchFail] =
    useState<string>()

  const fetchHeaderMenuChildNodesSwr = useHeaderApiSwrFetchHeaderMenuChildNodes(
    {
      identifierTuple,
      onDataLoaded: (identifier, fetchedNode: FockNode): void => {
        setCache({ ...cache, [identifier]: fetchedNode })
        setSlides([...slides, fetchedNode])
        setFallbackLinkOnFetchFail(undefined)
      },
      onError: () => {
        if (fallbackLinkOnFetchFail) {
          window.location.href = fallbackLinkOnFetchFail
        }
      },
    },
  )

  return useMemo(() => {
    /**
     * accepts categoryPath or code, if already fetched pushes it to the slides,
     * if not fetches and pushes it to the slides
     */
    const goNextSlide = (
      nextIdentifierTuple: CategoryOrCodeIdentifier,
      nextFallbackLinkOnFetchFail: string,
    ): void => {
      const identifier =
        nextIdentifierTuple.categoryPath ?? nextIdentifierTuple.code

      if (!identifier) {
        return
      }

      const nodeOnCache = cache[identifier]
      if (nodeOnCache) {
        setSlides([...slides, nodeOnCache])

        return
      }

      /**
       * setting identifierTuple (category or cms node code) on state, will trigger swr to fetch the data.
       */
      setIdentifierTuple(nextIdentifierTuple)
      /**
       * in case the new slide node fetch fails, user will be navigated to the category page he clicked.
       */
      setFallbackLinkOnFetchFail(nextFallbackLinkOnFetchFail)
    }

    return {
      /**
       * fetches or retrieves from cache the data for the node and adds it to slides
       */
      goNextSlide,
      /**
       * if anything is loading
       */
      isLoading: fetchHeaderMenuChildNodesSwr.isLoading,
      /**
       * aka, go back, removes the last node from slides
       */
      popSlide: (): void => {
        setSlides(slides.slice(0, -1))
      },
      /**
       * root slide menu data, aka the first slide
       */
      rootData: menuData,
      /**
       * array of child node, each node is a "child slide",
       * component just renders slides, the visual implementation shall be handled by component itself
       */
      slides,
    }
  }, [cache, fetchHeaderMenuChildNodesSwr, menuData, slides])
}

export const OffCanvasHeaderMenuContextProvider: FC<{
  children: ReactNode
  menuData: CmsComponentMenu[]
}> = ({ children, menuData }) => {
  const slideControls = useSlidesControls(menuData)
  const openControls = useOpenControls()

  const contextValue = useMemo(() => {
    const value: OffCanvasHeaderMenuContextValue = {
      openControls,
      slidesControls: slideControls,
    }

    return value
  }, [slideControls, openControls])

  return (
    <OffCanvasHeaderMenuContext.Provider value={contextValue}>
      {children}
    </OffCanvasHeaderMenuContext.Provider>
  )
}

export const useOffCanvasHeaderMenuContext =
  (): OffCanvasHeaderMenuContextValue => {
    const context = useContext(OffCanvasHeaderMenuContext)
    if (context === null) {
      throw new Error(
        'useOffCanvasHeaderMenuContext must be used within a OffCanvasHeaderMenuProvider',
      )
    }

    return context
  }
