'use client'

import { type FC } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import { HeaderBottomSection } from '../HeaderBottomSection'
import { HeaderMiddleSection } from '../HeaderMiddleSection'
import { HeaderTopSection } from '../HeaderTopSection'

export const HeaderDesktop: FC = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    initialIsIntersecting: true,
    threshold: 0,
  })

  const isSticky = !isIntersecting

  return (
    <>
      <HeaderTopSection ref={ref} />
      <HeaderMiddleSection isSticky={isSticky} />
      <HeaderBottomSection isSticky={isSticky} />
    </>
  )
}
