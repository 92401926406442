import { clsx } from 'clsx'
import { type StaticImageData } from 'next/image'
import { type FormEvent, type JSX, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSessionStorage } from 'usehooks-ts'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
} from 'base-ui'

import { useOpenableControls } from '../../generic-hooks/useOpenableControls/useOpenableControls'
import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { gtmDataLayerPush } from '../../gtm/gtmDataLayer'
import { LOCALE } from '../../i18n/LOCALE'
import { StaticImage } from '../../image/StaticImage'
import { urlResolverGetNewsletter } from '../../url-handling/urlResolver'

import modalHeroDE from './images/nl_banner_ch_de.webp'
import modalHeroFR from './images/nl_banner_ch_fr.webp'
import modalHeroIT from './images/nl_banner_it.webp'

export const NEWSLETTER_MODAL_TIMEOUT = 7000
export const NEWSLETTER_MODAL_ID = 'NewsletterModal'

export const NewsletterModal = (): JSX.Element => {
  const globalConfig = useGlobalConfigContext()
  const intl = useIntl()
  const newsletterUrl = urlResolverGetNewsletter(globalConfig)

  const { handleClose, handleOpen, isOpen } = useOpenableControls(false)
  const [, setNewsletterModalShown] = useSessionStorage(
    NEWSLETTER_MODAL_ID,
    false,
  )
  const getModalHero = (): StaticImageData => {
    switch (globalConfig.locale) {
      case LOCALE.DE_CH: {
        return modalHeroDE
      }
      case LOCALE.FR_CH: {
        return modalHeroFR
      }
      case LOCALE.IT_IT: {
        return modalHeroIT
      }
      default: {
        return modalHeroDE
      }
    }
  }

  useEffect(() => {
    const showPopup = setTimeout(() => {
      gtmDataLayerPush({
        event: 'popin',
        popinName: '__newsletterModal',
      })
      handleOpen()
    }, NEWSLETTER_MODAL_TIMEOUT)

    return (): void => {
      clearTimeout(showPopup)
    }
  }, [handleOpen])

  const onModalClose = (): void => {
    handleClose()
    setNewsletterModalShown(true)
  }

  /*
   * we are delaying the form submission to track the event
   * and to set sessionStorage value
   */
  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setNewsletterModalShown(true)
    event.currentTarget.submit()
  }

  const isLocaleItIt = globalConfig.locale === LOCALE.IT_IT

  return (
    <Dialog
      onClose={onModalClose}
      open={isOpen}
      slotProps={{
        dialog: {
          className: 'mobile-sm:w-11/12 max-w-xl bg-transparent h-auto',
        },
      }}
    >
      <div className="relative">
        <DialogTitle
          className="absolute right-3 top-3 bg-transparent p-0"
          onClose={onModalClose}
          slotProps={{
            closeButton: {
              className: clsx(
                'fill-current',
                isLocaleItIt && 'text-light-primary-strong',
              ),
            },
          }}
        />
      </div>

      <div
        className={clsx(
          'mobile-sm:rounded-xl mobile-sm:rounded-b-none',
          isLocaleItIt ? 'bg-dark-primary' : 'bg-light-brand-high',
        )}
      >
        <StaticImage alt="" className="m-auto" src={getModalHero()} />
      </div>
      <DialogContent className="relative flex flex-col items-center justify-center bg-light-primary-low mobile-sm:rounded-xl mobile-sm:rounded-t-none">
        <div className="flex max-w-[520px] flex-col items-center justify-center overflow-hidden text-center mobile-sm:rounded-xl mobile-sm:rounded-t-none">
          <p className="mb-4 text-m font-medium">
            <FormattedMessage id="general.newsletterBanner.title" />
          </p>
          <form
            action={newsletterUrl}
            className="flex flex-col items-center"
            method="GET"
            onSubmit={onSubmit}
          >
            <FormControl required>
              <FormLabel>
                {intl.formatMessage({ id: 'general.label.email' })}
              </FormLabel>
              <Input className="tablet:w-80" name="email" type="email" />
            </FormControl>
            <Button className="my-3.5" type="submit">
              <FormattedMessage id="checkout.error.requestBody.validation.email.login" />
            </Button>
          </form>
          <small className="text-dark-primary-medium">
            <FormattedMessage id="general.newsletterBanner.conditions" />
          </small>
        </div>
      </DialogContent>
    </Dialog>
  )
}
