import { type FC } from 'react'

import logoTuv from '../../../../../public/logos/logo-tuv.svg'
import { useGlobalConfigContext } from '../../../../global-config'
import { StaticImage } from '../../../../image'
import { TENANT } from '../../../../tenant'

export const HeaderMiddleSectionUsp: FC = () => {
  const { tenant } = useGlobalConfigContext()

  if (tenant === TENANT.AT) {
    return (
      <StaticImage
        alt="Meilleur site de commerce en ligne"
        height={56}
        src={logoTuv.src}
        width={56}
      />
    )
  }

  return null
}
