'use client'

import { clsx } from 'clsx'
import { type FC, useEffect, useRef } from 'react'

import { useDeviceDetectorContext } from '../../../../../device-detector'
import { experimentsApiActivatePreQuerySuggestionIfEnabled } from '../../../../../experiments/api/experimentsApi'
import { useExperimentsContext } from '../../../../../experiments/components/ExperimentsContext'
import { useGlobalConfigContext } from '../../../../../global-config'
import { useHeaderSearchContext } from '../../headerSearchContext'
import { useHeaderSearchPlaceholderText } from '../../useHeaderSearchPlaceholderText'
import { handleKeyPressedOnSearchInput } from '../HeaderSearchActiveSearchBox/HeaderSearchAlgoliaContainer/HeaderSearchResultsContainer/HeaderSearchResults/handleKeyPressedOnSearchInput'

import { HeaderSearchMobileInputCloseSearchButton } from './HeaderSearchMobileInputCloseSearchButton'

interface HeaderSearchMobileInputProps {
  className: string
}

export const HeaderSearchMobileInput: FC<HeaderSearchMobileInputProps> = ({
  className,
}) => {
  const headerSearchContext = useHeaderSearchContext()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { inputControls } = headerSearchContext
  const headerSearchPlaceholderText = useHeaderSearchPlaceholderText()
  useEffect(() => {
    /**
     * input when mobile search open
     */
    inputRef.current?.focus()
  }, [])
  const { isMobile } = useDeviceDetectorContext()
  const globalConfig = useGlobalConfigContext()
  const { experiments } = useExperimentsContext()

  return (
    <form
      className={clsx(
        'mb-5 flex rounded-full border border-dark-brand bg-light-primary-low py-2',
        className,
      )}
      onSubmit={(event): void => {
        /**
         * We do "submitting" via js.
         * Form is left as fallback, for cases when javascript will fail to hydrate for some reason,
         * but user still be able to land on search results page.
         */
        event.preventDefault()
        headerSearchContext.handleSubmitSearch()
      }}
    >
      <HeaderSearchMobileInputCloseSearchButton />
      <input
        className="mr-6 w-full rounded-full border-dark-accent bg-light-primary-low px-4 py-2 text-s leading-l outline-none placeholder:text-m placeholder:font-normal placeholder:text-dark-primary-low"
        data-qa-id="search-box-query"
        onChange={(event): void => {
          inputControls.setSearchInputValue(event.target.value, {
            /** trigger that search query as well be updating, triggering actual search */
            updateSearchQuery: true,
          })
        }}
        onClick={() => {
          experimentsApiActivatePreQuerySuggestionIfEnabled({
            experiments,
            globalConfig,
            isMobile,
          })
        }}
        onKeyUp={(event): void => {
          handleKeyPressedOnSearchInput(event, headerSearchContext)
        }}
        placeholder={headerSearchPlaceholderText}
        ref={inputRef}
        type="search"
        value={inputControls.searchInputValue}
      />
    </form>
  )
}
