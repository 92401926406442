import { type FC } from 'react'

import { type FooterDataType } from '../../model/footer.types'

import { FooterBannerSection } from './FooterBannerSection/FooterBannerSection'
import { FooterBottomSection } from './FooterBottomSection'
import { FooterTopSection } from './FooterTopSection'

type FooterProps = {
  footerData: FooterDataType
}

export const Footer: FC<FooterProps> = ({
  footerData: {
    appLinks,
    footerCmsData: { data },
    infoSectionLinks,
    legalTextIframeSrc,
    newsletterDiscount,
    paymentPartners,
    shippingPartners,
    socialLinks,
    tenantLinks,
    trustBoxLinks,
    uspBoxItems,
  },
}) => (
  <footer className="pb-28 pt-5 tablet:pb-24">
    <FooterTopSection
      categories={data?.categories}
      childComponentClassNames="mx-auto mb-5 max-w-5xl"
      trustedItems={trustBoxLinks}
      uspItems={uspBoxItems}
    />
    <div className="mb-5 w-full px-3.5 py-5 tablet:bg-light-brand-medium">
      <FooterBannerSection
        className="mx-auto max-w-5xl"
        infoSectionItems={infoSectionLinks}
        newsletterDiscount={newsletterDiscount}
        paymentPartnersItems={paymentPartners}
        shippingPartnersItems={shippingPartners}
      />
    </div>
    <FooterBottomSection
      appLinksItems={appLinks}
      childComponentClassNames="mx-auto mb-5 max-w-5xl px-3.5 desktop:px-0"
      legalTextIframeSrc={legalTextIframeSrc}
      socialLinksItems={socialLinks}
      tenantItemsLinks={tenantLinks}
    />
  </footer>
)
