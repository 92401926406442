import { clientSideCookieGet } from '../../cookies/clientSideCookie'
import { type PublicGlobalConfig } from '../../global-config/config-types/PublicGlobalConfig'

/**
 * Track optimizely event with event key and event tags
 * Uses sendBeacon to cover edge cases
 */
export const experimentsTrackEvent = (
  publicConfig: PublicGlobalConfig,
  eventKey: string,
  eventTags: object = {},
): void => {
  try {
    const econdaIds = {
      econdaSessionId: clientSideCookieGet('emos_jcsid')?.split(':')[0],
      econdaVisitorId: clientSideCookieGet('emos_jcvid')?.split(':')[0],
    }

    const eventTagsWithEcondaIds = Object.assign(econdaIds, eventTags)

    const blob = new Blob(
      [JSON.stringify({ eventKey, eventTags: eventTagsWithEcondaIds })],
      {
        type: 'application/json;charset=UTF-8',
      },
    )

    navigator.sendBeacon(
      `/webclient/experiments/${publicConfig.tenantAndEnv}/${publicConfig.language}/track`,
      blob,
    )
  } catch {
    // void
  }
}
