import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

type CheckboxOwnProps = {
  /**
   * The size of the component
   * @default medium
   */
  size?: 'medium' | 'small'
}

type CheckboxProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'type'> &
  CheckboxOwnProps

/**
 * Checkboxes give users binary choices when presented with multiple options in a series.
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const { className, size = 'medium', ...inputProps } = props

    return (
      <input
        {...inputProps}
        className={clsx(
          'checkbox',
          size === 'medium' && 'checkbox_medium',
          size === 'small' && 'checkbox_small',
          className,
        )}
        ref={ref}
        type="checkbox"
      />
    )
  },
)
