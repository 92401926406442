/* eslint-disable max-lines -- maybe some URLs could be moved to related configs */
import {
  type PimProductV1,
  type UserSubOrderV2,
} from '@redteclab/api/clients/bully'

import { PUBLIC_API_PREFIX, type PublicGlobalConfig } from '../global-config'
import { LOCALE } from '../i18n'
import { type CART_ACTION } from '../login-registration'
import { type ProductAddToCartCtaParams } from '../product-page'
import { SupportedLanguages, TENANT, type TENANT_AND_ENV } from '../tenant'

import { getUrlFromUrlMap } from './config/getUrlFromUrlMap'
import { queryStringEncode } from './queryString'
import { urlGetPathName, urlNormalizeUri } from './url'

export const urlResolverGetHomepage = (config: PublicGlobalConfig): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: '/',
  })

export const urlResolverGetLogin = (
  config: PublicGlobalConfig,
  queryParams?: {
    [key: string]: string | boolean | undefined
    email?: string
    forceLogin?: boolean
    redirect?: string
  },
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: `/nx/login/${queryStringEncode(queryParams)}`,
  })

export const urlResolverGetLogout = (config: PublicGlobalConfig): string =>
  urlNormalizeUri({ locale: config.locale, uri: '/logout/' })

export const urlResolverGetForgotPassword = (
  config: PublicGlobalConfig,
): string =>
  urlNormalizeUri({ locale: config.locale, uri: '/nx/forgot-password/' })

export const urlResolverGetRegister = (config: PublicGlobalConfig): string =>
  `${urlResolverGetLogin(config)}#register`

export const urlResolverGetClimateNeutralShipping = (
  config: PublicGlobalConfig,
): string =>
  urlNormalizeUri({ locale: config.locale, uri: '/klimaneutraler-versand/' })

export const urlResolverGetTrackShippingInfo = (
  config: PublicGlobalConfig,
  subOrder: UserSubOrderV2,
): string => {
  const carrierName =
    subOrder.carrier?.externalCarrierIds?.parcellab ??
    subOrder.carrier?.displayName.split('.')[1]

  const trackId = subOrder.carrier?.trackingId

  return urlNormalizeUri({
    locale: config.locale,
    uri: `/paketverfolgung/${queryStringEncode({ courier: carrierName, trackingNo: trackId })}`,
  })
}

// Account
export const urlResolverGetAccount = (config: PublicGlobalConfig): string =>
  urlNormalizeUri({ locale: config.locale, uri: '/nx/account/' })

export const urlResolverGetAccountOrders = (
  config: PublicGlobalConfig,
): string => `${urlResolverGetAccount(config)}orders/`

export const urlResolverGetAccountNotepad = (
  config: PublicGlobalConfig,
  source?: string,
): string =>
  source
    ? `${urlResolverGetAccount(config)}notepad/${queryStringEncode({ source })}`
    : `${urlResolverGetAccount(config)}notepad/`

export const urlResolverGetAccountRedpoints = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.CH) {
    return urlNormalizeUri({
      locale: config.locale,
      uri: `${urlResolverGetAccount(config)}redcoins/`,
    })
  }

  // same for other tenants
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}redpoints/`,
  })
}

export const urlResolverGetAccountOrderDetailByOrderId = (
  config: PublicGlobalConfig,
  orderId: string,
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}orders/${orderId}`,
  })

export const urlResolverGetAccountSubOrderDetailBySubOrderId = (
  config: PublicGlobalConfig,
  orderId: string,
  subOrderId: string,
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccountOrderDetailByOrderId(
      config,
      orderId,
    )}${subOrderId}`,
  })

export const urlResolverGetInvoiceByOrderId = (
  config: PublicGlobalConfig,
  subOrderId: string,
  documentHash: string,
): string => {
  return `${urlResolverGetAccountOrderDetailByOrderId(
    config,
    subOrderId,
  )}/document/invoice/${documentHash}`
}

export const urlResolverGetNowOrderInvoiceByOrderId = (
  config: PublicGlobalConfig,
  orderInfo: { documentId: string; orderId: string; subOrderId: string },
): string => {
  return `/homeone/api/bully/now/v3/${config.tenantAndEnv}/orders/${orderInfo.orderId}/${orderInfo.subOrderId}/documents/${orderInfo.documentId}`
}

export const urlResolverRequestMarketPlaceOrderInvoiceByOrderId = (
  config: PublicGlobalConfig,
  orderInfo: { orderId: string; subOrderId: string },
): string => {
  return `${urlResolverGetAccountSubOrderDetailBySubOrderId(
    config,
    orderInfo.orderId,
    orderInfo.subOrderId,
  )}?section=request-invoice`
}

export const urlResolverGetMarketPlaceOrderInvoiceByOrderId = (
  config: PublicGlobalConfig,
  orderInfo: { documentId: string; subOrderId: string },
): string => {
  return `${urlResolverGetAccountOrderDetailByOrderId(
    config,
    orderInfo.subOrderId,
  )}/document/${orderInfo.documentId}`
}

export const urlResolverGetSubOrderInvoicesBySubOrderId = (
  config: PublicGlobalConfig,
  subOrderId: string,
  orderId: string,
): string => {
  return `${urlResolverGetAccountSubOrderDetailBySubOrderId(
    config,
    orderId,
    subOrderId,
  )}/?section=invoice`
}

export const urlResolverGetHelpWithMyOrderBySubOrderId = (
  config: PublicGlobalConfig,
  orderId: string,
  subOrderInfo: {
    isMarketplace: boolean
    subOrderId: string
  },
): string => {
  return `${urlResolverGetAccountSubOrderDetailBySubOrderId(
    config,
    orderId,
    subOrderInfo.subOrderId,
  )}${queryStringEncode({
    section: subOrderInfo.isMarketplace ? 'orderProblem' : 'problemDialog',
  })}`
}

export const urlResolverGetAccountCredit = (
  config: PublicGlobalConfig,
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}credits`,
  })

// Cart
export const urlResolverGetCart = (
  config: PublicGlobalConfig,
  action?: CART_ACTION,
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: `/nx/cart/${queryStringEncode({ action })}`,
  })

export const urlResolverGetSearchPage = (
  config: PublicGlobalConfig,
  options?: {
    queryParams: {
      [key: string]: unknown
      // https://wiki.shop-apotheke.com/display/~kPitas/Important+Search+Tracking+Infos
      b?: string
      i?: string
      q?: string
      query?: string
      searchChannel?: string
      userToken?: string
    }
  },
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/search.htm${queryStringEncode(options?.queryParams)}`,
  })
}

export const urlResolverGetPathForPimProduct = (
  config: PublicGlobalConfig,
  pimProduct: PimProductV1,
): string =>
  urlNormalizeUri({
    locale: config.locale,
    uri: urlGetPathName(pimProduct.canonical_url),
  })

export const urlResolverGetMyTherapyApp = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/medikamenten-app/`,
  })
}

export const urlResolverGetMyTherapyAppDeepLink = (): string => {
  return 'https://activate.mytherapy.app/mmeT?deep_link_value=mytherapy%3A%2F%2Ftoday&af_dp=mytherapy%3A%2F%2Ftoday&af_xp=custom&pid=SAE-LP&c=MyT_REF_SAE-BAN_DE_00_None_All_PCLP&af_android_store_csl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Deu.smartpatient.mytherapy%26listing%3Dmytherapy-for-shopapotheke&af_ios_store_cpp=8d6e14bd-5a7c-4954-9da6-8b39a4b87a81'
}

export const urlResolverGetInteractions = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/wechselwirkungen/`,
  })
}

export const urlResolverGetCounselor = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/ratgeber/`,
  })
}

export const urlResolverGetMedicineLexicon = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/medikamenten-lexikon/`,
  })
}

export const urlResolverGetContactMailPharmacy = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/versandapotheke_kontakt.htm`,
  })
}

export const urlResolverGetPharmaLandingPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/pharmazeutische-beratung/`,
  })
}

/**
 * link to page where user can redeem his prescription
 */
export const urlResolverGetRedeemPrescription = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/rezept-online-einloesen/`,
  })
}

export const urlResolverGetEprescriptionInfo = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: '/magazin/e-health/das-e-rezept/',
  })
}

export const urlResolverGetRedeemEprescription = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: `/e-rezept-einloesen/` })
}

export const urlResolverGetRedeemPaperPrescription = (
  config: PublicGlobalConfig,
): string => {
  if (
    config.tenant === TENANT.CH &&
    config.language === SupportedLanguages.FR
  ) {
    return urlNormalizeUri({ locale: config.locale, uri: `/ordonnance/` })
  }

  return urlNormalizeUri({ locale: config.locale, uri: `/rezept-einloesen/` })
}

export const urlResolverGetRedeemPaperPrescriptionConfirmation = (
  config: PublicGlobalConfig,
): string => {
  const { locale } = config

  const uriMap: Partial<Record<LOCALE, string>> = {
    [LOCALE.DE_CH]: '/rezept-einloesen/bestaetigung',
    [LOCALE.FR_CH]: '/ordonnance/confirmation',
  }

  const uri = uriMap[locale]

  return urlNormalizeUri({ locale, uri })
}

export const urlResolverGetMediServiceRxLandingPagePrivacyPolicy = (
  config: PublicGlobalConfig,
): string => {
  return config.locale === LOCALE.FR_CH
    ? 'https://www.mediservice.ch/fr/cookie-policy.php'
    : 'https://www.mediservice.ch/de/cookie-policy.php'
}

export const urlResolverGetMediServiceRxLandingPageFirstQuestionPartnerLink = (
  config: PublicGlobalConfig,
): string => {
  return config.locale === LOCALE.FR_CH
    ? 'https://www.mediservice.ch/fr/services/homecare.php'
    : 'https://www.mediservice.ch/de/services/homecare.php'
}

export const urlResolverGetMediServiceRxLandingPageSecondQuestionPartnerLink = (
  config: PublicGlobalConfig,
): string => {
  return config.locale === LOCALE.FR_CH
    ? 'https://www.mediservice.ch/mediserviceAssets/bin/alle-formulare-de-fr-it/rezeptbegleitkarten/redcare/240117-ch-fr-rezeptbegleitkarte-mediservice-a4-v4.pdf'
    : 'https://www.mediservice.ch/mediserviceAssets/bin/alle-formulare-de-fr-it/rezeptbegleitkarten/redcare/240117-ch-de-rezeptbegleitkarte-mediservice-a4-v3.pdf'
}

export const urlResolverGetRedPointsRoot = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.CH) {
    return urlNormalizeUri({ locale: config.locale, uri: '/redcoins/' })
  }

  return urlNormalizeUri({ locale: config.locale, uri: '/redpoints/' })
}

export const urlResolverGetRedPointsNx = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.CH) {
    return urlNormalizeUri({ locale: config.locale, uri: '/nx/redcoins/' })
  }

  return urlNormalizeUri({ locale: config.locale, uri: '/nx/redpoints/' })
}

export const urlResolverScanEprescription = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: `/e-rezept-scannen/` })
}

export const urlResolverNfcLandingPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/e-rezept-elektronische-gesundheitskarte/`,
  })
}

export const urlResolverGetErxFamilyLandingPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/e-rezept-familie/`,
  })
}

export const urlResolverGetFreeEnvelopePdf = (): string => {
  return 'https://static.shop-apotheke.com/media/pdf/freiumschlag.pdf'
}

export const urlResolverGetFreeEaEnvelopePdf = (): string => {
  return 'https://static.shop-apotheke.com/media/pdf/Freiumschlag-EAV.pdf'
}

export const urlResolverGetRxFreeEnvelopePdf = (): string => {
  return 'https://www.mediservice.ch/mediserviceAssets/bin/alle-formulare-de-fr-it/gas/mediservice_adressetikett.pdf'
}

export const urlResolverGetShopApothekeApp = (
  config: PublicGlobalConfig,
): string =>
  urlNormalizeUri({ locale: config.locale, uri: '/lp/shop-apotheke-app' })

/**
 * One Link to eRx flow at Shopapotheke App
 */
export const urlResolverGoToNfcAtShopApothekeApp = (): string =>
  'https://shop-apotheke-com.onelink.me/d95Q?pid=Onsite&c=Onsite_DE_Erx_LP_NFC_Button&af_dp=sae://erx&deep_link_value=sae://erx&af_web_dp=https://www.shop-apotheke.com/lp/shop-apotheke-app'

export const urlResolverGetPrivacySecurity = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'privacySecurity'),
  })
}

export const urlResolverGetPrivacySecurityCreditCheck = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'privacySecurityCreditCheck'),
  })
}

export const urlResolverGetAgb = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'agb'),
  })
}

export const urlResolverGetLinkToAllProductsOfSeller = (
  config: PublicGlobalConfig,
  sellerId: string,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `/nx/partnerproducts/id-${sellerId}/`,
  })
}

export const urlResolverGetFaqRatingGuidelines = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'ratingGuidelines'),
  })
}

export const urlResolverGetAccountability = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'accountability'),
  })
}

export const urlResolverGetImprint = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'imprint'),
  })
}

/**
 * Generates the URL to use to share the product on facebook, depending on the device
 */
export const urlResolverGetFacebookProductShareUrl = (
  product: PimProductV1,
  deviceType: string,
): string => {
  return `https://www.facebook.com/sharer/sharer.php${queryStringEncode({
    /**
     * The identifier name is too short, and it can't be changed
     */
    // eslint-disable-next-line id-length
    u: `${product.canonical_url}?campaign=share/facebook/${deviceType}/${product.upid}`,
  })}`
}

/**
 * Generates the URL to use to send the product using mail
 */
export const urlResolverGetSendProductInEmailUrl = (
  subject: string,
): string => {
  return `mailto:?subject=${subject}`
}

/**
 * Generates the URL to use to share the product on Pinterest, depending on the device
 */
export const urlResolverGetPinterestProductShareUrl = (
  product: PimProductV1,
  deviceType: string,
): string => {
  return `https://www.pinterest.com/pin/create/link/${queryStringEncode({
    description: product.name,
    url: `${product.canonical_url}?campaign=share/pinterest/${deviceType}/${product.upid}`,
  })}`
}

/**
 * Generates the URL to use to share the product on Twitter, depending on the device
 */
export const urlResolverGetTwitterProductShareUrl = (
  product: PimProductV1,
  deviceType: string,
): string => {
  return `https://twitter.com/intent/tweet${queryStringEncode({
    text: product.name,
    url: `${product.canonical_url}?campaign=share/twitter/${deviceType}/${product.upid}`,
  })}`
}

/**
 * Generates the URL to use to share the product on Whatsapp, depending on the device
 */
export const urlResolverGetWhatsappProductShareUrl = (
  product: PimProductV1,
  deviceType: string,
): string => {
  return `whatsapp://send${queryStringEncode({
    text: product.name,
    url: `${product.canonical_url}?campaign=share/whatsapp/${deviceType}/${product.upid}`,
  })}`
}

/**
 * Generates the URL of the product to be copied, depending on the device
 */
export const urlResolverGetProductShareUrl = (
  product: PimProductV1,
  deviceType: string,
): string => {
  return `${product.canonical_url}?campaign=share/copylink/${deviceType}/${product.upid}`
}

/**
 * cart intermediate page handles adding item to cart.
 */
export const urlResolverGetCartIntermediatePage = (
  config: PublicGlobalConfig,
  params?: ProductAddToCartCtaParams,
): string => {
  const base = urlNormalizeUri({
    locale: config.locale,
    uri: `/nx/cartintermediate/`,
  })

  if (params) {
    return `${base}${queryStringEncode(
      params as unknown as Record<string, unknown>,
    )}`
  }

  return base
}

/**
 * Ods transition url, currently end url will be handled by fock.
 * @example:
 * {target: 'zava.com'} -> /transition?target=zava.com
 */
export const urlResolverGetOdsTransition = (params: {
  target: string
}): string => {
  return `/transition${queryStringEncode(params)}`
}

export const urlResolverGetLoginApiEndpoint = (): string => {
  return `${PUBLIC_API_PREFIX}/api/login`
}

export const urlResolverGetUserRegisterApiEndpoint = (): string => {
  return `${PUBLIC_API_PREFIX}/api/register/`
}

export const urlResolverGetOneTimePasswordInitiateApiEndpoint = (
  config: PublicGlobalConfig,
): string => {
  return `${PUBLIC_API_PREFIX}/api/bully/auth/v3/${config.tenantAndEnv}/login/otp/initiate`
}

export const urlResolverGetOneTimePasswordConfirmApiEndpoint = (
  config: PublicGlobalConfig,
): string => {
  return `${PUBLIC_API_PREFIX}/api/bully/auth/v3/${config.tenantAndEnv}/login/otp/confirm`
}

export const urlResolverGetCrossSellHorizontalSliderApiEndpoint =
  (): string => {
    return `${PUBLIC_API_PREFIX}/api/cross-sell/horizontal-slider`
  }

export const urlResolverGetOdsVideoConsultation = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: '/online-arzt-service/videosprechstunde/',
  })
}

export const urlResolverGetNewsletter = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/nx/newsletter/' })
}

export const urlResolverGetFaq = (
  config: PublicGlobalConfig,
  hashId?: string,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: hashId ? `/faq.htm#${hashId}` : `/faq.htm`,
  })
}

export const urlResolverGetFaqProductReviews = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'faq-product-4'),
  })
}

export const urlResolverGetFaqPrescriptions = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'f05'),
  })
}

export const urlResolverGetOffersCategoryPage = (
  config: PublicGlobalConfig,
): string => {
  switch (config.locale) {
    case LOCALE.DE_CH: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/aktionen',
      })
    }

    case LOCALE.FR_BE: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/offres-speciales-fr',
      })
    }

    case LOCALE.FR_CH: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/bons-plans',
      })
    }

    case LOCALE.FR_FR: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/vente-flash',
      })
    }

    case LOCALE.IT_IT: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/promozioni-set',
      })
    }

    case LOCALE.NL_BE: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/speciale-aanbiedingen-nl',
      })
    }

    default: {
      return urlNormalizeUri({
        locale: config.locale,
        uri: '/angebote',
      })
    }
  }
}

export const urlResolverGetFaqHowReviewScoreCalculated = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.IT) {
    return urlNormalizeUri({
      locale: config.locale,
      uri: urlResolverGetFaq(config, 'faq-product-3-h'),
    })
  }

  // same for FR CH DE
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'faq-product-3'),
  })
}

export const urlResolverGetFaqSellerEvaluations = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.AT) {
    return urlNormalizeUri({
      locale: config.locale,
      uri: urlResolverGetFaq(config, 'frage-8-22'),
    })
  }

  // COM
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, '#frage-8-23'),
  })
}

export const urlResolverGetFaqItemCancelOrder = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-0-1'),
  })
}

export const urlResolverGetFaqitemShippingCost = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-1-2'),
  })
}

export const urlResolverGetFaqItemDeliveryInfo = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-1-1'),
  })
}

export const urlResolverGetFaqItemDeliveryZones = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-1-3'),
  })
}

export const urlResolverGetFaqItemReturnOrder = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-0-5'),
  })
}

export const urlResolverGetFaqItemRedeemVoucher = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-0-7'),
  })
}

export const urlResolverGetFaqItemPaymentMethods = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'frage-2-0'),
  })
}

export const urlResolverGetFaqReviewsCriteria = (
  config: PublicGlobalConfig,
): string => {
  if (config.tenant === TENANT.IT) {
    return urlNormalizeUri({
      locale: config.locale,
      uri: urlResolverGetFaq(config, 'faq-product-1-h'),
    })
  }

  // same for FR CH DE
  return urlNormalizeUri({
    locale: config.locale,
    uri: urlResolverGetFaq(config, 'faq-product-1'),
  })
}

export const urlResolverGetChangeEmail = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}change-email`,
  })
}
export const urlResolverGetChangePassword = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}change-password/`,
  })
}
export const urlResolverGetBillingAddress = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}billing-address`,
  })
}
export const urlResolverGetShippingAddress = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}shipping-address`,
  })
}

export const urlResolverGetEavCatalogue = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}eav-catalogue`,
  })
}

export const urlResolverGetChangeDateOfBirth = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}change-date-of-birth`,
  })
}
export const urlResolverGetOnlineArztService = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: '/online-arzt-service.htm',
  })
}

export const urlResolverGetMedicationPlan = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}medication-plan/`,
  })
}

export const urlResolverGetCopaymentAnnualReport = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}copayment-report`,
  })
}

const urlResolverGetAccountMessages = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccount(config)}messages`,
  })
}

export const urlResolverGetMessagesOverview = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccountMessages(config)}overview`,
  })
}

export const urlResolverGetMessagesFromThreadId = (
  config: PublicGlobalConfig,
  threadId: string,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: `${urlResolverGetAccountMessages(config)}thread/${threadId}`,
  })
}

export const urlResolverGetRezeptbonus = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/rezeptbonus/' })
}

export const urlResolverGetRezeptbonusLandingPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/lp/rezeptbonus/' })
}

export const urlResolverGetSmartExpert = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/smart/' })
}

export const urlResolverGetAllergy = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/allergie/' })
}

export const urlResolverGetColds = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/erkaeltung/' })
}

export const urlResolverGetVitamin = (config: PublicGlobalConfig): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: '/vitamine-mineralstoffe-enzyme/',
  })
}

export const urlResolverMyTherapy = (): string => {
  return 'https://activate.mytherapyapp.com/746744'
}
export const urlResolverRedCare = (): string => {
  return '/search.htm?b=1&q=redcare'
}

export const urlResolverGetVouchersPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'vouchersPage'),
  })
}

export const urlResolverGetContactUs = (
  config: Pick<PublicGlobalConfig, 'locale'>,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'contactUs'),
  })
}

export const urlResolverGetAboutUsPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({
    locale: config.locale,
    uri: getUrlFromUrlMap(config, 'aboutUs'),
  })
}

export const urlResolverGetLeafletSearchPage = (): string => {
  return urlNormalizeUri({
    uri: `/beipackzettel`,
  })
}

export const urlResolverGetLeafletSearchResultsPage = (
  searchQuery: string,
): string => {
  return urlNormalizeUri({
    uri: `/beipackzettel/suchergebnisse${queryStringEncode({
      // eslint-disable-next-line id-length
      q: searchQuery,
    })}`,
  })
}

export const urlResolverGetCheckoutAddress = (
  config: PublicGlobalConfig,
  tenantAndEnv: TENANT_AND_ENV,
  language: SupportedLanguages,
): string => {
  return urlNormalizeUri({
    uri: `/nx/checkout/${tenantAndEnv}/${language}/address`,
  })
}

export const urlResolverGetChristmasPage = (
  config: PublicGlobalConfig,
): string => {
  return urlNormalizeUri({ locale: config.locale, uri: '/lp/cadeaux-de-noel/' })
}
