'use client'

import { type FC, useEffect } from 'react'
import { useSessionStorage } from 'usehooks-ts'

import { Dialog, DialogContent, DialogTitle, Link } from 'base-ui'

import { useDeviceDetectorContext } from '../../../device-detector/context/deviceDetectorContext'
import { useOpenableControls } from '../../../generic-hooks/useOpenableControls/useOpenableControls'
import { useGlobalConfigContext } from '../../../global-config/context/globalConfigContext'
import { StaticImage } from '../../../image/StaticImage'
import { urlResolverGetVouchersPage } from '../../../url-handling/urlResolver'
import { useUserApiSwrSessionV1Customer } from '../../../user-data/api/swr/useUserApiSwrSessionV1Customer'
import { useExperimentsContext } from '../../components/ExperimentsContext'
import {
  EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLICK,
  EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLOSE_MODAL,
  experimentsTrackEvent,
} from '../../eventTracking'
import { EXPERIMENT_EXIT_INTENT_POPUP } from '../../model/EXPERIMENT_NAME_PER_TENANT'
import { EXPERIMENT_VARIATION } from '../../model/EXPERIMENT_VARIATION'
import { type ExperimentType } from '../../model/Experiment.types'
import {
  experimentAccessorGetExperimentData,
  experimentAccessorGetExperimentNamePerTenant,
} from '../../model/experimentAccessor'
import { type ExitIntentPopupModalConfiguration } from '../config/getExitIntentPopupModalConfigurationByTenant'

const EXIT_INTENT_POPUP_MODAL_ID = 'ExitIntentPopupModal'
const EXIT_INTENT_POPUP_MODAL_MOBILE_TIMEOUT = 240_000

type ExitIntentPopupModalProps = {
  exitIntentPopupModalConfig: ExitIntentPopupModalConfiguration | null
}

const useGetExitIntentPopupModalExperiment = (
  isMobile: boolean,
): ExperimentType | undefined => {
  const { experiments } = useExperimentsContext()
  const { tenant } = useGlobalConfigContext()

  const exitIntentPopupModalExperimentName =
    experimentAccessorGetExperimentNamePerTenant({
      experimentName: EXPERIMENT_EXIT_INTENT_POPUP,
      isMobile,
      tenant,
    })

  if (!exitIntentPopupModalExperimentName) {
    return undefined
  }

  return experimentAccessorGetExperimentData(
    experiments,
    exitIntentPopupModalExperimentName,
  )
}

export const ExitIntentPopupModal: FC<ExitIntentPopupModalProps> = ({
  exitIntentPopupModalConfig,
}) => {
  const globalContext = useGlobalConfigContext()
  const { isMobile } = useDeviceDetectorContext()
  const { addExperimentKeyToActivate } = useExperimentsContext()
  const { data: userData } = useUserApiSwrSessionV1Customer()
  const { handleClose, handleOpen, isOpen } = useOpenableControls(false)
  const [hasBannerPoppedOpen, setHasBannerPoppedOpen] = useSessionStorage(
    EXIT_INTENT_POPUP_MODAL_ID,
    false,
  )

  const exitIntentPopupModalExperiment =
    useGetExitIntentPopupModalExperiment(isMobile)

  const onModalClose = (): void => {
    handleClose()
    setHasBannerPoppedOpen(true)
  }

  useEffect(() => {
    if (
      !exitIntentPopupModalConfig ||
      !exitIntentPopupModalExperiment ||
      !exitIntentPopupModalExperiment.isEnabled ||
      userData?.customerNumber
    ) {
      return undefined
    }

    const handleShowPopup = (): void => {
      if (!hasBannerPoppedOpen) {
        if (
          exitIntentPopupModalExperiment.variant === EXPERIMENT_VARIATION.V1
        ) {
          handleOpen()
          setHasBannerPoppedOpen(true)
        }
        addExperimentKeyToActivate(exitIntentPopupModalExperiment.name)
      }
    }

    if (isMobile) {
      const showPopup = setTimeout(() => {
        handleShowPopup()
      }, EXIT_INTENT_POPUP_MODAL_MOBILE_TIMEOUT)

      return (): void => {
        clearTimeout(showPopup)
      }
    }

    const handleMouseLeave = (event: MouseEvent): void => {
      if (event.clientY <= 0) {
        handleShowPopup()
      }
    }

    document.addEventListener('mouseleave', handleMouseLeave)

    return (): void => {
      document.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [
    addExperimentKeyToActivate,
    exitIntentPopupModalConfig,
    exitIntentPopupModalExperiment,
    handleOpen,
    hasBannerPoppedOpen,
    isMobile,
    setHasBannerPoppedOpen,
    userData?.customerNumber,
  ])

  return (
    <Dialog
      onClose={onModalClose}
      open={isOpen}
      slotProps={{
        dialog: {
          className:
            'mobile-sm:w-11/12 mobile-sm:mt-48 max-w-2xl bg-transparent',
        },
      }}
    >
      <div className="relative">
        <DialogTitle
          className="absolute right-0 z-10 bg-transparent p-0 tablet:right-3 tablet:top-3"
          onClick={(): void => {
            experimentsTrackEvent(
              globalContext,
              EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLOSE_MODAL,
            )
          }}
          onClose={onModalClose}
        />
      </div>

      <DialogContent className="relative flex flex-col items-center justify-center overflow-hidden bg-light-primary-low p-0 text-center mobile-sm:rounded-xl">
        <Link
          href={urlResolverGetVouchersPage(globalContext)}
          onClick={(): void => {
            onModalClose()
            experimentsTrackEvent(
              globalContext,
              EXPERIMENTS_TRACK_EVENT_EXIT_INTENT_POPUP_MODAL_CLICK,
            )
          }}
        >
          <StaticImage
            alt="new-customer-voucher"
            className="m-auto"
            src={exitIntentPopupModalConfig?.banner ?? ''}
          />
        </Link>
      </DialogContent>
    </Dialog>
  )
}
