'use client'

import { type LiteClient } from 'algoliasearch/lite'
import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  ALGOLIA_SEARCH_CHANNEL,
  type AlgoliaConfiguration,
  algoliaGetTokenFromLocalStorage,
  type AlgoliaQueryRecord,
  type AlgoliaRecord,
  useAlgoliaConfigContext,
} from '../../../algolia'
import { useGetExperimentForTenant } from '../../../experiments/hooks'
import { EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS } from '../../../experiments/model/EXPERIMENT_NAME_PER_TENANT'
import { EXPERIMENT_VARIATION } from '../../../experiments/model/EXPERIMENT_VARIATION'
import {
  type PublicGlobalConfig,
  useGlobalConfigContext,
} from '../../../global-config'
import { urlResolverGetSearchPage } from '../../../url-handling'

import { headerSearchSessionStorageTrackingTrackSuggestItemClick } from './headerSearchSessionStorageTracking'
import {
  previousSearchesStorageAddPreviousSearches,
  usePreviousSearchControls,
} from './previousSearchesStorage'

const HeaderSearchContext = createContext<HeaderSearchContextValue | null>(null)

export type HeaderSearchContextValue = {
  configuration: AlgoliaConfiguration
  handleSubmitSearch: () => void
  inputControls: {
    clearSearch: () => void
    renderedSearchRecords?: AlgoliaRecord[]
    renderedSuggestionRecords?: AlgoliaQueryRecord[]
    searchInputValue: string
    searchQuery: string
    setRenderedSearchRecords: (records: AlgoliaRecord[]) => void
    setRenderedSuggestionRecords: (records: AlgoliaQueryRecord[]) => void
    setSearchInputValue: (
      value: string,
      options: { updateSearchQuery: boolean },
    ) => void
  }
  portalResultsTo?: HTMLDivElement | null
  previousSearchControls: {
    hasPreviousSearches: boolean
    previousSearches: string[]
    resetPreviousSearches: () => void
  }
  searchClientControls: {
    getSearchClient: () => LiteClient
    isSearchClientInitialized: () => boolean
    setSearchClient: (value: LiteClient) => void
  }
  searchIsActive: boolean
  setSearchIsActive: (options: {
    portalResultsToElement?: HTMLDivElement | null
    value: boolean
  }) => void
  shouldShowDefaultRecommendations: () => boolean
  /**
   * whenever user clicks on suggest item, some tracking info is written to session storage
   * https://jira.shop-apotheke.com/browse/WSAWA-2281
   */
  trackSuggestItemClick: () => void
}

/**
 * The algolia component is loaded dynamically, so this will be used, to propagate search client, from dynamically,
 * loaded components to the context.
 */
const useSearchClientControls =
  (): HeaderSearchContextValue['searchClientControls'] => {
    const searchClientRef = useRef<LiteClient | null>(null)

    return useMemo((): HeaderSearchContextValue['searchClientControls'] => {
      return {
        getSearchClient: (): LiteClient => {
          const searchClient = searchClientRef.current
          if (!searchClient) {
            throw new Error('Search_client_is_not_initialized_but_accessed')
          }

          return searchClient
        },
        isSearchClientInitialized: () => Boolean(searchClientRef.current),
        setSearchClient: (searchClientInstance: LiteClient): void => {
          searchClientRef.current = searchClientInstance
        },
      }
    }, [searchClientRef])
  }

const navigateToSearchResults = ({
  config,
  query,
}: {
  config: PublicGlobalConfig
  query: string
}): void => {
  window.location.href = urlResolverGetSearchPage(config, {
    queryParams: {
      // "i" represents that the search is internal, e.g. originates from site itself
      // eslint-disable-next-line id-length
      i: '1',
      query,
      searchChannel: ALGOLIA_SEARCH_CHANNEL,
      userToken: algoliaGetTokenFromLocalStorage(),
    },
  })
}

/**
 * constructs the url for the search page and navigates to it.
 */
const handleSubmitSearch = (
  config: PublicGlobalConfig,
  inputControls: HeaderSearchContextValue['inputControls'],
): void => {
  const { searchInputValue } = inputControls
  if (!searchInputValue) {
    return
  }
  /** sometimes searchInputValue is different from searchQuery,
   * e.g. when user types in search input, navigates using keyboard
   * in this case searchInputValue will be different from searchQuery
   * therefore it is better to use searchInputValue instead of searchQuery
   */
  previousSearchesStorageAddPreviousSearches(searchInputValue)
  navigateToSearchResults({ config, query: searchInputValue })
}

/**
 * State management for search input.
 * Important to note that searchQuery and actual value on input are not same state.
 * searchQuery will actually be used to query algolia, while actual value on input is used to display the value on input.
 * so at given time they may contain different values.
 * That was needed for autocompletion on arrow keys, due to input may contain some value that should not trigger actual search
 */
const useInputControls = (): HeaderSearchContextValue['inputControls'] => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchInputValueOnState, setSearchInputValueOnState] = useState('')
  const [renderedSearchRecords, setRenderedSearchRecords] = useState<
    AlgoliaRecord[]
  >([])
  const [renderedSuggestionRecords, setRenderedSuggestionRecords] = useState<
    AlgoliaQueryRecord[]
  >([])

  const setSearchInputValue: HeaderSearchContextValue['inputControls']['setSearchInputValue'] =
    (value, { updateSearchQuery }) => {
      if (updateSearchQuery) {
        setSearchQuery(value)
      }
      setSearchInputValueOnState(value)
    }

  return useMemo((): HeaderSearchContextValue['inputControls'] => {
    return {
      clearSearch: (): void => {
        setSearchInputValue('', { updateSearchQuery: true })
      },
      renderedSearchRecords,
      renderedSuggestionRecords,

      /**
       * value on input itself, when needed it can be used to update search query
       */
      searchInputValue: searchInputValueOnState,

      searchQuery,
      setRenderedSearchRecords,
      setRenderedSuggestionRecords,

      /**
       * sets value on input and updates search query if needed
       * @param value value to be set on input
       * @param updateQuery if true search query will be updated to match value, effectively triggering search
       */
      setSearchInputValue,
    }
  }, [
    renderedSuggestionRecords,
    renderedSearchRecords,
    searchInputValueOnState,
    searchQuery,
  ])
}

/**
 * State management for header search.
 * @remark:
 * part of header search/results is loaded dynamically. Dynamically loaded component will set e.g. client search to context,
 * so it's as well available higher than dyn. loaded components.
 */
export const HeaderSearchContextProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [searchIsActive, setSearchIsActive] = useState(false)
  const searchClientControls = useSearchClientControls()
  const previousSearchControls = usePreviousSearchControls()
  const inputControls = useInputControls()
  const preQueryExperiment = useGetExperimentForTenant(
    EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS,
  )
  const configuration = useAlgoliaConfigContext()
  const globalConfig = useGlobalConfigContext()
  const [portalResultsTo, setPortalResultsTo] = useState<
    HTMLDivElement | null | undefined
  >(undefined)

  const contextValue: HeaderSearchContextValue = useMemo(
    (): HeaderSearchContextValue => ({
      configuration,
      handleSubmitSearch: (): void => {
        handleSubmitSearch(globalConfig, inputControls)
      },
      inputControls,
      portalResultsTo,
      previousSearchControls,
      searchClientControls,
      searchIsActive,
      setSearchIsActive: ({
        portalResultsToElement,
        value,
      }: {
        portalResultsToElement?: HTMLDivElement | null
        value: boolean
      }): void => {
        setSearchIsActive(value)
        setPortalResultsTo(portalResultsToElement)
      },

      /**
       * if search is active but user did not yet type anything, some default recommendations: top queries and top seller products will be shown
       */
      shouldShowDefaultRecommendations: (): boolean => {
        if (
          !preQueryExperiment.isEnabled ||
          preQueryExperiment.variant === EXPERIMENT_VARIATION.DEFAULT
        ) {
          return false
        }

        return searchIsActive && inputControls.searchQuery.length === 0
      },
      /**
       * https://jira.shop-apotheke.com/browse/WSAWA-2281
       */
      trackSuggestItemClick: (): void => {
        headerSearchSessionStorageTrackingTrackSuggestItemClick({
          qSuggest: inputControls.renderedSearchRecords?.length ?? 0,
          searchPhrase: inputControls.searchQuery,
        })
      },
    }),
    [
      configuration,
      globalConfig,
      inputControls,
      portalResultsTo,
      searchClientControls,
      searchIsActive,
      previousSearchControls,
      preQueryExperiment,
    ],
  )

  return (
    <HeaderSearchContext.Provider value={contextValue}>
      {children}
    </HeaderSearchContext.Provider>
  )
}

export const useHeaderSearchContext = (): HeaderSearchContextValue => {
  const contextValue = useContext(HeaderSearchContext)

  if (!contextValue) {
    throw new Error(
      'useHeaderSearchStore must be used within a HeaderSearchStoreProvider',
    )
  }

  return contextValue
}
