'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type AlgoliaConfiguration } from '../config/AlgoliaConfiguration'

const AlgoliaConfigContext = createContext<AlgoliaConfiguration | null>(null)

type AlgoliaConfigContextProviderProps = PropsWithChildren<{
  algoliaConfig: AlgoliaConfiguration
}>

export const AlgoliaConfigContextProvider: FC<
  AlgoliaConfigContextProviderProps
> = ({ algoliaConfig, children }) => (
  <AlgoliaConfigContext.Provider value={algoliaConfig}>
    {children}
  </AlgoliaConfigContext.Provider>
)

export const useAlgoliaConfigContext = (): AlgoliaConfiguration => {
  const algoliaConfig = useContext(AlgoliaConfigContext)

  if (!algoliaConfig) {
    throw new Error('ALGOLIA_CONFIG_NOT_PROVIDED')
  }

  return algoliaConfig
}
