import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useGlobalConfigContext } from '../../../../../../global-config'
import { urlResolverGetRegister } from '../../../../../../url-handling'

export const HeaderRegistration: FC = () => {
  const globalConfig = useGlobalConfigContext()

  return (
    <div className="mb-5 whitespace-nowrap border-b border-dark-primary-min pb-5 text-m text-dark-primary-max">
      <FormattedMessage id="menubar.AccountFlyout.label.newCustomer" />
      <a
        className="ml-1 font-bold text-dark-brand hover:text-dark-pressed-brand hover:underline"
        href={urlResolverGetRegister(globalConfig)}
      >
        <FormattedMessage id="general.link.label.registerNow" />
      </a>
    </div>
  )
}
