import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconPrescription } from '@redteclab/icons'

import { useExpaContext } from '../../../../expa'
import { useGlobalConfigContext } from '../../../../global-config'
import { TENANT } from '../../../../tenant'
import {
  urlResolverGetRedeemPaperPrescription,
  urlResolverScanEprescription,
} from '../../../../url-handling'

import { HeaderCart } from './HeaderCart'
import { HeaderProfile } from './HeaderProfile'

const REZEPT_TENANTS = new Set([TENANT.CH, TENANT.COM])

const shallShowPresciptionIcon = ({
  expa,
  tenant,
}: {
  expa: string | null
  tenant: TENANT
}): boolean => {
  // only specific tenants have prescription icon
  if (!REZEPT_TENANTS.has(tenant)) {
    return false
  }
  /**
   * usecase for CH - when gglp hide prescription icon
   * https://jira.shop-apotheke.com/browse/WSAWA-5470
   */
  if (expa && tenant === TENANT.CH && ['ggl', 'gglp'].includes(expa)) {
    return false
  }

  return true
}

export const HeaderMiddleSectionIconsBox: FC = () => {
  const publicConfig = useGlobalConfigContext()
  const expa = useExpaContext()

  return (
    <ul className="inline-flex">
      <HeaderProfile />
      {shallShowPresciptionIcon({ expa, tenant: publicConfig.tenant }) ? (
        <li className="flex">
          <a
            className="group flex flex-col items-center justify-end px-1.5"
            href={
              publicConfig.tenant === TENANT.COM
                ? urlResolverScanEprescription(publicConfig)
                : urlResolverGetRedeemPaperPrescription(publicConfig)
            }
          >
            <IconPrescription className="mb-2 size-6 fill-dark-primary-max group-hover:fill-dark-hover-accent group-active:fill-dark-pressed-accent" />
            <span className="whitespace-nowrap font-medium leading-none text-dark-primary-max group-hover:text-dark-hover-accent group-active:text-dark-pressed-accent">
              <FormattedMessage id="header.icon.receiptText" />
            </span>
          </a>
        </li>
      ) : null}
      <li className="flex">
        <HeaderCart />
      </li>
    </ul>
  )
}
