import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconClose } from '@redteclab/icons'

interface AdServerBannerCloseButtonProps {
  onClick: () => void
}

export const AdServerBannerCloseButton: FC<AdServerBannerCloseButtonProps> = ({
  onClick,
}) => (
  <button
    className="absolute right-1 top-1 z-[1] text-3xl text-light-primary-strong"
    onClick={onClick}
    type="button"
  >
    <IconClose />
    <span className="sr-only">
      <FormattedMessage id="general.link.label.close" />
    </span>
  </button>
)
