import {
  type CmsBlockMenuItemsInner,
  type CmsComponentMenu,
  CmsComponentMenuSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

export const headerCmsBlockMenuItemsInnerAccessorIsComponentMenu = (
  columnData: CmsBlockMenuItemsInner,
): columnData is CmsComponentMenu => {
  return columnData.schema === CmsComponentMenuSchemaEnum.CmsComponentMenu
}
