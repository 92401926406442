'use client'

import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
} from 'react'
import { useBoolean } from 'usehooks-ts'

interface HeaderBackdropContextValues {
  hideBackdrop: () => unknown
  showBackdrop: () => unknown
}

const HeaderBackdropContext = createContext<HeaderBackdropContextValues>({
  hideBackdrop: () => undefined,
  showBackdrop: () => undefined,
})

export const HeaderBackdropContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { setFalse, setTrue, value } = useBoolean(false)

  const providerValues = useMemo(
    () => ({
      hideBackdrop: setFalse,
      showBackdrop: setTrue,
    }),
    [setTrue, setFalse],
  )

  return (
    <HeaderBackdropContext.Provider value={providerValues}>
      {value ? (
        <div className="fixed left-0 top-0 z-10 size-full bg-backdrop" />
      ) : null}
      {children}
    </HeaderBackdropContext.Provider>
  )
}

export const useHeaderBackdropContext = (): HeaderBackdropContextValues =>
  useContext(HeaderBackdropContext)
