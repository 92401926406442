import { type PopperProps as PopperUnstyledProps } from '@mui/base'

/**
 * Sets dropdown width same as reference element
 */
export const sameWidth: Exclude<
  PopperUnstyledProps['modifiers'],
  undefined
>[number] = {
  effect: ({ state }) => {
    const { popper, reference } = state.elements

    if (reference instanceof HTMLElement) {
      popper.style.width = `${reference.offsetWidth}px`
    }
  },
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  name: 'sameWidth',
  phase: 'beforeWrite',
  requires: ['computeStyles'],
}
