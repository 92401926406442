import { type FC } from 'react'

import { FooterCategoriesSection } from './FooterCategoriesSection'
import { type FooterTopSectionProps } from './FooterTopSectionProps'
import { FooterTrusted } from './FooterTrusted'
import { FooterUsp } from './FooterUsp'

export const FooterTopSection: FC<FooterTopSectionProps> = ({
  categories,
  childComponentClassNames = '',
  trustedItems,
  uspItems,
}) => (
  <>
    {categories ? (
      <FooterCategoriesSection
        categories={categories}
        className={childComponentClassNames}
      />
    ) : null}
    {uspItems ? <FooterUsp uspItems={uspItems} /> : null}
    <FooterTrusted
      className={childComponentClassNames}
      trustedItems={trustedItems}
    />
  </>
)
