import { clsx } from 'clsx'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON,
  experimentsTrackEvent,
} from '../../../../../experiments/eventTracking'
import { useGlobalConfigContext } from '../../../../../global-config'
import { urlResolverGetCart } from '../../../../../url-handling'
import { useUserApiSwrGetMiniCart } from '../../../../../user-data'

import { HeaderCartIconAndBadge } from './HeaderCardIconAndBadge'

interface HeaderCartProps {
  isMobile?: boolean
}

const HeaderCart: FC<HeaderCartProps> = ({ isMobile = false }) => {
  const globalConfig = useGlobalConfigContext()
  const miniCartSwr = useUserApiSwrGetMiniCart()
  const entriesInCart = miniCartSwr.data?.entriesInCart
  const intl = useIntl()

  return (
    <a
      className={clsx(
        'group relative flex flex-col items-center justify-end px-1.5 tablet:pr-0',
        isMobile && 'py-1.5',
      )}
      data-qa-id="cart-button"
      href={urlResolverGetCart(globalConfig)}
      onClick={() => {
        experimentsTrackEvent(
          globalConfig,
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON,
        )
      }}
    >
      <div className="flex items-start">
        <HeaderCartIconAndBadge
          entriesInCart={entriesInCart}
          isMobile={isMobile}
        />
      </div>
      {!isMobile && (
        <span className="font-medium leading-none text-dark-primary-max group-hover:text-dark-hover-accent group-active:text-dark-pressed-accent">
          {intl.formatMessage({ id: 'menubar.link.label.cart' })}
        </span>
      )}
    </a>
  )
}

export { HeaderCart }
