'use client'

import { type SelectListboxSlotProps as MuiSelectListboxSlotProps } from '@mui/base'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import { clsx } from 'clsx'
import {
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useRef,
} from 'react'

import { IconClose } from '@redteclab/icons'

type ListboxProps = Omit<
  MuiSelectListboxSlotProps<Record<string, unknown>, false>,
  'ref'
> & { title?: ReactNode }

export const Listbox = forwardRef<HTMLDivElement, ListboxProps>(
  function Listbox(props, forwardedRef) {
    const {
      children,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- variable is not needed for rendering
      ownerState,
      title,
      ...rest
    } = props

    const ref = useRef<HTMLDivElement>(null)

    const { onBlur } = rest

    const onClose: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        // @ts-expect-error -- emulate listbox close behaviour
        onBlur(event)
      },
      [onBlur],
    )

    return (
      <div
        className={clsx('select-listbox', className)}
        ref={useForkRef(ref, forwardedRef)}
        {...rest}
      >
        <header className="select-listbox__header">
          {title ? (
            <span className="select-listbox__title">{title}</span>
          ) : null}
          <button
            className="select-listbox__close-button"
            onClick={onClose}
            type="button"
          >
            <IconClose className="select-listbox__close-button-icon" />
          </button>
        </header>
        <ul className="select-listbox__list">{children}</ul>
      </div>
    )
  },
)
