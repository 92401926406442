import {
  TabsList as MuiTabsList,
  type TabsListProps as MuiTabsListProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

export const TabsList = forwardRef(function Tab(
  props: MuiTabsListProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className } = props

  return (
    <MuiTabsList
      {...props}
      className={clsx('tabs-list', className)}
      ref={ref}
    />
  )
}) as typeof MuiTabsList
