import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const OffCanvasHeaderMenuRootSlideSaluteUserItem: FC<{
  userFirstName: string
  userLoggedIn: boolean
}> = ({ userFirstName, userLoggedIn }) => {
  if (userLoggedIn) {
    return (
      <>
        <FormattedMessage id="offcanvas.header.hello" /> {` ${userFirstName}`}
      </>
    )
  }

  return <FormattedMessage id="offcanvas.header.title" />
}
