import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { SocialMediaImage } from '../../../../../../social-media'

import { type FooterSocialProps } from './FooterSocialProps'

const FooterSocial: FC<FooterSocialProps> = ({ socialLinksItems }) => {
  if (socialLinksItems.length === 0) {
    return null
  }

  return (
    <div className="mb-3 flex items-center">
      <h3 className="mr-3.5 hidden text-m font-bold tablet:block">
        <FormattedMessage id="footer.headline.followUs" />
      </h3>
      <ul>
        {socialLinksItems.map(({ href, id }) => (
          <li className="mr-2 inline-block" key={id}>
            <a href={href} rel="noreferrer" target="_blank">
              <SocialMediaImage iconName={id} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export { FooterSocial }
