'use client'

import dynamic from 'next/dynamic'
import { type AwaitedReactNode, type FC, type ReactNode } from 'react'

import { AlgoliaConfigContextProvider } from '../../../algolia'
import { ErrorBoundary } from '../../../error-boundary'
import { ExitIntentPopupModal } from '../../../experiments/exit-intent-popup-experiment'
import { NewsletterModalExperiment } from '../../../experiments/newsletter-modal-experiment/NewsletterModalExperiment'
import { useWebviewContext } from '../../../webview'
import { AdvertisingFlyoutBanner } from '../../advertisingFlyoutBanner'
import { AlternativeUrlsContextProvider } from '../../alternativeUrls'
import { ErxModal } from '../../erxModal'
import { Footer } from '../../footer/components/Footer'
import { HeaderWithProviders } from '../../header/components/Header'
import { type StandardLayoutProps } from '../../StandardLayoutProps'
import { StickyBannerContextProvider } from '../../stickyBanner/StickyBannerContext'

import { ButtonScrollToTop } from './ButtonScrollToTop/ButtonScrollToTop'

const StickyBanner = dynamic(
  async () =>
    import('../../stickyBanner/StickyBanner').then((mod) => mod.StickyBanner),
  {
    ssr: false,
  },
)

type StandardShopLayoutProps = {
  children?: Exclude<ReactNode, Promise<AwaitedReactNode>>
  showStandardLayoutFooter?: boolean
  standardLayoutData: StandardLayoutProps
}

export const StandardShopLayout: FC<StandardShopLayoutProps> = ({
  children,
  showStandardLayoutFooter = true,
  standardLayoutData,
}) => {
  const isWebview = useWebviewContext()

  if (isWebview) {
    return children
  }

  return (
    <AlternativeUrlsContextProvider
      alternativeUrls={standardLayoutData.alternativeUrls}
    >
      <AlgoliaConfigContextProvider
        algoliaConfig={standardLayoutData.algoliaConfig}
      >
        <StickyBannerContextProvider>
          <HeaderWithProviders standardLayoutData={standardLayoutData} />
          <AdvertisingFlyoutBanner
            advertisingFlyoutBannerConfig={
              standardLayoutData.advertisingFlyoutBannerConfig
            }
          />
          <ExitIntentPopupModal
            exitIntentPopupModalConfig={
              standardLayoutData.exitIntentPopupModalConfig
            }
          />
          <NewsletterModalExperiment />
          <ErxModal />
          {children}
          {showStandardLayoutFooter ? (
            <Footer footerData={standardLayoutData.footerData} />
          ) : null}
          <ButtonScrollToTop />
          <ErrorBoundary>
            <StickyBanner bannerData={standardLayoutData.headerData} />
          </ErrorBoundary>
        </StickyBannerContextProvider>
      </AlgoliaConfigContextProvider>
    </AlternativeUrlsContextProvider>
  )
}
