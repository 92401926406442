import {
  type FC,
  type ForwardRefExoticComponent,
  type RefAttributes,
  type SVGProps,
} from 'react'

import { IconPills } from '@redteclab/icons'

import {
  IconCertificate,
  IconCircleInfoFilled,
  IconDelivery,
  IconMessageRight,
  IconPackagePrice,
  IconSmiley,
} from '../../../../../icons'

const iconMap: Record<
  string,
  | FC<SVGProps<SVGSVGElement>>
  | ForwardRefExoticComponent<
      Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
    >
> = {
  certificate: IconCertificate,
  chat: IconMessageRight,
  circleInfo: IconCircleInfoFilled,
  delivery: IconDelivery,
  packagePrice: IconPackagePrice,
  pills: IconPills,
  smiley: IconSmiley,
}

type FooterFixedSizeIconProps = {
  name: string
}

export const FooterFixedSizeIcon: FC<FooterFixedSizeIconProps> = ({ name }) => {
  const Component = name in iconMap ? iconMap[name] : undefined

  if (!Component) {
    return null
  }

  return <Component className="mr-4 size-11 fill-dark-brand" />
}
