import { Slider as BaseSlider, type SliderProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

import { SliderRail } from './SliderRail'
import { SliderRoot } from './SliderRoot'
import { SliderThumb } from './SliderThumb'
import { SliderTrack } from './SliderTrack'

/**
 * A slider is a UI element that lets users select a single value or a range of values along a bar.
 */
export const Slider = forwardRef(function Slider(
  props: SliderProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  const { className, slots } = props

  return (
    <BaseSlider
      {...props}
      className={clsx('slider', className)}
      ref={ref}
      slots={{
        rail: SliderRail,
        root: SliderRoot,
        thumb: SliderThumb,
        track: SliderTrack,
        ...slots,
      }}
    />
  )
}) as typeof BaseSlider
