import { flip, offset, shift, size } from '@floating-ui/react-dom'
import {
  Select as MuiSelect,
  type PopupMiddleware,
  type SelectProps,
  type SelectSlots,
  type SelectType,
  useFormControlContext,
  type UseFormControlContextReturnValue,
} from '@mui/base'
import { type ForwardedRef, forwardRef } from 'react'

import { Listbox } from './Listbox'
import { Popup } from './Popup'
import { Root } from './Root'

const middleware: PopupMiddleware[] = [
  flip(),
  shift(),
  offset(),
  size({
    apply: (state) => {
      const { elements, placement, rects } = state

      elements.floating.style.width = `${rects.reference.width}px`
      elements.floating.dataset.popupPlacement = placement

      if (elements.reference instanceof HTMLElement) {
        elements.reference.dataset.popupPlacement = placement
      }
    },
  }),
]

export { type SelectProps } from '@mui/base'

const checkFormControl = <
  OptionValue extends NonNullable<unknown>,
  Multiple extends boolean,
>(
  props: SelectProps<OptionValue, Multiple>,
  formControlContext: UseFormControlContextReturnValue,
): void => {
  const definedLocalProps = (['disabled', 'required'] as const).filter(
    (prop) => props[prop] !== undefined,
  )

  if (definedLocalProps.length > 0) {
    console.warn(
      [
        'Base UI: You have set props on an Select that is inside a FormControl.',
        'Set these props on a FormControl instead. Otherwise they will be ignored.',
        `Ignored props: ${definedLocalProps.join(', ')}`,
      ].join('\n'),
    )
  }

  const duplicateLocalProps = (['defaultValue', 'value'] as const).filter(
    (prop) => props[prop] && !formControlContext.value,
  )

  if (duplicateLocalProps.length > 0) {
    console.warn(
      [
        'Base UI: You have set props on an Select that is inside a FormControl.',
        'Set these props on a FormControl also. Otherwise styling will be affected (example label display).',
        `Duplicate props: ${duplicateLocalProps.join(', ')}`,
      ].join('\n'),
    )
  }
}

/**
 * Select components are used for collecting user provided information from a list of options.
 */
export const Select = forwardRef(function Select<
  OptionValue extends NonNullable<unknown>,
  Multiple extends boolean,
>(
  props: SelectProps<OptionValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const {
    disabled: disabledProp,
    required: requiredProp,
    slotProps,
    slots: slotsProp,
    ...other
  } = props

  const slots = {
    listbox: Listbox,
    popup: Popup,
    root: Root,
    ...slotsProp,
  } satisfies SelectSlots

  const formControlContext = useFormControlContext()

  // inspired by https://github.com/mui/material-ui/blob/d16c76e41d1872e40499e1b6d1b4427f615997c8/packages/mui-base/src/useInput/useInput.ts#L38-L72
  /* eslint-disable @typescript-eslint/init-declarations,@typescript-eslint/prefer-destructuring */
  let disabled: boolean | undefined
  let required: boolean | undefined

  if (formControlContext) {
    disabled = formControlContext.disabled
    required = formControlContext.required

    if (process.env.NODE_ENV !== 'production') {
      checkFormControl(props, formControlContext)
    }
  } else {
    disabled = disabledProp
    required = requiredProp
  }
  /* eslint-enable @typescript-eslint/init-declarations,@typescript-eslint/prefer-destructuring */

  return (
    <MuiSelect
      {...other}
      disabled={disabled}
      ref={ref}
      required={required}
      slotProps={{
        ...slotProps,
        popup: {
          middleware,
          ...slotProps?.popup,
        },
      }}
      slots={slots}
    />
  )
}) as SelectType
