import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { type CmsBlockMenuItemsInner } from '@redteclab/api/clients/content-management-system'

import { Experiment } from '../../../../../experiments/components/Experiment'
import { ExperimentVariant } from '../../../../../experiments/components/ExperimentVariant'
import { EXPERIMENT_NAME_NFC_BANNER_ERX_LANDING_PAGE } from '../../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../../experiments/model/EXPERIMENT_VARIATION'
import { useExperimentalAddStaticFileHost } from '../../../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { useGlobalConfigContext } from '../../../../../global-config'
import { StaticImage } from '../../../../../image'
import { TENANT } from '../../../../../tenant'
import {
  urlNormalizeUri,
  urlResolverNfcLandingPage,
} from '../../../../../url-handling'
import { useHeaderDataContext } from '../../../headerConfigContext'
import ePrescriptionBanner from '../../../images/e-prescription-banner.png'
import { headerCmsBlockMenuItemsInnerAccessorIsComponentMenu } from '../../../model/headerCmsBlockMenuItemsInnerAccessor'

import { HeaderCategoryColumn } from './HeaderCategoryColumn'

interface HeaderCategoriesListProps {
  categories: CmsBlockMenuItemsInner[]
  isVisible: boolean
}

export const HeaderCategoriesList: FC<HeaderCategoriesListProps> = ({
  categories,
  isVisible,
}) => {
  const config = useGlobalConfigContext()
  const headerData = useHeaderDataContext()
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()
  const { locale, tenant } = config
  const { redcareOwnBrandsBanner } = headerData

  return (
    <div
      aria-hidden={!isVisible}
      className={clsx(
        'absolute z-10 w-full max-w-5xl overflow-hidden rounded bg-light-primary-low',
        isVisible ? 'block' : 'hidden',
      )}
    >
      <div className="mx-auto flex bg-light-primary-low">
        {categories.map((columnData, index) => {
          // necessary to reduce correct type
          if (
            !headerCmsBlockMenuItemsInnerAccessorIsComponentMenu(columnData)
          ) {
            return null
          }

          const isFirstColumn = index === 0

          return (
            <HeaderCategoryColumn
              className={clsx(
                'flex flex-1 flex-col p-4',
                isFirstColumn && 'bg-light-brand-medium pr-16',
              )}
              columnData={columnData}
              isFirstColumn={isFirstColumn}
              key={columnData.title}
            />
          )
        })}
        {redcareOwnBrandsBanner?.[locale] ? (
          <div className="pr-4 pt-4">
            {tenant === TENANT.COM ? (
              <Experiment names={[EXPERIMENT_NAME_NFC_BANNER_ERX_LANDING_PAGE]}>
                <ExperimentVariant name={EXPERIMENT_VARIATION.DEFAULT}>
                  {() => (
                    <a
                      href={urlNormalizeUri({
                        locale,
                        uri: redcareOwnBrandsBanner[locale]?.href,
                      })}
                    >
                      <Image
                        alt=""
                        height={312}
                        src={addStaticCdnHostToUrl(
                          redcareOwnBrandsBanner[locale]?.src,
                        )}
                        unoptimized
                        width={150}
                      />
                    </a>
                  )}
                </ExperimentVariant>

                <ExperimentVariant name={EXPERIMENT_VARIATION.V1}>
                  {() => (
                    <a href={urlResolverNfcLandingPage(config)}>
                      <StaticImage
                        alt=""
                        height={292}
                        src={ePrescriptionBanner}
                        width={200}
                      />
                    </a>
                  )}
                </ExperimentVariant>
              </Experiment>
            ) : (
              <a
                href={urlNormalizeUri({
                  locale,
                  uri: redcareOwnBrandsBanner[locale].href,
                })}
              >
                <Image
                  alt=""
                  height={312}
                  src={addStaticCdnHostToUrl(
                    redcareOwnBrandsBanner[locale].src,
                  )}
                  unoptimized
                  width={150}
                />
              </a>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}
