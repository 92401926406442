import { EXPERIMENT_VARIATION } from '../model/EXPERIMENT_VARIATION'
import { type ExperimentType } from '../model/Experiment.types'
import { experimentAccessorFilterAndGetExperiments } from '../model/experimentAccessor'

export const DEFAULT_EXPERIMENT: ExperimentType = {
  active: false,
  isEnabled: false,
  name: '',
  variables: {},
  variant: EXPERIMENT_VARIATION.DEFAULT,
}

export const experimentComponentCompileAndGetExperiment = (
  experimentsNames: ExperimentType['name'][],
  experiments: ExperimentType[],
): ExperimentType => {
  // get all experiments that are enabled
  const activeExperiments = experimentAccessorFilterAndGetExperiments(
    experimentsNames,
    experiments,
  )
  /*
   * get the first enabled experiments name, it doesn't matter which one
   * if it doesn't exist, get it from the parameter
   */
  const experimentName = activeExperiments[0]?.name ?? experimentsNames[0]

  /*
   * get the first enabled experiment
   * if it doesn't exist, get the default experiment with the name from the parameter
   * so the default version of the experiment is rendered in any case
   */
  const defaultExperiment = {
    ...DEFAULT_EXPERIMENT,
    name: experimentName,
  }

  return activeExperiments[0] || defaultExperiment
}
