'use client'

import Script from 'next/script'
import { type FC } from 'react'
import useSWRImmutable from 'swr/immutable'

import { gtmDataLayerWaitTillEventLoaded } from '../gtm'

import { useAppFlyerContext } from './context/AppsFlyerContext'

import styles from './AppsFlyerBannerMounter.module.css'

const APPS_FLYER_CONTAINER_ID = 'appsflyer-container'

export const AppsFlyerBannerMounter: FC = () => {
  const {
    config: { key },
  } = useAppFlyerContext()
  const { data } = useSWRImmutable('gtm.data.loaded', async () =>
    gtmDataLayerWaitTillEventLoaded({
      eventName: 'gtm.data.loaded',
      timeout: 2000,
    }),
  )

  return (
    <>
      <div className={styles['flyer-banner']} id={APPS_FLYER_CONTAINER_ID} />
      {data ? (
        <Script id="apps-flyer">
          {/*
           * https://jira.shop-apotheke.com/browse/CRO-671
           * https://dev.appsflyer.com/hc/docs/dl_smart_banner_v2#smart-banners-only
           */}
          {`
!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${key}"}});

AF('banners', 'showBanner', { bannerContainerQuery: '#${APPS_FLYER_CONTAINER_ID}', bannerZIndex: 'auto' });
dataLayer.push({ event: 'af_banner' });
        `}
        </Script>
      ) : null}
    </>
  )
}
