import { useSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  type ForwardedRef,
  forwardRef,
} from 'react'

import { CounterIndicator } from '../CounterIndicator'

export type NumberedListItemProps = ComponentPropsWithoutRef<'li'> & {
  slotProps?: {
    content?: ComponentPropsWithRef<'div'>
    counter?: ComponentPropsWithRef<typeof CounterIndicator>
  }
}

/**
 * The `NumberedListItem` is used to represent an item in a list. It must be contained in a parent ordered list (`<ol>`) element.
 * In ordered lists, they are displayed with an ascending customizable number counter (`CounterIndicator`) on the left.
 *
 * `CounterIndicator` customization is allowed through `slotProps.counter` property.
 */
export const NumberedListItem = forwardRef(function NumberedListItem(
  props: NumberedListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const { children, className, slotProps = {}, ...rest } = props

  const contentProps = useSlotProps({
    className: 'numbered-list-item__content',
    elementType: 'div',
    externalSlotProps: slotProps.content,
    ownerState: undefined,
  })

  const counterProps = useSlotProps({
    className: 'numbered-list-item__counter',
    elementType: undefined,
    externalSlotProps: slotProps.counter,
    ownerState: undefined,
  })

  return (
    <li className={clsx('numbered-list-item', className)} {...rest} ref={ref}>
      <CounterIndicator {...counterProps} />
      <div {...contentProps}>{children}</div>
    </li>
  )
})
