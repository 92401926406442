import { clsx } from 'clsx'
import { type FC, type MouseEvent } from 'react'

import { IconCaretRight } from '@redteclab/icons'

import { HeaderCategoryIconRenderer } from '../../../../../components/HeaderCategoryIconRenderer'

interface OffCanvasHeaderMenuListItemProps {
  className?: string
  iconType?: string
  isExpandable: boolean
  link?: string
  onExpandClick?: (e: MouseEvent) => void
  title: string
}

const OffCanvasHeaderMenuListItem: FC<OffCanvasHeaderMenuListItemProps> = ({
  className,
  iconType,
  isExpandable,
  link,
  onExpandClick,
  title,
}) => {
  return (
    <li className={clsx('relative', className)}>
      <a
        className={clsx(
          'flex h-12 items-center justify-between px-5',
          'after:absolute after:inset-x-5 after:bottom-0 after:h-0 after:border-b after:border-dark-primary-min',
        )}
        data-qa-id={title === 'RedPoints' ? `form-${title}` : undefined}
        href={link}
        onClick={onExpandClick}
      >
        <span className="flex w-full flex-row items-center gap-1">
          {iconType ? (
            <HeaderCategoryIconRenderer
              className="text-3xl"
              iconType={iconType}
            />
          ) : null}
          {title}
        </span>
        {isExpandable ? (
          <IconCaretRight className="size-6 shrink-0 grow" />
        ) : null}
      </a>
    </li>
  )
}

export { OffCanvasHeaderMenuListItem }
