import { type FC, type ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  FEATURE_TOGGLE_REDPOINTS_ACTIVE,
  useFeatureToggleContext,
} from '../../../../../../feature-toggles'
import {
  type PublicGlobalConfig,
  useGlobalConfigContext,
} from '../../../../../../global-config'
import {
  urlResolverGetAccount,
  urlResolverGetAccountNotepad,
  urlResolverGetAccountOrders,
  urlResolverGetAccountRedpoints,
} from '../../../../../../url-handling'

const getAccountLinks = (
  globalConfig: PublicGlobalConfig,
  isRedpointsActive: boolean,
): { href: string; key: string; message: ReactNode }[] => {
  const accountLinks = [
    {
      href: urlResolverGetAccount(globalConfig),
      key: 'header-account-overview',
      message: <FormattedMessage id="menubar.AccountFlyout.link.MyOverview" />,
    },
    {
      href: urlResolverGetAccountOrders(globalConfig),
      key: 'header-account-orders',
      message: (
        <FormattedMessage id="menubar.AccountFlyout.link.MyOrdersAndInvoices" />
      ),
    },
    {
      href: urlResolverGetAccountNotepad(globalConfig),
      key: 'header-account-notepad',
      message: <FormattedMessage id="general.label.wishlist" />,
    },
  ]

  if (isRedpointsActive) {
    accountLinks.push({
      href: urlResolverGetAccountRedpoints(globalConfig),
      key: 'header-account-redpoints',
      message: <FormattedMessage id="menubar.AccountFlyout.link.MyRedpoints" />,
    })
  }

  return accountLinks
}

export const HeaderMyAccountLinks: FC = () => {
  const globalConfig = useGlobalConfigContext()
  const featureToggles = useFeatureToggleContext()

  const isRedpointsActive = Boolean(
    featureToggles[FEATURE_TOGGLE_REDPOINTS_ACTIVE],
  )

  const accountLinks = useMemo(
    () => getAccountLinks(globalConfig, isRedpointsActive),
    [globalConfig, isRedpointsActive],
  )

  return (
    <ul>
      {accountLinks.map(({ href, key, message }) => (
        <li
          className="mb-3 whitespace-nowrap last:mb-0"
          data-qa-id={key}
          key={key}
        >
          <a
            className="text-s font-normal leading-m text-dark-primary-max hover:text-dark-pressed-brand hover:underline"
            href={href}
          >
            {message}
          </a>
        </li>
      ))}
    </ul>
  )
}
