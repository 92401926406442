import { Tabs as MuiTabs, type TabsProps as MuiTabsProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

/**
 * Tabs are UI elements for organizing and navigating between groups of related content.
 *
 * Tabs are implemented using a collection of related components:
 *
 * * `<Tab />` — the tab element itself. Clicking on a tab displays its corresponding panel.
 * * `<TabsList />` — the container that houses the tabs. Responsible for handling focus and keyboard navigation between tabs.
 * * `<TabPanel />` — the card that hosts the content associated with a tab.
 * * `<Tabs />` — the top-level component that wraps the Tabs List and Tab Panel components so that tabs and their panels can communicate with one another.
 */
export const Tabs = forwardRef(function Tabs(
  props: MuiTabsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className } = props

  return <MuiTabs {...props} className={clsx('tabs', className)} ref={ref} />
}) as typeof MuiTabs
