import {
  type PolymorphicComponent,
  type PolymorphicProps,
  type SlotComponentProps,
  useSlotProps,
} from '@mui/base'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

type AccordionDetailsOwnProps = {
  slotProps?: {
    root?: SlotComponentProps<'div', NonNullable<unknown>, NonNullable<unknown>>
  }

  slots?: {
    root?: ElementType
  }
}

interface AccordionDetailsTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'div',
> {
  defaultComponent: RootComponentType
  props: AccordionDetailsOwnProps & AdditionalProps
}

export type AccordionDetailsProps<
  RootComponentType extends
    ElementType = AccordionDetailsTypeMap['defaultComponent'],
> = PolymorphicProps<
  AccordionDetailsTypeMap<NonNullable<unknown>, RootComponentType>,
  RootComponentType
>

export const AccordionDetails = forwardRef(function AccordionDetails(
  props: AccordionDetailsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { slotProps = {}, slots = {}, ...other } = props

  const Root = slots.root ?? 'div'

  const rootProps = useSlotProps({
    additionalProps: {
      ref,
    },
    className: 'accordion-details',
    elementType: Root,
    externalForwardedProps: other,
    externalSlotProps: slotProps.root,
    ownerState: {},
  })

  return <Root {...rootProps} ref={ref} />
}) as PolymorphicComponent<AccordionDetailsTypeMap>
