import { expaGetClientsideFrozenExpaFromCookie } from '../../expa'
import { useRunCallbackWhenSessionCallIsDone } from '../../user-data'
import { gtmDataLayerPushEventUserDataAvailable } from '../gtmDataLayer'

export const usePushGtmUserDataAvailableEvent = (): void => {
  useRunCallbackWhenSessionCallIsDone((data) => {
    const expaToPush = expaGetClientsideFrozenExpaFromCookie() ?? data?.expa

    gtmDataLayerPushEventUserDataAvailable({
      expa: expaToPush,
    })
  })
}
