import { type KeyboardEvent } from 'react'

const EVENT_KEY_ARROW_UP = 'ArrowUp'

const EVENT_KEY_ARROW_DOWN = 'ArrowDown'

const EVENT_KEY_ESCAPE = 'Escape'

export const baseKeyboardEventAccessorIsArrowDown = (
  event: KeyboardEvent,
): boolean => {
  return event.key === EVENT_KEY_ARROW_DOWN
}

export const baseKeyboardEventAccessorIsArrowUp = (
  event: KeyboardEvent,
): boolean => {
  return event.key === EVENT_KEY_ARROW_UP
}

export const baseKeyboardEventAccessorIsEscape = (
  event: KeyboardEvent,
): boolean => {
  return event.key === EVENT_KEY_ESCAPE
}
