import dynamic from 'next/dynamic'

import { Loading } from '../../../../../../loading'

/**
 * Wrapper for async loading of {@link HeaderSearchAlgoliaContainer}.
 * Needed because we load algolia dependencies asynchronously.
 */
export const DynamicHeaderSearchAlgoliaContainer = dynamic(
  async () => {
    return import('./HeaderSearchAlgoliaContainer').then(
      (mod) => mod.HeaderSearchAlgoliaContainer,
    )
  },
  {
    loading: () => <Loading className="h-56 min-h-full" />,
    ssr: false,
  },
)
