import { type PopperProps as PopperUnstyledProps } from '@mui/base'

/**
 * Provides placement value to reference element for customization
 */
export const placementHandler: Exclude<
  PopperUnstyledProps['modifiers'],
  undefined
>[number] = {
  enabled: true,
  fn: ({ state }) => {
    const {
      elements: { reference },
      placement,
    } = state

    if (reference instanceof HTMLElement) {
      reference.dataset.popperPlacement = placement
    }
  },
  name: 'positionHandler',
  phase: 'beforeWrite',
}
