'use client'

import {
  ClickAwayListener,
  Popper as PopperUnstyled,
  type PopperProps as PopperUnstyledProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  cloneElement,
  type ComponentProps,
  forwardRef,
  Fragment,
  type ReactElement,
  type ReactNode,
  useMemo,
  useRef,
} from 'react'

import { useTooltipToggle } from './useTooltipToggle'

type TooltipOwnProps = {
  /**
   * @default false
   */
  arrow?: boolean
  children: ReactElement<ComponentProps<'div'>>
  clickTrigger?: boolean
  open?: boolean
  placement?: 'bottom' | 'left' | 'right' | 'top'
  title: ReactNode
}

type TooltipProps = Omit<PopperUnstyledProps, keyof TooltipOwnProps> &
  TooltipOwnProps

const OFFSET_FROM_ANCHOR = 12

/**
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  function Tooltip(props, ref) {
    const {
      anchorEl,
      arrow = false,
      children,
      className,
      clickTrigger = false,
      modifiers: modifiersProp,
      open: openProp,
      placement = 'bottom',
      title,
      ...rest
    } = props

    const childRef = useRef(null)

    const {
      onTooltipClose,
      onTooltipOpen,
      onTooltipPointerLeave,
      onTooltipToggle,
      tooltipOpen,
    } = useTooltipToggle(Boolean(openProp))

    if (process.env.NODE_ENV !== 'production') {
      if (anchorEl === undefined && children.type === Fragment) {
        console.warn(
          'Tooltip children should not be a Fragment but an interactive element',
        )
      }

      if (anchorEl !== undefined && 'ref' in children.props) {
        console.warn('Children element has a ref already')
      }
    }

    const modifiers = useMemo(
      () => [
        {
          name: 'offset',
          options: {
            offset: [0, OFFSET_FROM_ANCHOR],
          },
        },
        ...(modifiersProp ?? []),
      ],
      [modifiersProp],
    )

    return (
      <>
        {clickTrigger ? (
          <ClickAwayListener onClickAway={onTooltipClose}>
            {cloneElement(children, {
              onClick: onTooltipToggle,
              ref: childRef,
            })}
          </ClickAwayListener>
        ) : (
          cloneElement(children, {
            onBlur: onTooltipClose,
            onFocus: onTooltipOpen,
            onPointerEnter: onTooltipToggle,
            onPointerLeave: onTooltipPointerLeave,
            ref: childRef,
          })
        )}

        <PopperUnstyled
          {...rest}
          anchorEl={anchorEl ?? childRef.current}
          className={clsx('tooltip', className)}
          modifiers={modifiers}
          open={tooltipOpen}
          placement={placement}
          ref={ref}
        >
          {Boolean(arrow) && <span className="tooltip__arrow" />}
          {title}
        </PopperUnstyled>
      </>
    )
  },
)
