'use client'

import {
  type PolymorphicComponent,
  type PolymorphicProps,
  type SlotComponentProps,
  useSlotProps,
} from '@mui/base'
import {
  type ChangeEvent,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  useId,
  useState,
} from 'react'

import { IconStar, IconStarFilled } from '@redteclab/icons'

export const RATING_DEFAULT_MAX = 5

type RatingPropsOwnProps = {
  /**
   * Maximum rating
   * @default 5
   */
  max?: number

  /**
   * Called when the user clicks on a star
   * @param value The 1-based index of the clicked star
   */
  onChange?: (event: ChangeEvent, value: number) => void

  /**
   * Removes all hover effects and pointer events
   */
  readOnly?: boolean

  slotProps?: {
    iconContainer?: SlotComponentProps<
      'span',
      NonNullable<unknown>,
      RatingOwnerState
    >

    root?: SlotComponentProps<'span', NonNullable<unknown>, RatingOwnerState>
  }

  slots?: {
    iconContainer?: ElementType
    root?: ElementType
  }

  /**
   * The rating value
   */
  value?: number
}

export type RatingOwnerState = {
  value: number
}

interface RatingTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'span',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps & RatingPropsOwnProps
}

export type RatingProps<
  RootComponentType extends ElementType = RatingTypeMap['defaultComponent'],
> = PolymorphicProps<
  RatingTypeMap<NonNullable<unknown>, RootComponentType>,
  RootComponentType
>

/**
 * Ratings provide insight regarding others' opinions and experiences
 */
export const Rating = forwardRef(function Rating(
  props: RatingProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  const {
    max = RATING_DEFAULT_MAX,
    onChange: onChangeProp,
    readOnly,
    slotProps = {},
    slots = {},
    value = 0,
    ...other
  } = props
  const [hoverValue, setHoverValue] = useState<number | null>(null)

  const items = [...Array.from({ length: max }).keys()]

  const id = useId()

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number.parseInt(event.currentTarget.value, 10)

    onChangeProp?.(event, newValue)
  }

  const ratingValue = hoverValue ?? value

  const ownerState: RatingOwnerState = {
    value,
  }

  const Root = slots.root ?? 'span'
  const rootProps = useSlotProps({
    additionalProps: {
      ref,
    },
    className: 'rating',
    elementType: Root,
    externalForwardedProps: other,
    externalSlotProps: slotProps.root,
    ownerState,
  })

  const IconContainer = slots.iconContainer ?? 'span'
  const iconContainerProps = useSlotProps({
    className: readOnly ? 'rating__item' : undefined,
    elementType: IconContainer,
    externalSlotProps: slotProps.iconContainer,
    ownerState,
  })

  return (
    <Root {...rootProps} ref={ref}>
      {items.map((key) => {
        const star =
          key < ratingValue ? (
            <IconStarFilled className="rating__icon rating__icon_filled" />
          ) : (
            <IconStar className="rating__icon" />
          )

        if (readOnly) {
          return (
            <IconContainer
              {...iconContainerProps}
              key={key}
              value={typeof IconContainer === 'string' ? undefined : key + 1}
            >
              {star}
            </IconContainer>
          )
        }

        return (
          <label
            className="rating__item"
            key={key}
            onPointerEnter={(): void => {
              setHoverValue(key + 1)
            }}
            onPointerLeave={(): void => {
              setHoverValue(null)
            }}
          >
            <IconContainer
              {...iconContainerProps}
              value={typeof IconContainer === 'string' ? undefined : key + 1}
            >
              {star}
            </IconContainer>
            <input
              className="rating__input"
              id={`${id}_${key}`}
              name={id}
              onChange={onChange}
              type="radio"
              value={key + 1}
            />
          </label>
        )
      })}
    </Root>
  )
}) as PolymorphicComponent<RatingTypeMap>
