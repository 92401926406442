import { type PublicGlobalConfig } from '../../global-config'
import { LOCALE } from '../../i18n'

const urlMap: Record<LOCALE, Record<string, string>> = {
  [LOCALE.DE_AT]: {
    aboutUs: '/about.htm',
    accountability: '/apotheke_haftung.htm',
    agb: '/versandapotheke_agb.htm',
    contactUs: '/versandapotheke_kontakt.htm',
    imprint: '/apotheke_impressum.htm',
    privacySecurity: '/datenschutz.htm',
    privacySecurityCreditCheck: '/datenschutz.htm#bonitaet',
    ratingGuidelines: '/faq.htm#frage-6-3',
    vouchersPage: '/lp/gutscheine/',
  },
  [LOCALE.DE_CH]: {
    aboutUs: '/about.htm',
    accountability: '/allgemeine-nutzungsbedingungen.htm',
    agb: '/versandapotheke_agb.htm',
    contactUs: '/versandapotheke_kontakt.htm',
    imprint: '/apotheke_impressum.htm',
    privacySecurity: '/datenschutz.htm',
    ratingGuidelines: '/faq.htm#frage-5-4',
    vouchersPage: '/lp/gutscheine/',
  },
  [LOCALE.DE_DE]: {
    aboutUs: '/about.htm',
    accountability: '/apotheke_haftung.htm',
    agb: '/versandapotheke_agb.htm',
    contactUs: '/versandapotheke_kontakt.htm',
    imprint: '/apotheke_impressum.htm',
    privacySecurity: '/datenschutz.htm',
    privacySecurityCreditCheck: '/datenschutz.htm#bonitaet',
    ratingGuidelines: '/faq.htm#frage-7-4',
    vouchersPage: '/lp/gutscheine/',
  },
  [LOCALE.FR_BE]: {
    aboutUs: '/a-propos-de-farmaline.htm',
    accountability: '/cgu.htm',
    agb: '/cga.htm',
    contactUs: '/contactez_nous.htm',
    imprint: '/mentions-legales.htm',
    privacySecurity: '/vie-privee.htm',
    privacySecurityCreditCheck: '/fr/protection-des-donnees.htm',
    ratingGuidelines: '/faq.htm',
    vouchersPage: '/lp/code-promo/',
  },
  [LOCALE.FR_CH]: {
    aboutUs: '/qui-sommes-nous.htm',
    accountability: '/cgu.htm',
    agb: '/cga.htm',
    contactUs: '/contact.htm',
    imprint: '/apotheke_impressum.htm',
    privacySecurity: '/protection-des-donnees.htm',
    ratingGuidelines: '/faq.htm#frage-5-4',
    vouchersPage: '/lp/code-promo/',
  },
  [LOCALE.FR_FR]: {
    aboutUs: '/qui-sommes-nous.htm',
    accountability: '/cgu.htm',
    agb: '/cgv.htm',
    contactUs: '/contact.htm',
    imprint: '/apotheke_impressum.htm',
    privacySecurity: '/cgu.htm',
    privacySecurityCreditCheck: '/protection-des-donnees.htm',
    ratingGuidelines: '/faq.htm#frage-6-3',
    vouchersPage: '/lp/code-promo/',
  },
  [LOCALE.IT_IT]: {
    aboutUs: '/su-di-noi.htm',
    accountability: '/cgu.htm',
    agb: '/cgv.htm',
    contactUs: '/contatti.htm',
    imprint: '/note-legali.htm',
    privacySecurity: '/politica-protezione-dati-personali.htm',
    privacySecurityCreditCheck: '/politica-protezione-dati-personali.htm',
    ratingGuidelines: '/faq.htm#frage-6-3',
    vouchersPage: '/lp/buoni/',
  },
  [LOCALE.NL_BE]: {
    aboutUs: '/over-farmaline.htm',
    accountability: '/algemene-gebruiksvoorwaarden.htm',
    agb: '/algemene-voorwaarden.htm',
    contactUs: '/contacteer_ons.htm',
    imprint: '/wettelijke-informatie.htm',
    privacySecurity: '/privacy-beleid.htm',
    privacySecurityCreditCheck: '/nl/gegevensbescherming.htm#bonitaet',
    ratingGuidelines: '/faq.htm',
    vouchersPage: '/lp/promocodes/',
  },
}

export const getUrlFromUrlMap = (
  globalConfig: Pick<PublicGlobalConfig, 'locale'>,
  pageKey: string,
): string => {
  return urlMap[globalConfig.locale][pageKey]
}
