import { type CmsComponentMenuAction } from '@redteclab/api/clients/content-management-system'

import { apmCaptureError } from '../../../apm'
import { type TenantLanguage } from '../../../tenant'

/**
 * true - if item has nested data | "expandable" tag is present
 */
export const headerCmsComponentMenuActionAccessorIsExpandable = (
  item: CmsComponentMenuAction,
): boolean => {
  return item.tags?.includes('expandable') ?? false
}

class CmsHamgurgerMenuRootNodeInvalidError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'CmsHamgurgerMenuRootNodeInvalidError'
  }
}

const TWO = 2

const captureErrorIfItemHrefInvalid = ({
  languages,
  parts,
}: {
  languages: TenantLanguage[]
  parts: string[]
}): void => {
  const invalidForSingleLangTenant = languages.length === 1 && parts.length > 1
  const invalidForMultiLangTenant = languages.length > 1 && parts.length > TWO

  if (
    invalidForSingleLangTenant ||
    invalidForMultiLangTenant ||
    parts.length === 0
  ) {
    apmCaptureError(
      new CmsHamgurgerMenuRootNodeInvalidError(
        `hamburger menu root node href invalid for ${parts.join('/')}`,
      ),
    )
  }
}

/**
 * href of item is used as node name for "fetch by node".
 * node name does not contain /
 * node name does not contain language.
 * this func makes from href a node name
 * it's expected that href for root node of hamburger menu has always one word without slashes
 * @example
 * '/some-category/' => 'some-category'
 * '/foo' => 'foo'
 * /nl/bar/ => 'bar'
 */
export const headerCmsComponentMenuActionAccessorRootNodeHrefToNodeName = ({
  item,
  languages,
}: {
  item: CmsComponentMenuAction
  languages: TenantLanguage[]
}): string => {
  const parts = item.href.split('/').filter(Boolean)

  // because we rely on specific format of href we need to validate and capture error if it's invalid
  captureErrorIfItemHrefInvalid({ languages, parts })

  const lastPart = parts.at(-1)

  if (!lastPart) {
    // to not break the page
    return '/'
  }

  return lastPart
}
