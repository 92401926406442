import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { HeaderLoginButton } from './HeaderLoginButton'
import { HeaderLogoutButton } from './HeaderLogoutButton'
import { HeaderMyAccountLinks } from './HeaderMyAccountLinks'
import { HeaderRegistration } from './HeaderRegistration'

interface HeaderProfileFlyoutProps {
  isLoggedIn: boolean
}

const HeaderProfileFlyout: FC<HeaderProfileFlyoutProps> = ({ isLoggedIn }) => {
  const intl = useIntl()

  return (
    <div className="absolute top-full z-50 -translate-x-1/3 before:absolute before:left-1/2 before:size-0 before:-translate-x-1/2 before:border before:border-x-[21px] before:border-b-[21px] before:border-transparent before:border-b-light-primary-strong">
      <div
        className="mt-3 rounded bg-light-primary-low p-6 shadow-md drop-shadow-lg"
        data-qa-id="menu-flyout"
      >
        <h3 className="sr-only">
          {intl.formatMessage({ id: 'menubar.link.label.myAccount' })}
        </h3>
        {!isLoggedIn && (
          <>
            <HeaderLoginButton />
            <HeaderRegistration />
          </>
        )}

        <HeaderMyAccountLinks />

        {isLoggedIn ? <HeaderLogoutButton /> : null}
      </div>
    </div>
  )
}

export { HeaderProfileFlyout }
