import {
  TabPanel as MuiTabPanel,
  type TabPanelProps as MuiTabPanelProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

export const TabPanel = forwardRef(function TabPanel(
  props: MuiTabPanelProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className } = props

  return (
    <MuiTabPanel
      {...props}
      className={clsx('tab-panel', className)}
      ref={ref}
    />
  )
}) as typeof MuiTabPanel
