import { clsx } from 'clsx'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const HeaderProfileLoginStatusDependentLabel: FC<{
  isLoggedIn: boolean
  showFlyout: boolean
}> = ({ isLoggedIn, showFlyout }) => {
  return (
    <span
      className={clsx(
        'font-medium leading-none text-dark-primary-max',
        showFlyout ? 'text-light-primary-strong' : 'text-dark-primary-max',
      )}
    >
      {isLoggedIn ? (
        <FormattedMessage id="offcanvas.link.myApotheke" />
      ) : (
        <FormattedMessage id="general.label.subscribe" />
      )}
    </span>
  )
}
