import { type PointerEventHandler, useEffect, useState } from 'react'

export const useTooltipToggle = (
  defaultOpen: boolean,
): {
  onTooltipClose: () => void
  onTooltipOpen: () => void
  onTooltipPointerLeave: PointerEventHandler<HTMLDivElement>
  onTooltipToggle: () => void
  tooltipOpen: boolean
} => {
  const [open, setOpen] = useState(false)
  const onTooltipPointerLeave: PointerEventHandler<HTMLDivElement> = (
    event,
  ) => {
    if (event.pointerType === 'mouse') {
      setOpen(false)
    }
  }
  const onTooltipOpen = (): void => {
    setOpen(true)
  }
  const onTooltipToggle = (): void => {
    setOpen((previousValue) => !previousValue)
  }
  const onTooltipClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [defaultOpen])

  return {
    onTooltipClose,
    onTooltipOpen,
    onTooltipPointerLeave,
    onTooltipToggle,
    tooltipOpen: open,
  }
}
