import { clsx } from 'clsx'
import { type FC, type MouseEvent } from 'react'
import { useIntl } from 'react-intl'

import { IconSearch } from '@redteclab/icons'

import { Link } from 'base-ui'

import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON,
  experimentsTrackEvent,
} from '../../../../experiments/eventTracking'
import { useGetExperiment } from '../../../../experiments/hooks'
import { EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST } from '../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../experiments/model/EXPERIMENT_VARIATION'
import { useGlobalConfigContext } from '../../../../global-config'
import { urlResolverGetSearchPage } from '../../../../url-handling'
import { useHeaderSearchContext } from '../../header-search/headerSearchContext'

export const HeaderMobileOpenSearch: FC = () => {
  const intl = useIntl()
  const headerSearchContext = useHeaderSearchContext()
  const globalConfig = useGlobalConfigContext()

  const { variant } = useGetExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  return (
    /**
     *
     * Element is a link for resilience in case JS fails
     * so user will be still navigated to search page.
     * If all's ok, JS will prevent default and handle search
     */
    <Link
      aria-label={intl.formatMessage({
        id: 'general.button.label.search',
      })}
      className={clsx(variant === EXPERIMENT_VARIATION.V1 ? 'p-1.5' : 'px-3')}
      data-qa-id="m-search-button"
      // will navigate only in case of js fails.
      href={urlResolverGetSearchPage(globalConfig, {
        queryParams: {
          // https://wiki.shop-apotheke.com/display/~kPitas/Important+Search+Tracking+Infos
          // eslint-disable-next-line id-length
          i: '1',
          query: 'med',
        },
      })}
      onClick={(event: MouseEvent): void => {
        experimentsTrackEvent(
          globalConfig,
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON,
        )
        event.preventDefault()
        headerSearchContext.setSearchIsActive({
          value: true,
        })
      }}
      type="button"
    >
      <IconSearch
        className={clsx(
          'fill-dark-primary-max',
          variant === EXPERIMENT_VARIATION.V1 ? 'size-7' : 'size-8',
        )}
      />
    </Link>
  )
}
