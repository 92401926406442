/**
 * The prefix is used so our infra could forward calls to Home One directly.
 * Path starting with `/api` conflicts with other rules. The prefixed is handled
 * by NGINX/HAProxy and Varnish.
 *
 * Rewrite back to `/api` is handled by Middleware function `rewriteApiUrl`.
 *
 * Example: `/homeone/api/something` -> `/api/something`
 */
export const PUBLIC_API_PREFIX = '/homeone'
