import { clsx } from 'clsx'
import { type FC, type JSX } from 'react'
import { useIntl } from 'react-intl'

import { IconCaretDown } from '@redteclab/icons'

import { Accordion, AccordionDetails, AccordionSummary } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import {
  assetUrlGetPrefixed,
  urlResolverGetClimateNeutralShipping,
} from '../../../../../../url-handling'

import { type FooterShippingPartnersProps } from './FooterShippingPartnersProps'

import styles from './FooterShippingPartners.module.css'

const iconMap = {
  logoBpost: 'bpost.svg',
  'logoBrt-it': 'bartolini.svg',
  logoColisPrive: 'colis-prive-fr.svg',
  logoColissimo: 'colissimo-fr.svg',
  logoDhl: 'dhl.svg',
  logoDpd: 'dpd.svg',
  logoHermes: 'hermes.svg',
  logoMondial: 'mondialrelay.svg',
  logoPointRelais: 'pointrelais-fr.svg',
  logoPost: 'post.svg',
  logoPosteItaliane: 'posteitaliane.svg',
  logoSameDay: 'same-day.svg',
  logoSwissPost: 'swisspost.svg',
  logoSwissPostFr: 'swisspost-fr.svg',
  logoTransoflex: 'transoflex.svg',
  logoUps: 'ups.svg',
}

const iconRequiresPadding = (iconId: string): boolean =>
  ['logoMondial', 'logoSameDay', 'logoTransoflex', 'logoUps'].includes(iconId)

const iconShouldNotBeFullWidth = (iconId: string): boolean =>
  ['logoUps'].includes(iconId)

const getLogoPath = ({
  assetsPrefix,
  iconId,
}: {
  assetsPrefix: string
  iconId: string
}): string => {
  const namePart = iconMap[iconId as keyof typeof iconMap]

  return namePart
    ? assetUrlGetPrefixed(assetsPrefix, `/logos/logo-${namePart}`)
    : ''
}

const FooterShippingPartners: FC<FooterShippingPartnersProps> = ({
  className,
  shippingPartnersItems,
}) => {
  const intl = useIntl()
  const globalConfig = useGlobalConfigContext()
  const { assetsPrefix } = globalConfig
  const headerText = intl.formatMessage({ id: 'footer.headline.shippings' })

  const renderImage = (alt: string, id: string): JSX.Element => (
    <NextImageWithFallback
      alt={alt}
      className="size-full object-contain"
      fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
      height={10}
      src={getLogoPath({ assetsPrefix, iconId: id })}
      width={10}
    />
  )

  return (
    <div className={clsx(className, styles.accordion)} id="shipping-partners">
      <Accordion className="block rounded-b-none border-b-0 tablet:border-0 tablet:bg-transparent">
        <AccordionSummary
          className="text-s font-medium text-dark-primary-max aria-expanded:bg-transparent tablet:pointer-events-none tablet:bg-light-brand-medium tablet:px-0"
          expandIcon={<IconCaretDown className="tablet:hidden" />}
        >
          {headerText}
        </AccordionSummary>
        <AccordionDetails className="tablet:p-0">
          <ul className="grid auto-rows-fr grid-cols-3 gap-3.5">
            {shippingPartnersItems.map(({ alt, href, logo: { src } }) => (
              <li
                className={clsx(
                  'flex max-h-16 w-full items-center justify-center overflow-hidden rounded-2xl bg-light-primary-low',
                  iconRequiresPadding(src) && 'px-3 py-1',
                  src === 'logoBpost' && 'px-3 py-5',
                )}
                key={src}
              >
                {href ? (
                  <a
                    className={clsx(
                      'h-full',
                      !iconShouldNotBeFullWidth(src) && 'w-full',
                    )}
                    href={urlResolverGetClimateNeutralShipping(globalConfig)}
                  >
                    {renderImage(alt, src)}
                  </a>
                ) : (
                  <span
                    className={clsx(
                      'h-full',
                      !iconShouldNotBeFullWidth(src) && 'w-full',
                    )}
                  >
                    {renderImage(alt, src)}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export { FooterShippingPartners }
