import { DEFAULT_EXPERIMENT } from '../components/experimentComponentCompiler'
import { useExperimentsContext } from '../components/ExperimentsContext'
import { type ExperimentType } from '../model/Experiment.types'

export const useGetExperiment = (
  experimentName: ExperimentType['name'] | undefined,
): ExperimentType => {
  const { experiments } = useExperimentsContext()

  if (!experimentName) {
    return DEFAULT_EXPERIMENT
  }

  const experiment = experiments.find(({ name }) => name === experimentName)

  return experiment ?? DEFAULT_EXPERIMENT
}
