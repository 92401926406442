import { Tab as MuiTab, type TabProps as MuiTabProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

export const Tab = forwardRef(function Tab(
  props: MuiTabProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { className } = props

  return <MuiTab {...props} className={clsx('tab', className)} ref={ref} />
}) as typeof MuiTab
