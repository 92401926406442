'use client'

import {
  type PolymorphicComponent,
  type PolymorphicProps,
  type SlotComponentProps,
  useButton,
  type UseButtonParameters,
  useSlotProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  useEffect,
} from 'react'

export type ButtonOwnProps = {
  /**
   * The color of the component
   * @default primary
   */
  color?: 'primary' | 'secondary'

  /**
   * Icon placed after the children
   */
  endIcon?: ReactElement<ComponentProps<'svg'>, 'svg'> | false | null

  /**
   * The size of the component.
   * @default large
   */
  size?: 'large' | 'medium' | 'small'

  /**
   * The props used for each slot inside the `Button`.
   */
  slotProps?: {
    root?: SlotComponentProps<'button', undefined, undefined>
  }

  /**
   * The components used for each slot inside the `Button`.
   * Either a string to use an HTML element or a component.
   */
  slots?: {
    root?: ElementType
  }

  /**
   * Icon placed before the children
   */
  startIcon?: ReactElement<ComponentProps<'svg'>, 'svg'> | false | null

  /**
   * The variant to use.
   * @default filled
   */
  variant?: 'filled' | 'outlined' | 'text'
}

export interface ButtonTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'button',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps &
    Omit<
      UseButtonParameters,
      'href' | 'rootElementName' | 'rootRef' | 'to' | 'type'
    >
}

export type ButtonProps<
  RootComponentType extends ElementType = ButtonTypeMap['defaultComponent'],
> = PolymorphicProps<
  ButtonTypeMap<ButtonOwnProps, RootComponentType>,
  RootComponentType
>

/**
 * Buttons allow users to take actions, and make choices, with a single tap
 */
export const Button = forwardRef(function Button<
  RootComponentType extends ElementType,
>(props: ButtonProps<RootComponentType>, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    children,
    color = 'primary',
    endIcon,
    size = 'large',
    slotProps,
    slots,
    startIcon,
    variant = 'filled',
    ...other
  } = props

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      variant === 'text' &&
      !endIcon &&
      !startIcon
    ) {
      console.warn(
        'Variant "text" should have an icon, otherwise might be a regular link',
      )
    }
  }, [endIcon, startIcon, variant])

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      variant === 'outlined' &&
      color === 'secondary'
    ) {
      console.warn(
        'Variant "outlined" doesn\'t support "secondary" color combination, fallback to "primary" instead',
      )
    }
  }, [color, variant])

  const { getRootProps } = useButton(props)

  const Root = slots?.root ?? 'button'
  const rootProps = useSlotProps({
    additionalProps: {
      ref,
    },
    className: clsx(
      'button',
      variant === 'filled' && 'button_filled',
      size === 'large' && 'button_large',
      size === 'medium' && 'button_medium',
      variant === 'outlined' && 'button_outlined',
      color === 'primary' && 'button_primary',
      color === 'secondary' && 'button_secondary',
      size === 'small' && 'button_small',
      variant === 'text' && 'button_text',
    ),
    elementType: Root,
    externalForwardedProps: other,
    externalSlotProps: slotProps?.root,
    getSlotProps: getRootProps,
    ownerState: undefined,
  })

  return (
    <Root {...rootProps} ref={ref}>
      {startIcon ? (
        <span className="button__icon button__icon_start">{startIcon}</span>
      ) : null}
      {children}
      {endIcon ? (
        <span className="button__icon button__icon_end">{endIcon}</span>
      ) : null}
    </Root>
  )
}) as PolymorphicComponent<ButtonTypeMap<ButtonOwnProps>>
