'use client'

import {
  type AutocompleteFreeSoloValueMapping,
  type AutocompleteHighlightChangeReason,
  type SlotComponentProps,
  useAutocomplete,
  type UseAutocompleteProps,
  useSlotProps,
} from '@mui/base'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  type JSX,
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useState,
} from 'react'

import { Input } from '../Input'

import { Popper } from './Popper'

const defaultGetOptionLabel = <
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters -- generics needed to fit Autocomplete props
  Value,
  FreeSolo extends boolean | undefined = false,
>(
  option: AutocompleteFreeSoloValueMapping<FreeSolo> | Value,
): string => {
  if (typeof option === 'string') {
    return option
  }

  if (
    typeof option === 'object' &&
    option &&
    'label' in option &&
    typeof option.label === 'string'
  ) {
    return option.label
  }

  throw new Error('Provide `getOptionLabel` prop')
}

type RenderOption<Value> = (
  props: ComponentPropsWithoutRef<'li'>,
  option: Value,
  highlighted?: boolean,
) => ReactNode

export type AutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  UseAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
  'unstable_classNamePrefix' | 'unstable_isActiveElementInListbox'
> & {
  renderOption?: RenderOption<Value>
  slotProps?: {
    input?: SlotComponentProps<
      typeof Input,
      NonNullable<unknown>,
      NonNullable<unknown>
    >
  }
}

type AutocompleteComponent = <
  Value,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
) => JSX.Element

/**
 * The autocomplete is a text input enhanced by a panel of suggested options when users start typing.
 */
export const Autocomplete = forwardRef(function Autocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const {
    getOptionLabel = defaultGetOptionLabel,
    onHighlightChange: onHighlightChangeProp,
    renderOption: renderOptionProp,
    slotProps = {},
    ...other
  } = props

  const [highlightedOption, setHighlightedOption] = useState<Value | null>(null)

  const onHighlightChange = useCallback(
    (
      event: SyntheticEvent,
      option: Value | null,
      reason: AutocompleteHighlightChangeReason,
    ) => {
      setHighlightedOption(option)

      onHighlightChangeProp?.(event, option, reason)
    },
    [onHighlightChangeProp],
  )

  const {
    anchorEl,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getRootProps,
    groupedOptions,
    popupOpen,
    setAnchorEl,
  } = useAutocomplete<Value, Multiple, DisableClearable, FreeSolo>({
    componentName: 'Autocomplete',
    getOptionLabel,
    onHighlightChange,
    ...other,
  })

  const defaultRenderOption: RenderOption<Value> = useCallback(
    (optionProps, option, highlighted) => (
      <li
        {...optionProps}
        className={clsx(
          'autocomplete__option',
          highlighted && 'autocomplete__option_highlighted',
        )}
      >
        {getOptionLabel(option)}
      </li>
    ),
    [getOptionLabel],
  )

  const renderOption = renderOptionProp ?? defaultRenderOption

  const ref = useForkRef(forwardedRef, setAnchorEl)

  const inputProps = useSlotProps({
    additionalProps: {
      ref,
      slotProps: {
        input: {
          ...getInputProps(),
          className: 'autocomplete__input-inner',
        },
      },
    },
    className: 'autocomplete__input',
    elementType: Input,
    externalForwardedProps: getRootProps(),
    externalSlotProps: slotProps.input,
    ownerState: {},
  })

  return (
    <>
      <Input {...inputProps} />
      {anchorEl && groupedOptions.length > 0 ? (
        <Popper
          anchorEl={anchorEl}
          className="autocomplete__popper"
          open={popupOpen}
        >
          <ul {...getListboxProps()} className="autocomplete__listbox">
            {groupedOptions.map((option, index): ReactNode => {
              if (option && typeof option === 'object' && 'group' in option) {
                throw new Error('Grouping is not supported yet')
              }

              const optionProps = getOptionProps({ index, option })

              return renderOption(
                optionProps,
                option,
                option === highlightedOption,
              )
            })}
          </ul>
        </Popper>
      ) : null}
    </>
  )
}) as AutocompleteComponent
