// Inspired by https://github.com/mui/material-ui/blob/fc23da8a6bc0872d7ebd71b7f78c7502aeb95aa3/packages/mui-material/src/CircularProgress/CircularProgress.js

import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, type FC } from 'react'

type CircularProgressProps = ComponentPropsWithoutRef<'span'> & {
  screenReaderMessage?: string
}

/**
 * Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process
 */
export const CircularProgress: FC<CircularProgressProps> = (props) => {
  const { className, screenReaderMessage, ...rest } = props

  return (
    <span {...rest} className={clsx('circular-progress', className)}>
      <svg viewBox="22 22 44 44">
        <circle cx="44" cy="44" r="20.2" />
      </svg>
      {screenReaderMessage ? (
        <span className="circular-progress__screen-reader">
          {screenReaderMessage}
        </span>
      ) : null}
    </span>
  )
}
