import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

const FooterShopsInlineLabel: FC = () => {
  return (
    <div className="mr-2 text-s">
      <FormattedMessage id="footer.label.country" />
      {': '}
    </div>
  )
}

export { FooterShopsInlineLabel }
