'use client'

import { createContext, type FC, type ReactNode, useContext } from 'react'

export type AlternativeUrlsType = Record<
  string,
  { flag: string; path: string; translatedLanguage: string }
>

const AlternativeUrlsContext = createContext<AlternativeUrlsType>({})

export const AlternativeUrlsContextProvider: FC<{
  alternativeUrls: AlternativeUrlsType
  children: ReactNode
}> = ({ alternativeUrls, children }) => {
  return (
    <AlternativeUrlsContext.Provider value={alternativeUrls}>
      {children}
    </AlternativeUrlsContext.Provider>
  )
}

export const useAlternativeUrlsContext = (): AlternativeUrlsType => {
  const alternativeUrls = useContext(AlternativeUrlsContext)

  return alternativeUrls
}
