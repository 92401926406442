import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconArrowLeft } from '@redteclab/icons'

import { useHeaderSearchContext } from '../../headerSearchContext'

export const HeaderSearchMobileInputCloseSearchButton: FC = () => {
  const headerSearchContext = useHeaderSearchContext()

  return (
    <button
      onClick={(): void => {
        headerSearchContext.setSearchIsActive({
          value: false,
        })
      }}
      // important so this button does not submit
      type="button"
    >
      <IconArrowLeft className="mx-4 size-8 text-dark-brand" />
      <span className="sr-only">
        <FormattedMessage id="general.link.label.back" />
      </span>
    </button>
  )
}
