'use client'

import { createContext, type FC, type ReactNode, useContext } from 'react'

import { type HeaderDataType } from './model/headerTypes'

type HeaderConfigContextValue = HeaderDataType

const HeaderDataContext = createContext<HeaderConfigContextValue | null>(null)

export const HeaderDataContextProvider: FC<{
  children: ReactNode
  headerData: HeaderDataType
}> = ({ children, headerData }) => {
  return (
    <HeaderDataContext.Provider value={headerData}>
      {children}
    </HeaderDataContext.Provider>
  )
}

export const useHeaderDataContext = (): HeaderConfigContextValue => {
  const context = useContext(HeaderDataContext)

  if (context === null) {
    throw new Error(
      'useHeaderConfigContext must be used within a HeaderConfigContextProvider',
    )
  }

  return context
}
