import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconPrescription } from '@redteclab/icons'

import { useGlobalConfigContext } from '../../../../global-config'
import { TENANT } from '../../../../tenant'
import {
  urlResolverGetRedeemEprescription,
  urlResolverGetRedeemPaperPrescription,
} from '../../../../url-handling'
import { HeaderCart } from '../HeaderMiddleSection/HeaderCart'

import { HeaderMobileOpenSearch } from './HeaderMobileOpenSearch'

const REZEPT_TENANTS = new Set([TENANT.CH, TENANT.COM])

export const HeaderMobileIconList: FC = () => {
  const publicConfig = useGlobalConfigContext()
  const { tenant } = publicConfig

  return (
    <ul className="flex items-center gap-2">
      <li className="flex">
        <HeaderMobileOpenSearch />
      </li>
      {REZEPT_TENANTS.has(tenant) ? (
        <li>
          <a
            className="flex flex-col items-center"
            href={
              tenant === TENANT.COM
                ? urlResolverGetRedeemEprescription(publicConfig)
                : urlResolverGetRedeemPaperPrescription(publicConfig)
            }
          >
            <IconPrescription className="text-2xl" />
            <span className="whitespace-nowrap text-xs leading-none">
              <FormattedMessage id="header.icon.receiptText" />
            </span>
          </a>
        </li>
      ) : null}
      <li className="flex">
        <HeaderCart isMobile />
      </li>
    </ul>
  )
}
