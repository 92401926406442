import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

type RadioOwnProps = {
  /**
   * The size of the component
   * @default medium
   */
  size?: 'medium' | 'small'
}

type RadioProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'type'> &
  RadioOwnProps

/**
 * Radio buttons enable the user to select one option from a set.
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  function Radio(props, ref) {
    const { className, size = 'medium', ...inputProps } = props

    return (
      <input
        {...inputProps}
        className={clsx(
          'radio',
          size === 'medium' && 'radio_medium',
          size === 'small' && 'radio_small',
          className,
        )}
        ref={ref}
        type="radio"
      />
    )
  },
)
