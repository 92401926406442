import { TENANT } from './tenantTypes'

export const tenantLanguagesConfig = {
  [TENANT.AT]: {
    defaultLanguage: 'de',
    languages: ['de'],
  },
  [TENANT.BE]: {
    defaultLanguage: 'nl',
    languages: ['nl', 'fr'],
  },
  [TENANT.CH]: {
    defaultLanguage: 'de',
    languages: ['de', 'fr'],
  },
  [TENANT.COM]: {
    defaultLanguage: 'de',
    languages: ['de'],
  },
  [TENANT.FR]: {
    defaultLanguage: 'fr',
    languages: ['fr'],
  },
  [TENANT.IT]: {
    defaultLanguage: 'it',
    languages: ['it'],
  },
} as const

export type TenantLanguagesConfig = typeof tenantLanguagesConfig

export type TenantLanguage = TenantLanguagesConfig[TENANT]['languages'][number]

/**
 * It's not possible to infer exact values as tenant is not known at current moment.
 * If you would like to get exact values for specific tenant access {@link tenantLanguagesConfig} directly.
 */
export const getTenantLanguages = (tenant: TENANT): TenantLanguage[] =>
  tenantLanguagesConfig[tenant].languages as unknown as TenantLanguage[]

export const SupportedLanguages = {
  DE: 'de',
  FR: 'fr',
  IT: 'it',
  NL: 'nl',
} as const

export type SupportedLanguages =
  (typeof SupportedLanguages)[keyof typeof SupportedLanguages]
