import { clsx } from 'clsx'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconMail } from '@redteclab/icons'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../../global-config'
import { TENANT } from '../../../../../../tenant'
import { urlResolverGetNewsletter } from '../../../../../../url-handling'

import { FooterNewsletterBenefits } from './FooterNewsletterBenefits'
import { type FooterNewsletterProps } from './FooterNewsletterProps'

const FooterNewsletter: FC<FooterNewsletterProps> = ({
  className = '',
  newsletterDiscount,
}) => {
  const globalConfig = useGlobalConfigContext()

  return (
    <article className={`relative hidden tablet:block ${className}`}>
      <h3 className="mb-3.5 pt-2.5 text-s font-medium">
        <FormattedMessage id="footer.headline.newsletter" />
      </h3>
      <div className="relative rounded-2xl bg-light-primary-low p-3.5">
        <div className="mb-3.5 flex">
          <div
            className={clsx(
              'absolute -right-6 -top-6 mr-3.5 flex size-[76px] shrink-0 items-center justify-center rounded-full bg-light-tertiary text-center font-mono font-medium text-dark-primary-max',
              TENANT.CH === globalConfig.tenant ? 'text-s' : 'text-l',
            )}
          >
            {newsletterDiscount}
          </div>
          <FooterNewsletterBenefits />
        </div>
        <div className="flex justify-end">
          <Button<'a'>
            className="w-full py-4 text-s font-bold"
            href={urlResolverGetNewsletter(globalConfig)}
            slots={{ root: 'a' }}
            startIcon={<IconMail />}
          >
            <FormattedMessage id="newsletterBox.cta.label.subscribe" />
          </Button>
        </div>
      </div>
    </article>
  )
}

export { FooterNewsletter }
