import { type FormControlProps, FormControl as MuiFormControl } from '@mui/base'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

/**
 * The Form Control component is a utility that lets you associate a form input with auxiliary components, such as labels, error indicators, or helper text.
 *
 * > FormControl works perfect with `input` based component, example `Input`. For non-input based component (check `Select` [implementation](https://github.com/RedTecLab/home-one/blob/main/packages/base-ui/components/Select/Select.tsx)) developers should provide custom integration with `FormControlContext`.
 */
export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  function FormControl(props, ref) {
    return (
      <MuiFormControl
        {...props}
        className={clsx('form-control', props.className)}
        ref={ref}
      />
    )
  },
)
