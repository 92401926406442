import {
  type FormControlState as MuiFormControlState,
  useFormControlContext,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

type FormLabelOwnProps = Partial<Pick<MuiFormControlState, 'required'>>

export type FormLabelProps = ComponentPropsWithoutRef<'label'> &
  FormLabelOwnProps

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  function InputLabel(props, ref) {
    const {
      children,
      className,
      htmlFor,
      required: requiredProp,
      ...rest
    } = props

    const formControlContext = useFormControlContext()

    const { filled, focused, required } = {
      required: requiredProp,
      ...formControlContext,
    }

    return (
      <label
        className={clsx(
          'form-label',
          formControlContext && [
            'form-label_form-control',
            filled && 'form-label_form-control_filled',
            focused && 'form-label_form-control_focused',
          ],
          className,
        )}
        htmlFor={htmlFor}
        ref={ref}
        {...rest}
      >
        {children}
        {Boolean(required) && '*'}
      </label>
    )
  },
)
