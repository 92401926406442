'use client'

import Image from 'next/image'
import { type FC, useState } from 'react'

import { useExperimentalAddStaticFileHost } from '../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'

import { type NextImageWithFallbackProps } from './NextImageWithFallbackProps'

const NextImageWithFallback: FC<NextImageWithFallbackProps> = (props) => {
  const { fallbackSrc, src, ...imageProps } = props
  const [imageSrc, setImageSrc] = useState(src)
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  // default to this so external images do not go through _next.
  const unoptimized = imageProps.unoptimized ?? true

  return (
    <Image
      onError={(): void => {
        setImageSrc(addStaticCdnHostToUrl(fallbackSrc))
      }}
      src={imageSrc}
      {...imageProps}
      unoptimized={unoptimized}
    />
  )
}

export { NextImageWithFallback }
