import { type FC } from 'react'

import { type CmsIcons } from '@redteclab/api/clients/content-management-system'

import { HEADER_ICON_MAP } from './headerIconsMap'

interface HeaderInlineIconProps {
  className?: string
  icon: CmsIcons | undefined
}

const HeaderInlineIcon: FC<HeaderInlineIconProps> = ({ className, icon }) => {
  const Icon = icon
    ? HEADER_ICON_MAP[icon as keyof typeof HEADER_ICON_MAP]
    : null

  return Icon ? <Icon className={className} /> : null
}

export { HeaderInlineIcon }
