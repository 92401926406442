import { type FC, useState } from 'react'

import { type CmsComponentMenu } from '@redteclab/api/clients/content-management-system'

import { useHeaderBackdropContext } from '../../Header/headerBackdropContext'

import { HeaderCategoriesList } from './HeaderCategoriesList'
import { HeaderCategoriesMenuIconButton } from './HeaderCategoriesMenuIconButton'

interface HeaderCategoriesMenuProps {
  categoriesData: CmsComponentMenu
}

const useMenuVisibilityControls = (): {
  handleHideMenu: () => void
  handleShowMenu: () => void
  isShown: boolean
} => {
  const [isShown, setIsShown] = useState(false)
  const { hideBackdrop, showBackdrop } = useHeaderBackdropContext()

  return {
    handleHideMenu: (): void => {
      setIsShown(false)
      hideBackdrop()
    },
    handleShowMenu: (): void => {
      setIsShown(true)
      showBackdrop()
    },
    isShown,
  }
}

const HeaderCategoriesMenu: FC<HeaderCategoriesMenuProps> = ({
  categoriesData,
}) => {
  const menuVisibility = useMenuVisibilityControls()

  return (
    <div
      onBlur={menuVisibility.handleHideMenu}
      onFocus={menuVisibility.handleShowMenu}
      onMouseEnter={menuVisibility.handleShowMenu}
      onMouseLeave={menuVisibility.handleHideMenu}
      role="menu"
      tabIndex={0}
    >
      <HeaderCategoriesMenuIconButton
        icon={categoriesData.icon}
        menuIsShown={menuVisibility.isShown}
        title={categoriesData.title}
      />
      <HeaderCategoriesList
        categories={categoriesData.items ?? []}
        isVisible={menuVisibility.isShown}
      />
    </div>
  )
}

export { HeaderCategoriesMenu }
