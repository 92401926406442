import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconCheck } from '@redteclab/icons'

const FooterNewsletterBenefits: FC = () => {
  const itemClassNames = 'flex items-center mb-2 text-no'
  const iconClassNames = 'mr-1 h-5 fill-dark-now text-l'

  return (
    <ul className="-ml-1.5">
      <li className={itemClassNames}>
        <IconCheck className={iconClassNames} />
        <FormattedMessage id="newsletterBox.benefit.sale" />
      </li>
      <li className={itemClassNames}>
        <IconCheck className={iconClassNames} />
        <FormattedMessage id="newsletterBox.benefit.voucher" />
      </li>
      <li className={itemClassNames}>
        <IconCheck className={iconClassNames} />
        <FormattedMessage id="newsletterBox.benefit.freebies" />
      </li>
    </ul>
  )
}

export { FooterNewsletterBenefits }
