import { clsx } from 'clsx'
import { type FC } from 'react'

import { type FooterBannerSectionProps } from './FooterBannerSectionProps'
import { FooterInfoSection } from './FooterInfoSection'
import { FooterNewsletter } from './FooterNewsletter'
import { FooterPaymentPartners } from './FooterPaymentPartners'
import { FooterShippingPartners } from './FooterShippingPartners'

export const FooterBannerSection: FC<FooterBannerSectionProps> = ({
  className = '',
  infoSectionItems,
  newsletterDiscount,
  paymentPartnersItems,
  shippingPartnersItems,
}) => (
  <div className={clsx('grid-cols-12 gap-3.5 tablet:grid', className)}>
    <FooterShippingPartners
      className="col-start-1 col-end-6 row-start-1 row-end-2"
      shippingPartnersItems={shippingPartnersItems}
    />
    <FooterPaymentPartners
      className="col-start-8 col-end-13 row-start-1 row-end-2"
      paymentPartnersItems={paymentPartnersItems}
    />
    <FooterInfoSection
      className="col-start-1 col-end-8 row-start-2 row-end-4"
      infoSectionLinks={infoSectionItems}
    />
    <FooterNewsletter
      className="col-start-8 col-end-13 row-start-2 row-end-3"
      newsletterDiscount={newsletterDiscount}
    />
  </div>
)
