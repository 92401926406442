'use client'

import { createContext, type SyntheticEvent } from 'react'

type AccordionContextValue = {
  expanded: boolean
  toggle: (event: SyntheticEvent) => void
}

export const AccordionContext = createContext<AccordionContextValue>({
  expanded: false,
  toggle: () => {
    throw new Error('Please use <AccordionContext.Provider />')
  },
})
