import { clsx } from 'clsx'
import { type FC } from 'react'

import {
  IconContactLenses,
  IconDiscount,
  IconNowAvailable,
  IconPills,
} from '@redteclab/icons'

import {
  IconOnlineDoctor,
  IconPrescription,
  IconRedpoints,
} from '../../../icons'

type HeaderCategoryIconRendererProps = {
  className?: string
  iconType?: string
}

export const HeaderCategoryIconRenderer: FC<
  HeaderCategoryIconRendererProps
> = ({ className, iconType }) => {
  switch (iconType) {
    case 'circlePercent': {
      return <IconDiscount className={className} />
    }
    case 'contactLenses': {
      return (
        <IconContactLenses className={clsx(className, 'fill-dark-redpoints')} />
      )
    }
    case 'new': {
      return (
        <IconNowAvailable className={clsx(className, 'fill-dark-redpoints')} />
      )
    }
    case 'ods': {
      return (
        <IconOnlineDoctor className={clsx(className, 'fill-dark-redpoints')} />
      )
    }
    case 'pills': {
      return <IconPills className={clsx(className, 'fill-dark-redpoints')} />
    }
    case 'redeemRecipe': {
      return (
        <IconPrescription
          className={clsx(className, 'fill-dark-redpoints p-0.5')}
        />
      )
    }
    case 'redpoints': {
      return (
        <IconRedpoints className={clsx(className, 'fill-dark-redpoints')} />
      )
    }
    default: {
      return null
    }
  }
}
