import { ClickAwayListener } from '@mui/base'
import { type FC } from 'react'

import { useHeaderSearchContext } from '../../headerSearchContext'

import { HeaderSearchDesktopInput } from './HeaderSearchDesktopInput'

const HeaderSearchDesktop: FC<{ className?: string }> = ({ className }) => {
  const { portalResultsTo, searchIsActive, setSearchIsActive } =
    useHeaderSearchContext()

  const backdropActive = Boolean(searchIsActive && portalResultsTo)

  const onClickAway = (): void => {
    if (backdropActive) {
      setSearchIsActive({
        value: false,
      })
    }
  }

  return (
    <div className={className}>
      {backdropActive ? (
        <div className="fixed left-0 top-0 z-10 size-full bg-backdrop" />
      ) : null}
      <ClickAwayListener
        /*
         * In cases when user clicks on another modal backdrop this component invokes onClick event
         * after search input receives focus by `FocusTrap` of the modal.
         * To fix this exception use `mouseEvent="onMouseDown"` & `touchEvent="onTouchStart"` to trigger
         * `onClickAway` handler before input gets focus.
         * Issue example is described in https://jira.shop-apotheke.com/browse/CRO-3838
         */
        mouseEvent="onMouseDown"
        onClickAway={onClickAway}
        touchEvent="onTouchStart"
      >
        <div className="absolute top-0 z-20 size-full">
          <HeaderSearchDesktopInput />
        </div>
      </ClickAwayListener>
    </div>
  )
}

export { HeaderSearchDesktop }
