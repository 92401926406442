import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { useActivateContentsquareExperiment } from 'contentsquare'

import { AdServerTopHeaderBanner } from '../../../../ad-server'
import { useAppRouterMigrationContext } from '../../../../app-router-migration'
import { AppsFlyerBannerMounter } from '../../../../apps-flyer-banner'
import { Experiment } from '../../../../experiments/components/Experiment'
import { ExperimentVariant } from '../../../../experiments/components/ExperimentVariant'
import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
  experimentsTrackEvent,
} from '../../../../experiments/eventTracking'
import { HeaderMobileIconListExperiment } from '../../../../experiments/header-mobile-icon-list-experiment/HeaderMobileIconListExperiment'
import { HeaderMobileIconListExperimentLogo } from '../../../../experiments/header-mobile-icon-list-experiment/HeaderMobileIconListExperimentLogo'
import { useGetExperiment } from '../../../../experiments/hooks'
import { EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST } from '../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../experiments/model/EXPERIMENT_VARIATION'
import { useGlobalConfigContext } from '../../../../global-config'
import { urlResolverGetHomepage } from '../../../../url-handling'
import { useHeaderDataContext } from '../../headerConfigContext'
import { headerCmsBlockMenuAccessorGetHamburgerMenu } from '../../model/headerCmsBlockMenuAccessor'
import { OffCanvasHeaderMenuContainer } from '../../off-canvas-header-menu/components/OffCanvasHeaderMenuContainer'
import { HeaderNavigationProgressBar } from '../HeaderNavigationProgressBar/HeaderNavigationProgressBar'

import { HeaderMobileIconList } from './HeaderMobileIconList'

interface HeaderMobileProps {
  isDesktop: boolean
}

export const HeaderMobile: FC<HeaderMobileProps> = ({ isDesktop }) => {
  const headerData = useHeaderDataContext()
  const publicConfig = useGlobalConfigContext()

  const logo = headerData.shopLogos.mobile
  const { isAppRouter } = useAppRouterMigrationContext()

  useActivateContentsquareExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  const { variant } = useGetExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  return (
    <header className="sticky top-0 z-20 tablet:hidden">
      {!isDesktop && <AdServerTopHeaderBanner />}
      <div
        className={clsx(
          'flex items-center justify-between border-b border-b-dark-primary-low bg-light-primary-low pt-0.5',
          variant === EXPERIMENT_VARIATION.V1 ? 'px-4' : 'px-2',
        )}
      >
        <ul>
          <li>
            <OffCanvasHeaderMenuContainer
              hamburgerMenuData={headerCmsBlockMenuAccessorGetHamburgerMenu(
                headerData.cmsMenu,
              )}
            />
          </li>
        </ul>
        <div>
          <a
            href={urlResolverGetHomepage(publicConfig)}
            onClick={() => {
              experimentsTrackEvent(
                publicConfig,
                EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
              )
            }}
          >
            {variant === EXPERIMENT_VARIATION.V1 ? (
              <HeaderMobileIconListExperimentLogo />
            ) : (
              <Image alt="logo" src={logo} />
            )}
          </a>
        </div>
        <Experiment names={[EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST]}>
          <ExperimentVariant name={EXPERIMENT_VARIATION.DEFAULT}>
            {() => <HeaderMobileIconList />}
          </ExperimentVariant>
          <ExperimentVariant name={EXPERIMENT_VARIATION.V1}>
            {() => <HeaderMobileIconListExperiment />}
          </ExperimentVariant>
        </Experiment>
      </div>
      <div className="absolute bottom-0 w-full">
        {!isAppRouter && <HeaderNavigationProgressBar />}
      </div>
      <AppsFlyerBannerMounter />
    </header>
  )
}
