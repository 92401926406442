import { clsx } from 'clsx'
import { type FC, useMemo } from 'react'

import { type CmsComponentMenuAction } from '@redteclab/api/clients/content-management-system'

import { HeaderCategoryIconRenderer } from '../../HeaderCategoryIconRenderer'

interface HeaderCategoryLinkItemProps {
  isBold: boolean
  item: CmsComponentMenuAction
}

const getDataQaId = (href: string): string | undefined => {
  switch (href) {
    case '/now/': {
      return 'now-products-link-under-categories'
    }
    case '/ratgeber/': {
      return 'ratgeber-link'
    }

    default: {
      return undefined
    }
  }
}

export const HeaderCategoryLinkItem: FC<HeaderCategoryLinkItemProps> = ({
  isBold,
  item,
}) => {
  const categoryLinkClassName = useMemo(
    () =>
      clsx(
        'flex items-center hover:text-dark-brand hover:underline',
        isBold && 'font-medium',
      ),
    [isBold],
  )

  return (
    <li className="mb-2.5 mt-3" key={item.title}>
      <a
        className={categoryLinkClassName}
        data-qa-id={getDataQaId(item.href)}
        href={item.href}
        role="menuitem"
      >
        <HeaderCategoryIconRenderer
          className="mr-2.5 size-8 shrink-0"
          iconType={item.icon}
        />
        {item.title}
      </a>
    </li>
  )
}
