'use client'

import { useEffect } from 'react'

import { activateContentsquareExperiment } from './activateContentsquareExperiment'

export const useActivateContentsquareExperiment = (
  experimentName: string,
): void => {
  useEffect(() => {
    // https://docs.contentsquare.com/en/web/command-reference/#trackpageevent
    activateContentsquareExperiment(experimentName)
  }, [experimentName])
}
