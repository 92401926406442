import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconClose } from '@redteclab/icons'

export const OffCanvasHeaderMenuRootSlideCloseButton: FC<{
  onClick: () => void
}> = ({ onClick }) => (
  <button
    className="min-h-14 fill-dark-primary-max px-3.5"
    onClick={onClick}
    type="button"
  >
    <IconClose className="size-8" />
    <span className="sr-only">
      <FormattedMessage id="general.link.label.close" />
    </span>
  </button>
)
