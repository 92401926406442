import { clsx } from 'clsx'
import { type FC } from 'react'

import { useGlobalConfigContext } from '../../../../global-config'
import { StaticImage } from '../../../../image'
import { urlResolverGetHomepage } from '../../../../url-handling'
import { type ShopLogos } from '../../config/PerTenantHeaderConfiguration.type'

export const HeaderMiddleSectionBrandLogo: FC<{
  className?: string
  isSticky: boolean
  shopLogos: ShopLogos
}> = ({ className, isSticky, shopLogos }) => {
  const publicConfig = useGlobalConfigContext()

  return (
    <div className={className}>
      <div
        className={clsx(
          'relative flex w-[8.75rem] items-center duration-150 ease-in-out',
          !isSticky && 'desktop:mx-auto desktop:scale-150',
        )}
      >
        <a data-qa-id="brand-log" href={urlResolverGetHomepage(publicConfig)}>
          <StaticImage alt="logo" src={shopLogos.desktop} />
        </a>
      </div>
    </div>
  )
}
