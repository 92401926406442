import { clsx } from 'clsx'
import { forwardRef, Fragment } from 'react'

import { AdServerTopHeaderBanner } from '../../../../ad-server'
import { useDeviceDetectorContext } from '../../../../device-detector'
import { useHeaderDataContext } from '../../headerConfigContext'
import { LanguageSwitcherSelect } from '../LanguageSwitcher/LanguageSwitcherSelect'

import { Tag } from './Tag'

export const HeaderTopSection = forwardRef<HTMLDivElement>(
  function InnerHeaderTopSection(props, ref) {
    const { isDesktop } = useDeviceDetectorContext()
    const { menuLinks } = useHeaderDataContext()

    return (
      <div
        className="relative z-20 hidden bg-light-primary-low hover:z-40 tablet:block"
        ref={ref}
      >
        {Boolean(isDesktop) && <AdServerTopHeaderBanner />}
        <div className="mx-auto flex max-w-5xl flex-wrap items-center justify-between px-3.5 pt-2 text-s">
          <ul
            className={clsx('inline-flex pr-4', 'desktop:ml-5 desktop:pl-48')}
          >
            {menuLinks.center.map((link, index) => (
              <li className="mr-4" key={link.id}>
                <Tag hasBorder={index === 0} href={link.href}>
                  {link.text}
                </Tag>
              </li>
            ))}
          </ul>
          <ul className="inline-flex items-center">
            {menuLinks.right.map((link) => (
              <Fragment key={link.id}>
                <li className="ml-2">
                  <a
                    aria-label={link.text}
                    className="text-xs text-dark-primary-max hover:text-dark-hover-brand"
                    href={link.href}
                  >
                    {link.text}
                  </a>
                </li>
              </Fragment>
            ))}
          </ul>
          <LanguageSwitcherSelect />
        </div>
      </div>
    )
  },
)
