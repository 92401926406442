import { IconDiscount, IconMenu } from '@redteclab/icons'

import {
  IconNew,
  IconOnlineDoctorService,
  IconPrescription,
  IconRedpoints,
} from '../../../../../icons'

export const HEADER_ICON_MAP = {
  circlePercent: IconDiscount,
  hamburger: IconMenu,
  new: IconNew,
  ods: IconOnlineDoctorService,
  redeemRecipe: IconPrescription,
  redpoints: IconRedpoints,
}
