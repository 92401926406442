import { clsx } from 'clsx'
import { type FC } from 'react'

import { useDeviceDetectorContext } from '../../../../device-detector'
import { useActivateNeuralSearchExperiment } from '../../../../experiments/neural-search-experiment/useActivateNeuralSearchExperiment'
import { useDisableMobileBodyScroll } from '../../../../generic-hooks'
import { usePushGtmUserDataAvailableEvent } from '../../../../gtm'
import { HeaderSearchActiveSearchBox } from '../../header-search/components/HeaderSearchActiveSearchBox/HeaderSearchActiveSearchBox'
import { useHeaderSearchContext } from '../../header-search/headerSearchContext'
import { HeaderDataContextProvider } from '../../headerConfigContext'
import { type HeaderDataType } from '../../model/headerTypes'

import { HeaderDesktop } from './HeaderDesktop'
import { HeaderExtension } from './HeaderExtension'
import { HeaderMobile } from './HeaderMobile'

interface HeaderProps {
  headerData: HeaderDataType
}

const Header: FC<HeaderProps> = ({ headerData }) => {
  const headerSearchContext = useHeaderSearchContext()
  const deviceDetector = useDeviceDetectorContext()
  usePushGtmUserDataAvailableEvent()
  useActivateNeuralSearchExperiment()

  /**
   * We need to take portalResultsTo into consideration to cover an edge case since we use searchIsActive for both mobile and desktop
   * Edge case: open search on mobile, resize to desktop and open search there, resize back to mobile -> the body scroll is not working
   * because the searchIsActive is still true from the desktop version but so the portalResultsTo
   */
  useDisableMobileBodyScroll(
    headerSearchContext.searchIsActive && !headerSearchContext.portalResultsTo,
  )

  return (
    <HeaderDataContextProvider headerData={headerData}>
      <HeaderDesktop />
      <HeaderMobile isDesktop={deviceDetector.isDesktop} />
      {headerSearchContext.searchIsActive ? (
        <HeaderSearchActiveSearchBox
          /*
           * on desktop, search box is always portalled to HeaderSearchDesktop,
           * on mobile it is rendered without portal here.
           */
          className={clsx(
            !headerSearchContext.portalResultsTo && 'tablet:hidden',
          )}
        />
      ) : null}
      <HeaderExtension headerExtension={headerData.headerExtension} />
    </HeaderDataContextProvider>
  )
}

export { Header }
