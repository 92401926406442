import { clsx } from 'clsx'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { type FooterCopyrightAndShopsProps } from './FooterCopyrightAndShopsProps'
import { FooterShops } from './FooterShops'

const FooterCopyrightAndShops: FC<FooterCopyrightAndShopsProps> = ({
  className = '',
  tenantItemsLinks,
}) => {
  return (
    <div
      className={clsx(
        'mb-3.5 pt-3.5 tablet:flex tablet:justify-between',
        className,
      )}
    >
      <div className="col-span-5 text-center text-s tablet:text-left">
        {`© 2001 - ${new Date().getFullYear()} `}
        <FormattedMessage id="footer.label.legal" />
      </div>

      <div className="col-span-5 col-start-8 flex justify-center align-middle tablet:justify-end">
        <FooterShops tenantLinksItems={tenantItemsLinks} />
      </div>
    </div>
  )
}

export { FooterCopyrightAndShops }
