import { useIntl } from 'react-intl'

import { useGlobalConfigContext } from '../../../global-config'
import { TENANT } from '../../../tenant'

export const useHeaderSearchPlaceholderText = (): string => {
  const { tenant } = useGlobalConfigContext()
  const intl = useIntl()

  if ([TENANT.AT, TENANT.COM, TENANT.FR, TENANT.IT].includes(tenant)) {
    return intl.formatMessage({
      id: 'general.label.placeholder.searchYourProduct',
    })
  }

  return intl.formatMessage({
    id: 'general.label.placeholder.search',
  })
}
