import { type StaticImageData } from 'next/image'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { StaticImage } from '../../image'

type ImageWithLinkProps = {
  className?: string
  link: string
  onClick: () => void
  src: StaticImageData
}

export const ImageWithLink: FC<ImageWithLinkProps> = ({
  className,
  link,
  onClick,
  src,
}) => {
  const intl = useIntl()

  return (
    <a href={link} onClick={onClick}>
      <StaticImage
        alt={intl.formatMessage({ id: 'advertisingFlyoutBanner.altText' })}
        className={className}
        src={src}
      />
    </a>
  )
}
