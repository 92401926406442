import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ElementType,
  forwardRef,
} from 'react'

export type BrandIconOwnProps = {
  /**
   * The color of the component
   * @default brand
   */
  color?: 'brand' | 'secondary'

  icon: ElementType<ComponentProps<'svg'>, 'svg'>

  /**
   * The size applied to the icon. Defaults to inherit font size.
   */
  size?: 'l' | 'm' | 's'
}

type BrandIconProps = BrandIconOwnProps &
  Omit<ComponentPropsWithoutRef<'svg'>, keyof BrandIconOwnProps>

export const BrandIcon = forwardRef<SVGSVGElement, BrandIconProps>(
  function BrandIcon(props, ref) {
    const { className, color = 'brand', icon: Icon, size, ...rest } = props

    return (
      <Icon
        className={clsx(
          'brand-icon',
          color === 'brand' && 'brand-icon_color_brand',
          color === 'secondary' && 'brand-icon_color_secondary',
          size === 's' && 'brand-icon_size_s',
          size === 'm' && 'brand-icon_size_m',
          size === 'l' && 'brand-icon_size_l',
          className,
        )}
        ref={ref}
        {...rest}
      />
    )
  },
)
