import { type InputRootSlotProps as InputUnstyledRootSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

export const Root = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & InputUnstyledRootSlotProps
>(function Root(props, ref) {
  const { className, ownerState, ...rest } = props

  return (
    <div
      {...rest}
      className={clsx(
        'input-root',
        ownerState.disabled && 'input-root_disabled',
        ownerState.error && 'input-root_error',
        ownerState.focused && 'input-root_focused',
        Boolean(ownerState.formControlContext) && 'input-root_form-control',
        ownerState.multiline && 'input-root_multiline',
        className,
      )}
      ref={ref}
    />
  )
})
