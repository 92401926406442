import { type FC } from 'react'

import { AppsFlyerContextProvider } from '../../../../apps-flyer-banner'
import { type StandardLayoutProps } from '../../../StandardLayoutProps'
import { HeaderSearchContextProvider } from '../../header-search/headerSearchContext'

import { Header } from './Header'
import { HeaderBackdropContextProvider } from './headerBackdropContext'

export const HeaderWithProviders: FC<{
  standardLayoutData: StandardLayoutProps
}> = ({ standardLayoutData }) => {
  return (
    <HeaderSearchContextProvider>
      <HeaderBackdropContextProvider>
        <AppsFlyerContextProvider
          config={standardLayoutData.headerData.appsFlyerData}
        >
          <Header headerData={standardLayoutData.headerData} />
        </AppsFlyerContextProvider>
      </HeaderBackdropContextProvider>
    </HeaderSearchContextProvider>
  )
}
