'use client'

import { type FC, type PropsWithChildren, type ReactNode } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { apmCaptureError } from '../apm'

import { ErrorBoundaryError } from './ErrorBoundaryError'

type ErrorBoundaryProps = PropsWithChildren<{
  fallback?: ReactNode
}>

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  children,
  fallback,
}) => (
  <ReactErrorBoundary
    fallback={fallback}
    onError={(error) => {
      apmCaptureError(new ErrorBoundaryError(error))
    }}
  >
    {children}
  </ReactErrorBoundary>
)
