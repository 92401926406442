import { type ReactNode } from 'react'

import { type TENANT } from '../../tenant/tenantTypes'

type ExperimentVariantProps = {
  children: () => ReactNode
  name: string
  // tenant prop is checked in the parent `Experiment` component
  tenant?: TENANT | TENANT[] | null
}

export const ExperimentVariant = ({
  children,
}: ExperimentVariantProps): ReactNode => {
  return children()
}
