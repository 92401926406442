import { useSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  type ForwardedRef,
  forwardRef,
  type ReactElement,
} from 'react'

export type IconListItemProps = ComponentPropsWithoutRef<'li'> & {
  icon: ReactElement<ComponentProps<'svg'>, 'svg'>
  slotProps?: {
    content?: ComponentPropsWithRef<'div'>
  }
}

/**
 * The `IconListItem` is used to represent an item in a list. It must be contained in a parent unordered list (`<ul>`) element.
 * In unordered lists, they are displayed with an ascending system icon (`@redteclab/icons`) on the left.
 */
export const IconListItem = forwardRef(function IconListItem(
  props: IconListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const { children, className, icon, slotProps = {}, ...rest } = props

  const contentProps = useSlotProps({
    className: 'icon-list-item__content',
    elementType: 'div',
    externalSlotProps: slotProps.content,
    ownerState: undefined,
  })

  return (
    <li className={clsx('icon-list-item', className)} {...rest} ref={ref}>
      <div className="icon-list-item__icon-container">{icon}</div>
      <div {...contentProps}>{children}</div>
    </li>
  )
})
