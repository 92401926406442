declare global {
  interface Window {
    // Contentsquare https://docs.contentsquare.com/uxa-en/
    _uxa?: [string, unknown][]
  }
}

export const activateContentsquareExperiment = (
  experimentName: string,
): void => {
  window._uxa ??= []
  window._uxa.push(['trackPageEvent', `${experimentName}_activated`])
}
