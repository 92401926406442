import { type FC } from 'react'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import {
  type assetsCountryFlagLogoMap,
  assetUrlGetCountryFlag,
} from '../../../../../../url-handling'

import { FooterShopsInlineLabel } from './FooterShopsInlineLabel'
import { type FooterShopsProps } from './FooterShopsProps'

const FooterShops: FC<FooterShopsProps> = ({ tenantLinksItems }) => {
  const { assetsPrefix } = useGlobalConfigContext()

  return (
    <>
      <FooterShopsInlineLabel />
      <ul className="flex flex-wrap gap-2 tablet:flex-nowrap">
        {tenantLinksItems.map(({ alt, href, id }) => (
          <li key={id}>
            <a href={href}>
              <NextImageWithFallback
                alt={alt}
                fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
                height={20}
                src={assetUrlGetCountryFlag({
                  assetsPrefix,
                  country: id as keyof typeof assetsCountryFlagLogoMap,
                })}
                width={30}
              />
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export { FooterShops }
