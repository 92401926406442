import { clsx } from 'clsx'
import { type FC } from 'react'

import { useExpaContext } from '../../../../../expa'
import { FooterLinkCategory } from '../FooterLinkCategory'

import { type FooterCategoriesSectionProps } from './FooterCategoriesSectionProps'

const FooterCategoriesSection: FC<FooterCategoriesSectionProps> = ({
  categories,
  className = '',
}) => {
  const expa = useExpaContext()

  return (
    <div className={clsx('hidden tablet:block', className)}>
      <h2 className="mb-7 px-3.5 text-xl font-medium text-dark-primary-max">
        {categories.title}
      </h2>
      <div className="flex flex-wrap">
        {categories.items.map((category) => (
          <FooterLinkCategory
            category={{
              items: category.items.filter(
                (item) =>
                  !item.cleansite?.some((cleansite) => cleansite === expa),
              ),
              title: category.title,
            }}
            className="mb-5 w-1/4 pl-12"
            key={category.title}
          />
        ))}
      </div>
    </div>
  )
}

export { FooterCategoriesSection }
