import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ElementType,
  forwardRef,
  type ReactElement,
} from 'react'

import { IconCheck, IconInfo, IconWarning } from '@redteclab/icons'

type Severity = 'error' | 'info' | 'success' | 'warning'

type Variant = 'primary' | 'secondary'

export type NotificationProps = ComponentPropsWithoutRef<'div'> & {
  /**
   * Override the icon displayed before the children. Unless provided,
   * the icon is mapped to the value of the `severity` prop. Set to `false` to remove the `icon`.
   */
  icon?: ReactElement<ComponentProps<'svg'>, 'svg'> | boolean | null

  /**
   * The severity of the alert. This defines the color and icon used.
   * @default info
   */
  severity?: Severity

  /**
   * The title of the notification
   */
  title?: string

  /**
   * The variant to use.
   * @default filled
   */
  variant?: Variant
}

const severityIconsMap: Record<Severity, ElementType> = {
  error: IconWarning,
  info: IconInfo,
  success: IconCheck,
  warning: IconInfo,
}

/**
 * A notification displays a short, important message in a way that attracts
 * the user's attention without interrupting the user's task.
 */
export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  function Notification(props, ref) {
    const {
      children,
      className,
      icon: iconProp,
      severity = 'info',
      title,
      variant = 'primary',
      ...rest
    } = props

    let icon = iconProp

    if (icon === undefined || icon === true) {
      const SeverityIcon = severityIconsMap[severity]

      icon = <SeverityIcon className="notification__icon" />
    }

    return (
      <div
        {...rest}
        className={clsx(
          'notification',
          severity === 'error' && 'notification_error',
          severity === 'info' && 'notification_info',
          severity === 'success' && 'notification_success',
          severity === 'warning' && 'notification_warning',
          variant === 'secondary' && 'notification_secondary',
          variant === 'primary' && 'notification_primary',
          className,
        )}
        ref={ref}
      >
        {icon ? (
          <span className="notification__icon-container">{icon}</span>
        ) : null}

        <div>
          {title ? <p className="notification__title">{title}</p> : null}
          <div className="notification__text">{children}</div>
        </div>
      </div>
    )
  },
)
