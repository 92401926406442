import {
  Popper as MuiPopper,
  type PopperProps as MuiPopperProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type FC, useMemo } from 'react'

import { placementHandler, sameWidth } from '../Select/modifiers'

export const Popper: FC<MuiPopperProps> = (props: MuiPopperProps) => {
  const { className, modifiers: modifiersProp, ...rest } = props

  const modifiers = useMemo(
    () => [sameWidth, placementHandler, ...(modifiersProp ?? [])],
    [modifiersProp],
  )

  return (
    <MuiPopper
      className={clsx('autocomplete-popper', className)}
      modifiers={modifiers}
      {...rest}
    />
  )
}
