import {
  type FormControlState as MuiFormControlState,
  useFormControlContext,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

type FormHelperTextOwnProps = Partial<
  Pick<MuiFormControlState, 'disabled' | 'error'>
>

export type FormHelperTextProps = FormHelperTextOwnProps &
  Omit<ComponentPropsWithoutRef<'p'>, keyof FormHelperTextOwnProps>

export const FormHelperText = forwardRef<
  HTMLParagraphElement,
  FormHelperTextProps
>(function FormHelperText(props, ref) {
  const { className, disabled: disabledProp, error: errorProp, ...rest } = props

  const formControlContext = useFormControlContext()

  const { disabled, error } = {
    disabled: disabledProp,
    error: errorProp,
    ...formControlContext,
  }

  return (
    <p
      className={clsx(
        'form-helper-text',
        disabled && 'form-helper-text_disabled',
        error && 'form-helper-text_error',
        Boolean(formControlContext) && 'form-helper-text_form-control',
        className,
      )}
      ref={ref}
      {...rest}
    />
  )
})
