import { clsx } from 'clsx'
import { type FC } from 'react'

import { Loading } from '../../../../../../../loading'
import { useUserApiSwrSessionV1Customer } from '../../../../../../../user-data'
import { LanguageSwitcherAccordion } from '../../../../../components/LanguageSwitcher'
import { useOffCanvasHeaderMenuContext } from '../../../../OffCanvasHeaderMenuContext'

import { AuthHeader } from './AuthHeader'
import { OffCanvasHeaderMenuRootSlideCloseButton } from './OffCanvasHeaderMenuRootSlideCloseButton'
import { OffCanvasHeaderMenuRootSlideLogoutItem } from './OffCanvasHeaderMenuRootSlideLogoutItem'
import { OffCanvasHeaderMenuRootSlideMenuNodeRenderer } from './OffCanvasHeaderMenuRootSlideMenuNodeRenderer'
import { OffCanvasHeaderMenuRootSlideSaluteUserItem } from './OffCanvasHeaderMenuRootSlideSaluteUserItem'

/**
 * Root slide renders "root level menu"
 */
const OffCanvasHeaderMenuRootSlide: FC = () => {
  const offCanvasHeaderMenuContext = useOffCanvasHeaderMenuContext()
  const cmsComponentMenus = offCanvasHeaderMenuContext.slidesControls.rootData
  const { data: userData } = useUserApiSwrSessionV1Customer()

  const userLoggedIn = Boolean(userData?.customerNumber)
  const userFirstName = userData?.firstName ?? ''

  return (
    <div className="relative flex size-full flex-col">
      {offCanvasHeaderMenuContext.slidesControls.isLoading ? (
        <Loading className="absolute" />
      ) : null}
      <h3 className="flex items-center justify-between bg-light-brand-high pl-3.5 text-m font-bold leading-xl text-dark-primary-max">
        <OffCanvasHeaderMenuRootSlideSaluteUserItem
          userFirstName={userFirstName}
          userLoggedIn={userLoggedIn}
        />
        <OffCanvasHeaderMenuRootSlideCloseButton
          onClick={(): void => {
            offCanvasHeaderMenuContext.openControls.close()
          }}
        />
      </h3>
      <div
        className={clsx(
          'flex h-full flex-col overflow-auto bg-light-primary-medium',
          userLoggedIn && 'pb-11',
        )}
      >
        <AuthHeader userLoggedIn={userLoggedIn} />
        <LanguageSwitcherAccordion />
        {cmsComponentMenus.map((cmsComponentMenu, index) => {
          return (
            <OffCanvasHeaderMenuRootSlideMenuNodeRenderer
              cmsComponentMenu={cmsComponentMenu}
              isHighlighted={Boolean(
                cmsComponentMenu.tags?.includes('highlighted'),
              )}
              isSecond={index === 1}
              key={cmsComponentMenu.title}
            />
          )
        })}
        {userLoggedIn ? <OffCanvasHeaderMenuRootSlideLogoutItem /> : null}
      </div>
    </div>
  )
}

export { OffCanvasHeaderMenuRootSlide }
