import { type FC, type JSX } from 'react'

import { StaticImage } from '../../../../../image'
import {
  FooterFixedSizeIllustration,
  type FooterFixedSizeIllustrationType,
} from '../FooterFixedIllustration'
import { FooterFixedSizeIcon } from '../FooterFixedSizeIcon'

import { type FooterUspProps } from './FooterUspProps'

const getFooterIllustrationTypeFromName = (
  name: string,
): FooterFixedSizeIllustrationType | undefined => {
  switch (name) {
    case 'fastDelivery': {
      return 'fastDelivery'
    }
    case 'freeDelivery': {
      return 'freeDelivery'
    }
    case 'ownBrands': {
      return 'ownBrands'
    }
    case 'productRange': {
      return 'productRange'
    }
    case 'ratings': {
      return 'ratings'
    }

    default: {
      return undefined
    }
  }
}

export const FooterUsp: FC<FooterUspProps> = ({ uspItems }) => {
  return (
    <div className="mx-auto mb-5 max-w-5xl px-4">
      <ul className="flex flex-col gap-4 rounded-3xl bg-light-brand-medium p-4 tablet:flex-row">
        {uspItems.map((item) => {
          let image: JSX.Element | null = null

          if ('imageData' in item) {
            image = (
              <StaticImage
                alt=""
                height={100}
                src={item.imageData}
                width={100}
              />
            )
          } else if (item.isIllustration) {
            image = (
              <FooterFixedSizeIllustration
                name={getFooterIllustrationTypeFromName(item.icon)}
              />
            )
          } else {
            image = <FooterFixedSizeIcon name={item.icon} />
          }

          return (
            <li className="flex flex-1 items-center gap-3" key={item.headline}>
              {image}
              <div>
                <h3
                  className="break-words text-l text-dark-brand [hyphens:auto] tablet:text-xl"
                  // eslint-disable-next-line react/no-danger -- headline contains superscript HTML markup, migration to Server Component could resolve this issue
                  dangerouslySetInnerHTML={{ __html: item.headline }}
                />
                <p
                  className="text-m text-dark-primary-strong tablet:text-l"
                  // eslint-disable-next-line react/no-danger -- label contains superscript HTML markup, migration to Server Component could resolve this issue
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
