import {
  Option as MuiOption,
  type OptionProps,
  type OptionType,
} from '@mui/base'
import { useListItem } from '@mui/base/useList'
import { clsx } from 'clsx'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

import { IconCheck } from '@redteclab/icons'

export { type OptionProps } from '@mui/base'

export const Option = forwardRef<HTMLLIElement, OptionProps<unknown>>(
  function Option<OptionValue, RootComponentType extends ElementType>(
    props: OptionProps<OptionValue, RootComponentType>,
    ref: ForwardedRef<HTMLLIElement>,
  ) {
    const { children, className, value } = props

    const { highlighted, selected } = useListItem({
      item: value,
    })

    return (
      <MuiOption
        {...props}
        className={clsx(
          'option',
          highlighted && 'option_highlighted',
          selected && 'option_selected',
          className,
        )}
        ref={ref}
      >
        {children}
        {selected ? <IconCheck className="option__icon" /> : null}
      </MuiOption>
    )
  },
) as OptionType
