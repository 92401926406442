import { type SelectRootSlotProps, useFormControlContext } from '@mui/base'
import { clsx } from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

import { IconCaretDown } from '@redteclab/icons'

type RootProps<V extends object> = Omit<SelectRootSlotProps<V, false>, 'ref'>

export const Root = forwardRef<HTMLButtonElement, RootProps<object>>(
  function Root<V extends object>(
    props: RootProps<V>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const { children, className, ownerState, ...rest } = props

    const formControlContext = useFormControlContext()

    return (
      <button
        type="button"
        {...rest}
        className={clsx(
          Boolean(formControlContext) && 'form-control__select-root',
          'select-root',
          ownerState.open && 'select-root_open',
          className,
        )}
        ref={ref}
      >
        <span
          className={clsx(
            'select-root__value',
            Boolean(formControlContext) && 'form-control__select-root-value',
          )}
        >
          {children}
        </span>
        <IconCaretDown className="select-root__icon" />
      </button>
    )
  },
)
