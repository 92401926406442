import { type FC } from 'react'

import { type FooterBottomSectionProps } from './FooterBottomSectionProps'
import { FooterCopyrightAndShops } from './FooterCopyrightAndShops'
import { FooterLegalText } from './FooterLegalText'
import { FooterSocialAndApps } from './FooterSocialAndApps'

const FooterBottomSection: FC<FooterBottomSectionProps> = ({
  appLinksItems,
  childComponentClassNames,
  legalTextIframeSrc,
  socialLinksItems,
  tenantItemsLinks,
}) => {
  return (
    <>
      <FooterSocialAndApps
        appLinksItems={appLinksItems}
        className={childComponentClassNames}
        socialLinksItems={socialLinksItems}
      />
      <FooterCopyrightAndShops
        className={childComponentClassNames}
        tenantItemsLinks={tenantItemsLinks}
      />
      <FooterLegalText
        className={childComponentClassNames}
        legalTextIframeSrc={legalTextIframeSrc}
      />
    </>
  )
}

export { FooterBottomSection }
