import { clsx } from 'clsx'
import { type FC } from 'react'

import { type FooterMenuLink } from '../../../config/PerTenantFooterConfiguration.type'

import { type FooterLinkCategoryProps } from './FooterLinkCategoryProps'

const getLinkAsString = (className: string, item: FooterMenuLink): string =>
  `<a class="${className}" href="${item.href}">${item.title}</a>`

// eslint-disable-next-line no-script-url
const JAVASCRIPT_URL = 'javascript:'

const FooterLinkCategory: FC<FooterLinkCategoryProps> = ({
  category,
  className,
  invertedColors,
  noTitle,
}) => {
  const baseListClassNames = '[hyphens:auto] break-words'
  const baseHeaderClassNames = 'mb-4 text-s font-bold text-dark-primary-max'
  const invertedHeaderClassNames = 'text-dark-primary-max'
  const baseItemClassNames =
    'text-s text-dark-primary-strong hover:text-dark-pressed-brand'
  const invertedItemClassNames = 'text-dark-primary-max'

  return (
    <div className={className}>
      {!noTitle && (
        <div
          className={clsx(
            baseHeaderClassNames,
            invertedColors && invertedHeaderClassNames,
          )}
        >
          {category.title}
        </div>
      )}
      <ul className={baseListClassNames}>
        {category.items.map((item) => {
          const hasJavaScriptUrl = item.href.includes(JAVASCRIPT_URL)
          const linkClasses = clsx(
            baseItemClassNames,
            invertedColors && invertedItemClassNames,
          )

          return (
            <li
              className="mb-2"
              /*
               * Data coming from the CMS can have "javascript:" URLs, which trigger a warning in React.
               * We need to use "dangerouslySetInnerHTML" to bypass that warning.
               */
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={
                hasJavaScriptUrl
                  ? { __html: getLinkAsString(linkClasses, item) }
                  : undefined
              }
              key={item.title}
            >
              {/* When using "dangerouslySetInnerHTML" we need to explicitly set "children" to "undefined"
              because they are not allowed at the same time */}
              {hasJavaScriptUrl ? undefined : (
                <a
                  className={linkClasses}
                  data-qa-id="footer-links"
                  href={item.href}
                >
                  {item.title}
                </a>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { FooterLinkCategory }
