import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../../global-config'
import { urlResolverGetLogout } from '../../../../../../url-handling'

export const HeaderLogoutButton: FC = () => {
  const globalConfig = useGlobalConfigContext()

  return (
    <div className="mt-7">
      <form action={urlResolverGetLogout(globalConfig)} method="post">
        <Button
          className="w-full"
          data-qa-id="form-Menubar.Logout"
          type="submit"
        >
          <FormattedMessage id="general.link.label.logout" />
        </Button>
      </form>
    </div>
  )
}
