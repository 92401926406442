'use client'

import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type FC,
  useEffect,
  useRef,
} from 'react'

import { useAdServerCloseBannerControls } from '../../useAdServerCloseBannerControls'
import { useAdServerConfigurationContext } from '../AdServerConfigurationContextProvider/useAdServerConfigurationContext'

import { AdServerBannerCloseButton } from './AdServerBannerCloseButton'
import { AdServerBannerLegalText } from './AdServerBannerLegalText'

type AdSlotDimensions = {
  height: number
  width: number
}

type AdBannerProps = ComponentPropsWithoutRef<'div'> & {
  bannerTags: string[]
  dimensions: AdSlotDimensions
  optional?: boolean
  showSkeleton?: boolean
}

export const AdServerBanner: FC<AdBannerProps> = ({
  bannerTags,
  className,
  dimensions,
  optional,
  // will rezerve the space for the banner by displaying a skeleton with banner sizes
  showSkeleton = false,
  ...restProps
}) => {
  const bannerWrapperRef = useRef<HTMLDivElement>(null)
  const { getBanner, initBanners } = useAdServerConfigurationContext()
  const bannerClasses = clsx(
    'as-placeholder',
    optional && 'as-placeholder__optional',
    className,
  )

  const bannerData = getBanner({
    height: dimensions.height,
    id: bannerTags.toString(),
    width: dimensions.width,
  })

  useEffect(() => {
    // if banner data is not present on the context then we need to fetch it
    if (!bannerData) {
      initBanners([
        {
          height: dimensions.height,
          id: bannerTags.toString(),
          width: dimensions.width,
        },
      ])
    }

    if (bannerData?.bannerElement && (showSkeleton || bannerData.metadata)) {
      // we replace the adBanner placeholder with the actual banner and add banner specific classes
      bannerData.bannerElement.className = bannerClasses
      bannerWrapperRef.current?.replaceWith(bannerData.bannerElement)
    }
  }, [
    bannerData,
    bannerTags,
    dimensions.height,
    dimensions.width,
    initBanners,
    showSkeleton,
    bannerClasses,
  ])

  const closeBannerControls = useAdServerCloseBannerControls({
    metaDataContent: bannerData?.metadata,
  })

  if (!closeBannerControls.showBanner) {
    return null
  }

  const legalButton = bannerData?.metadata?.customData?.legalButton

  return (
    <div className="relative flex max-w-full overflow-hidden rounded-2xl">
      <div
        className={bannerClasses}
        ref={bannerWrapperRef}
        style={{
          ...(showSkeleton
            ? {
                aspectRatio: dimensions.width / dimensions.height,
                width: `${dimensions.width}px`,
              }
            : {}),
          ...restProps.style,
        }}
        {...restProps}
      />
      {closeBannerControls.showCloseButton ? (
        <AdServerBannerCloseButton onClick={closeBannerControls.handleClose} />
      ) : null}
      {legalButton ? (
        <AdServerBannerLegalText
          label={legalButton.label}
          text={legalButton.text}
        />
      ) : null}
    </div>
  )
}
