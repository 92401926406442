import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../../global-config'
import { urlResolverGetLogin } from '../../../../../../url-handling'

export const HeaderLoginButton: FC = () => {
  const globalConfig = useGlobalConfigContext()

  return (
    <div className="mb-3.5">
      <Button<'a'>
        className="w-full"
        data-qa-id="form-Menubar.Login"
        href={urlResolverGetLogin(globalConfig)}
        slots={{ root: 'a' }}
      >
        <FormattedMessage id="general.link.label.login" />
      </Button>
    </div>
  )
}
