import Image, { type ImageProps } from 'next/image'
import { forwardRef } from 'react'

type StaticImageProps = Omit<ImageProps, 'unoptimized'>

/**
 * Image component for statically imported images.
 *
 * Example:
 * ```tsx
 * import image from '../../public/images/accountpage-e-prescription.svg'
 *
 * const Example = () => <StaticImage src={image} />
 * ```
 */
export const StaticImage = forwardRef<HTMLImageElement, StaticImageProps>(
  function StaticImage(props, ref) {
    return <Image {...props} alt={props.alt} ref={ref} unoptimized />
  },
)
