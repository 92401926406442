'use client'

import { clsx } from 'clsx'
import { type FC, useCallback, useEffect, useState } from 'react'

import { useGlobalNavigationChangeListener } from '../../../../navigation-listener'

/* eslint-disable @typescript-eslint/no-magic-numbers */
/**
 * [timeout, percentOfProgress]
 */
const progression = [
  [0, 25],
  [150, 30],
  [150, 35],
  [150, 40],
  [150, 55],
  [150, 65],
  [150, 70],
  [150, 75],
  [170, 80],
  [200, 85],
  [200, 90],
  [300, 95],
]
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const HeaderNavigationProgressBar: FC = () => {
  const navigation = useGlobalNavigationChangeListener()
  const [progress, setProgress] = useState<number | null>(null)
  const [progressClass, setProgressClass] = useState<string>('')

  /**
   * recursively applies percentage and timeout of {@link progression},
   * starting with 0 until end of progression.length
   */
  const progressGradually = useCallback((index: number): void => {
    if (index > progression.length - 1) {
      return
    }
    const [timeOut, progressPercentage] = progression[index]
    setTimeout(() => {
      setProgress(progressPercentage)
      progressGradually(index + 1)
    }, timeOut)
  }, [])

  useEffect(() => {
    // start progress
    if (navigation.isNavigating && progress === null) {
      setProgressClass('')
      progressGradually(0)

      return
    }

    // end progress
    if (!navigation.isNavigating && progress !== null) {
      setProgress(100)
      setTimeout(() => {
        setProgress(null)
        setProgressClass('opacity-0')
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      }, 50)
    }
  }, [navigation.isNavigating, progress, progressGradually])

  return (
    <div className="h-1 w-full">
      <div
        className={clsx(
          'h-1 bg-gradient-to-r from-light-error to-dark-error transition-[width]',
          progressClass,
        )}
        style={{
          width: progress ? `${progress}%` : '0%',
        }}
      />
    </div>
  )
}
