import { clsx } from 'clsx'
import { type FC } from 'react'

import { IconCaretDown } from '@redteclab/icons'

import { Accordion, AccordionDetails, AccordionSummary } from 'base-ui'

import { FooterLinkCategory } from '../../FooterLinkCategory'

import { type FooterInfoSectionProps } from './FooterInfoSectionProps'

import styles from './FooterInfoSection.module.css'

const FooterInfoSection: FC<FooterInfoSectionProps> = ({
  className = '',
  infoSectionLinks,
}) => {
  return (
    <div className={`gap-7 tablet:flex ${className}`}>
      {infoSectionLinks.map((category, index) => {
        const categoryId = `${category.title.replaceAll(' ', '_')}_${index}`

        return (
          <Accordion
            className={clsx(
              'block flex-1 rounded-none border-b-0 last:rounded-b last:border-b tablet:border-0 tablet:bg-transparent last:tablet:border-b-0',
              styles.accordion,
            )}
            id={categoryId}
            key={categoryId}
          >
            <AccordionSummary
              className="text-l font-bold text-dark-primary-max aria-expanded:bg-transparent tablet:pointer-events-none tablet:bg-transparent tablet:px-0"
              expandIcon={<IconCaretDown className="tablet:hidden" />}
            >
              {category.title}
            </AccordionSummary>
            <AccordionDetails className="tablet:px-0">
              <FooterLinkCategory
                category={category}
                key={category.title}
                noTitle
              />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

export { FooterInfoSection }
