'use client'

import { clsx } from 'clsx'
import { type FC, useEffect, useState } from 'react'

import { IconCaretUp } from '@redteclab/icons'

import styles from './ButtonScrollToTop.module.css'

const OFFSET_TOP_PX = 150

export const ButtonScrollToTop: FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [moveToTop, setMoveToTop] = useState(false)

  useEffect(() => {
    const onDisplayButton = (): void => {
      const shouldDisplayButton = window.scrollY > OFFSET_TOP_PX
      if (shouldDisplayButton) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    const onDisplayStickyBuyBox = (): void => {
      const stickyBuyBox = document.querySelector(
        '[data-clientside-hook="stickyBuyBox"]',
      )

      if (stickyBuyBox instanceof HTMLElement) {
        setMoveToTop(true)
      } else {
        setMoveToTop(false)
      }
    }
    const fireEvents = (): void => {
      onDisplayButton()
      onDisplayStickyBuyBox()
    }
    window.addEventListener('scroll', fireEvents)

    return (): void => {
      window.removeEventListener('scroll', fireEvents)
    }
  }, [])

  return (
    <button
      aria-label="scroll to top"
      className={clsx(
        'fixed bottom-5 right-32 z-20 hidden size-14 items-center justify-center rounded-full bg-light-primary-low text-dark-brand shadow-md transition-opacity tablet:flex print:hidden',
        isVisible ? 'opacity-100' : 'opacity-0',
        styles['scroll-to-top'],
        moveToTop && 'mb-28',
      )}
      data-qa-id="form-scrollToTop"
      onClick={(): void => {
        window.scrollTo({ behavior: 'smooth', top: 0 })
      }}
      type="button"
    >
      <IconCaretUp className="text-2xl" />
    </button>
  )
}
