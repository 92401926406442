import {
  type ModalProps as ModalUnstyledProps,
  type SlotComponentProps,
  useSlotProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  forwardRef,
  type ReactNode,
} from 'react'

import { IconClose } from '@redteclab/icons'

export type DialogTitleProps = ComponentPropsWithoutRef<'div'> & {
  closeText?: ReactNode
  onClose?: ModalUnstyledProps['onClose']
  slotProps?: {
    closeButton?: SlotComponentProps<'button', NonNullable<unknown>, undefined>
  }
  slots?: {
    closeButton?: ElementType
  }
}

export const DialogTitle = forwardRef<HTMLDivElement, DialogTitleProps>(
  function DialogTitle(props, ref) {
    const {
      children,
      className,
      closeText,
      onClose,
      slotProps,
      slots,
      ...rest
    } = props

    const CloseButton = slots?.closeButton ?? 'button'
    const closeButtonProps = useSlotProps({
      additionalProps: {
        onClick: (): void => {
          onClose?.({}, 'escapeKeyDown')
        },
        type: 'button',
      },
      className: 'dialog-title__close-button',
      elementType: CloseButton,
      externalSlotProps: slotProps?.closeButton,
      ownerState: undefined,
    })

    return (
      <div {...rest} className={clsx('dialog-title', className)} ref={ref}>
        <h3 className="dialog-title__text">{children}</h3>
        <CloseButton {...closeButtonProps}>
          <IconClose className="dialog-title__close-button-icon" />
          {closeText ? (
            <span className="dialog-title__close-button-text">{closeText}</span>
          ) : null}
        </CloseButton>
      </div>
    )
  },
)
