import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconProfileUserAvatar } from '@redteclab/icons'

import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN,
  experimentsTrackEvent,
} from '../../../../../../../experiments/eventTracking'
import { useGlobalConfigContext } from '../../../../../../../global-config'
import {
  urlResolverGetAccount,
  urlResolverGetLogin,
} from '../../../../../../../url-handling'

interface AuthHeaderProps {
  userLoggedIn: boolean
}

export const AuthHeader: FC<AuthHeaderProps> = ({ userLoggedIn }) => {
  const globalConfig = useGlobalConfigContext()

  return (
    <ul className="pl-5">
      <li className="flex h-12 items-center">
        <a
          className="flex w-full items-center gap-1 py-2.5"
          onClick={() => {
            experimentsTrackEvent(
              globalConfig,
              userLoggedIn
                ? EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN
                : EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN,
            )
          }}
          {...(!userLoggedIn && { 'data-qa-id': 'form-off-canvas-login' })}
          href={
            userLoggedIn
              ? urlResolverGetAccount(globalConfig)
              : urlResolverGetLogin(globalConfig)
          }
        >
          <IconProfileUserAvatar className="size-8 fill-dark-primary-max p-0.5" />
          {userLoggedIn ? (
            <FormattedMessage id="offcanvas.link.myApotheke" />
          ) : (
            <FormattedMessage id="offcanvas.link.loginOrRegister" />
          )}
        </a>
      </li>
    </ul>
  )
}
