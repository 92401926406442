import { clsx } from 'clsx'
import { forwardRef, type HTMLAttributes } from 'react'

export const DialogContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(function DialogContent(props, ref) {
  const { children, className, ...rest } = props

  return (
    <div className={clsx('dialog-content', className)} ref={ref} {...rest}>
      {children}
    </div>
  )
})
