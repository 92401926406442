import { type FC } from 'react'

import {
  IconProfileUserAvatar,
  IconProfileUserAvatarLoggedIn,
} from '@redteclab/icons'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import {
  urlResolverGetAccount,
  urlResolverGetLogin,
} from '../../url-handling/urlResolver'
import { useUserApiSwrSessionV1Customer } from '../../user-data/api/swr/useUserApiSwrSessionV1Customer'
import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN,
  experimentsTrackEvent,
} from '../eventTracking'

export const HeaderMobileIconListExperimentAccountIcon: FC = () => {
  const { data: userData } = useUserApiSwrSessionV1Customer()
  const globalConfig = useGlobalConfigContext()

  const isLoggedIn = Boolean(userData?.customerNumber)

  return (
    <a
      className="p-1.5"
      href={
        isLoggedIn
          ? urlResolverGetAccount(globalConfig)
          : urlResolverGetLogin(globalConfig)
      }
      onClick={() => {
        experimentsTrackEvent(
          globalConfig,
          isLoggedIn
            ? EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN
            : EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN,
        )
      }}
    >
      {isLoggedIn ? (
        <IconProfileUserAvatarLoggedIn className="size-7 fill-dark-primary-max" />
      ) : (
        <IconProfileUserAvatar className="size-7 fill-dark-primary-max" />
      )}
    </a>
  )
}
