/**
 * serializes passed object/record to a query string
 * @example
 * {foo: 'joe', bar: 'bob'} => foo=joe&bar=bob
 * {foo: 'joe', bar: ['jau', 'bau'] } => foo=joe&bar=jau&bar=bau
 */
export const queryStringEncode = (
  record?: Record<string, unknown>,
  prefixWithQuestionMark = true,
): string => {
  if (!record) {
    return ''
  }

  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(record)) {
    if (!value) {
      continue
    }

    if (Array.isArray(value)) {
      for (const innerValue of value) {
        if (!innerValue) {
          continue
        }

        searchParams.append(key, innerValue as string)
      }

      continue
    }

    searchParams.set(key, value as string)
  }

  const prefix = prefixWithQuestionMark ? '?' : ''

  return `${prefix}${searchParams.toString()}`
}
