import { type FC } from 'react'

import { HeaderMobileOpenSearch } from '../../standard-shop-layout/header/components/Header/HeaderMobileOpenSearch'
import { HeaderCart } from '../../standard-shop-layout/header/components/HeaderMiddleSection/HeaderCart'

import { HeaderMobileIconListExperimentAccountIcon } from './HeaderMobileIconListExperimentAccountIcon'

//Experiment will be only distributed to AT and BE, therefore no need to include rx/eRx component
export const HeaderMobileIconListExperiment: FC = () => {
  return (
    <ul className="flex items-center gap-1 pl-4">
      <li className="flex">
        <HeaderMobileOpenSearch />
      </li>
      <li className="flex">
        <HeaderMobileIconListExperimentAccountIcon />
      </li>
      <li className="flex">
        <HeaderCart isMobile />
      </li>
    </ul>
  )
}
