import {
  type PolymorphicComponent,
  type PolymorphicProps,
  type SlotComponentProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

type LinkOwnProps = {
  /**
   * @default brand
   */
  color?: 'brand' | 'inherit'

  /**
   * If `true`, the `::after` pseudo-element is added to cover the area of interaction.
   * The parent of the overlay `Link` should have relative CSS position.
   */
  overlay?: boolean

  slotProps?: {
    root?: SlotComponentProps<'a', NonNullable<unknown>, NonNullable<unknown>>
  }

  slots?: {
    root?: ElementType
  }
}

interface LinkTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'a',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps & LinkOwnProps
}

type LinkProps<
  RootComponentType extends ElementType = LinkTypeMap['defaultComponent'],
> = PolymorphicProps<
  LinkTypeMap<NonNullable<unknown>, RootComponentType>,
  RootComponentType
>

/**
 * The Link component allows you to easily customize anchor elements with theme colors
 */
export const Link = forwardRef(function Link(
  props: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const {
    className,
    color = 'brand',
    overlay,
    slotProps = {},
    slots = {},
    ...other
  } = props

  const Root = slots.root ?? 'a'

  return (
    <Root
      {...other}
      {...slotProps.root}
      className={clsx(
        'link',
        color === 'brand' && 'link_brand',
        overlay && 'link_overlay',
        className,
      )}
      ref={ref}
    />
  )
}) as PolymorphicComponent<LinkTypeMap>
