import {
  type CmsBlockMenu,
  type CmsBlockMenuItemsInner,
  type CmsComponentMenu,
} from '@redteclab/api/clients/content-management-system'

import { headerCmsBlockMenuItemsInnerAccessorIsComponentMenu } from './headerCmsBlockMenuItemsInnerAccessor'

/**
 * returns data rendered inside the "hamburger" menu
 */
export const headerCmsBlockMenuAccessorGetHamburgerMenu = (
  headerCmsMenuData: CmsBlockMenu,
): CmsComponentMenu[] => {
  const { items } = headerCmsMenuData
  const hamburgerMenuRoot = items?.[0]

  if (!hamburgerMenuRoot) {
    return []
  }

  if (!headerCmsBlockMenuItemsInnerAccessorIsComponentMenu(hamburgerMenuRoot)) {
    return []
  }

  return hamburgerMenuRoot.items as CmsComponentMenu[]
}

/**
 * returns data for navigation menu
 */
export const headerCmsBlockMenuAccessorGetNavigationMenuData = (
  headerCmsMenuData: CmsBlockMenu,
): CmsBlockMenuItemsInner[] => {
  return headerCmsMenuData.items ?? []
}
