import { clsx } from 'clsx'
import { type FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { type FockNode } from '@redteclab/api/clients/content-management-system'
import { IconArrowLeft, IconClose } from '@redteclab/icons'

import { useGlobalConfigContext } from '../../../../../../../global-config'
import { Loading } from '../../../../../../../loading'
import { urlNormalizeUri } from '../../../../../../../url-handling'
import { LanguageSwitcherAccordion } from '../../../../../components/LanguageSwitcher'
import { headerFockNodeDownInnerAccessorDownNodeHasChildren } from '../../../../../model/headerFockNodeDownInnerAccessor'
import { useOffCanvasHeaderMenuContext } from '../../../../OffCanvasHeaderMenuContext'
import { OffCanvasHeaderMenuListItem } from '../OffCanvasHeaderMenuListItem'

interface OffCanvasHeaderMenuSlideProps {
  menuNode: FockNode
  zIndex: number
}

const useShouldSlideIn = (): boolean => {
  const [shouldSlideIn, setShouldSlideIn] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShouldSlideIn(true)
    })
  }, [shouldSlideIn])

  return shouldSlideIn
}

const SLIDE_OUT_ANIMATION_DURATION = 310

const useSlideOutAndPopFromSlides = (): {
  handleGoBack: () => void
  shouldSlideOut: boolean
} => {
  const offCanvasHeaderMenuContext = useOffCanvasHeaderMenuContext()
  const [shouldSlideOut, setShouldSlideOut] = useState(false)
  const handleGoBack = (): void => {
    setShouldSlideOut(true)
    setTimeout(() => {
      offCanvasHeaderMenuContext.slidesControls.popSlide()
    }, SLIDE_OUT_ANIMATION_DURATION)
  }

  return {
    handleGoBack,
    shouldSlideOut,
  }
}

const getContainerClassNames = (
  shouldSlideOut: boolean,
  shouldSlideIn: boolean,
): string => {
  return clsx(
    'absolute flex h-full min-h-full min-w-full flex-col bg-light-primary-medium',
    (shouldSlideOut || !shouldSlideIn) &&
      'left-[+100%] transition-[left] duration-300',
    !shouldSlideOut && shouldSlideIn && 'left-0 transition-[left] duration-300',
  )
}

const OffCanvasHeaderMenuSlide: FC<OffCanvasHeaderMenuSlideProps> = ({
  menuNode,
  zIndex,
}) => {
  const { openControls, slidesControls } = useOffCanvasHeaderMenuContext()
  const shouldSlideIn = useShouldSlideIn()
  const { handleGoBack, shouldSlideOut } = useSlideOutAndPopFromSlides()
  const publicGlobalConfig = useGlobalConfigContext()
  const { locale } = publicGlobalConfig

  return (
    <div
      className={getContainerClassNames(shouldSlideOut, shouldSlideIn)}
      style={{
        zIndex,
      }}
    >
      {slidesControls.isLoading ? <Loading className="absolute" /> : null}
      <h3 className="flex items-center bg-light-brand-high text-m font-bold text-dark-primary-max">
        <button
          className="flex items-center fill-dark-primary-max px-3.5 text-dark-primary-max"
          onClick={handleGoBack}
          type="button"
        >
          <IconArrowLeft className="size-7" />
          <span className="sr-only">
            <FormattedMessage id="general.link.label.back" />
          </span>
        </button>
        <span className="grow">
          <FormattedMessage id="general.link.label.back" />
        </span>
        <button
          className="min-h-14 px-3.5 hover:fill-dark-brand"
          onClick={(): void => {
            openControls.close()
          }}
          type="button"
        >
          <IconClose className="size-7" />
          <span className="sr-only">
            <FormattedMessage id="general.link.label.close" />
          </span>
        </button>
      </h3>
      <div className="overflow-auto">
        <ul>
          <li>
            <a
              className="mx-3.5 my-2.5 flex text-m font-bold text-dark-primary-max"
              href={menuNode.path}
            >
              {menuNode.caption}
            </a>
          </li>
          {menuNode.down?.map((downNode) => {
            const isExpandable =
              headerFockNodeDownInnerAccessorDownNodeHasChildren(downNode)

            return (
              <OffCanvasHeaderMenuListItem
                className="pl-5"
                isExpandable={isExpandable}
                key={downNode.code}
                link={urlNormalizeUri({
                  locale,
                  uri: downNode.path,
                })}
                onExpandClick={(event): void => {
                  if (!isExpandable) {
                    return
                  }

                  event.preventDefault()

                  slidesControls.goNextSlide(
                    {
                      code: downNode.code,
                    },
                    downNode.path,
                  )
                }}
                title={downNode.caption}
              />
            )
          })}
        </ul>
        <LanguageSwitcherAccordion />
      </div>
    </div>
  )
}

export { OffCanvasHeaderMenuSlide }
