import {
  type ModalOwnProps as MuiModalOwnProps,
  type PolymorphicComponent,
  type PolymorphicProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
} from 'react'

import { Modal } from '../Modal'

type DialogOwnProps = Omit<
  MuiModalOwnProps,
  'children' | 'slotProps' | 'slots'
> & {
  children?: ReactNode
  slotProps?: MuiModalOwnProps['slotProps'] & {
    dialog?: ComponentProps<'div'>
  }
  slots?: MuiModalOwnProps['slots'] & {
    dialog?: ElementType
  }
}

interface DialogTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'div',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps & DialogOwnProps
}

export type DialogProps<
  RootComponentType extends ElementType = DialogTypeMap['defaultComponent'],
> = PolymorphicProps<
  DialogTypeMap<NonNullable<unknown>, RootComponentType>,
  RootComponentType
>

type DialogComponent = PolymorphicComponent<DialogTypeMap>

/**
 * Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks.
 */
export const Dialog = forwardRef<HTMLDivElement, DialogProps>(function Dialog<
  RootComponentType extends ElementType = 'div',
>(props: DialogProps<RootComponentType>, ref: ForwardedRef<HTMLDivElement>) {
  const { children, slotProps = {}, slots = {} } = props

  const { dialog: DialogPropComponent = 'div' } = slots

  return (
    <Modal ref={ref} {...props}>
      <DialogPropComponent
        {...slotProps.dialog}
        className={clsx('dialog', slotProps.dialog?.className)}
      >
        {children}
      </DialogPropComponent>
    </Modal>
  )
}) as DialogComponent
