import { type PolymorphicComponent, type PolymorphicProps } from '@mui/base'
import { type ButtonOwnProps as MuiButtonOwnProps } from '@mui/base/Button/Button.types'
import { clsx } from 'clsx'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

import { Button, type ButtonOwnProps } from '../Button'
import { CircularProgress } from '../CircularProgress'

export type LoadingButtonOwnProps = {
  /**
   * If `true`, the loading indicator is shown
   * @default false
   */
  loading?: boolean

  /**
   * The loading indicator can be positioned on the start, end, or the center of the button
   * @default center
   */
  loadingPosition?: 'center' | 'end' | 'start'
}

export interface LoadingButtonTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'button',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps &
    ButtonOwnProps &
    Omit<MuiButtonOwnProps, 'href' | 'rootRef' | 'to' | 'type'>
}

export type LoadingButtonProps<
  RootComponentType extends
    ElementType = LoadingButtonTypeMap['defaultComponent'],
> = PolymorphicProps<
  LoadingButtonTypeMap<LoadingButtonOwnProps, RootComponentType>,
  RootComponentType
>

/**
 * Loading buttons can show loading state and disable interactions.
 */
export const LoadingButton = forwardRef(function LoadingButton<
  RootComponentType extends ElementType,
>(
  props: LoadingButtonProps<RootComponentType>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const {
    children,
    className,
    disabled,
    endIcon,
    loading = false,
    loadingPosition = 'center',
    startIcon,
    ...other
  } = props

  if (process.env.NODE_ENV !== 'production') {
    if (startIcon && loadingPosition !== 'start') {
      console.warn(
        'When startIcon is provided, LoadingPosition should also be "start"',
      )
    }

    if (endIcon && loadingPosition !== 'end') {
      console.warn(
        'When endIcon is provided, LoadingPosition should also be "end"',
      )
    }
  }

  return (
    <Button<'button'>
      {...other}
      className={clsx('loading-button', className)}
      disabled={loading || disabled}
      endIcon={
        loading && loadingPosition === 'end' ? (
          <CircularProgress className="loading-button__progress loading-button__progress_end" />
        ) : (
          endIcon
        )
      }
      ref={ref}
      startIcon={
        loading && loadingPosition === 'start' ? (
          <CircularProgress className="loading-button__progress loading-button__progress_start" />
        ) : (
          startIcon
        )
      }
    >
      {loading && loadingPosition === 'center' ? (
        <CircularProgress className="loading-button__progress loading-button__progress_center" />
      ) : null}
      <span
        className={clsx(
          'loading-button__content',
          loading &&
            loadingPosition === 'center' &&
            'loading-button__content_loading',
        )}
      >
        {children}
      </span>
    </Button>
  )
}) as PolymorphicComponent<LoadingButtonTypeMap<LoadingButtonOwnProps>>
