import { clsx } from 'clsx'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { IconCaretUp } from '@redteclab/icons'

type AdvertisingFlyoutBannerButtonProps = {
  className?: string
  onClick?: () => void
}

export const AdvertisingFlyoutBannerButton: FC<
  AdvertisingFlyoutBannerButtonProps
> = ({ className, onClick }) => {
  const buttonClasses = clsx(
    'flex items-center whitespace-nowrap rounded-t-3xl bg-dark-redpoints px-6 text-s text-light-primary-strong desktop:py-3',
    className,
  )
  const intl = useIntl()

  return (
    <button className={buttonClasses} onClick={onClick} type="button">
      <IconCaretUp className="mr-1.5 size-8 fill-light-primary-strong" />

      <span className="desktop:inline">
        {intl.formatMessage({ id: 'advertisingFlyoutBanner.buttonText' })}
      </span>
    </button>
  )
}
