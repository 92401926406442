import { type FC, type JSX } from 'react'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import { assetUrlGetPrefixed } from '../../../../../../url-handling'

import { type FooterAppStoresProps } from './FooterAppStoresProps'

const logosMap = {
  appstore: 'appstore.svg',
  'appstore-fr': 'appstore-fr.svg',
  'appstore-it': 'appstore-it.svg',
  'google-play': 'google-play.svg',
  'google-play-fr': 'google-play-fr.svg',
  'google-play-it': 'google-play-it.svg',
}

const getLogoPath = ({
  assetsPrefix,
  iconId,
}: {
  assetsPrefix: string
  iconId: string
}): string => {
  const namePart = logosMap[iconId as keyof typeof logosMap]

  return namePart
    ? assetUrlGetPrefixed(assetsPrefix, `/logos/logo-${namePart}`)
    : ''
}

const FooterAppStores: FC<FooterAppStoresProps> = ({ appLinksItems }) => {
  const { assetsPrefix } = useGlobalConfigContext()

  const renderImage = (alt: string, id: string): JSX.Element => (
    <NextImageWithFallback
      alt={alt}
      fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
      height={40}
      src={getLogoPath({ assetsPrefix, iconId: id })}
      width={135}
    />
  )

  return (
    <ul className="mb-3 flex justify-end">
      {appLinksItems.map(({ alt, href, id }) => (
        <li className="ml-1 shrink-0" key={id}>
          <a href={href}>{renderImage(alt, id)}</a>
        </li>
      ))}
    </ul>
  )
}

export { FooterAppStores }
