import {
  type PolymorphicComponent,
  type PolymorphicProps,
  type SlotComponentProps,
} from '@mui/base'
import { clsx } from 'clsx'
import { type ElementType, type ForwardedRef, forwardRef } from 'react'

type SkeletonOwnProps = {
  slotProps?: {
    root?: SlotComponentProps<
      'span',
      NonNullable<unknown>,
      NonNullable<unknown>
    >
  }

  slots?: {
    root?: ElementType
  }

  /**
   * The type of content that will be rendered
   * @default text
   */
  variant?: 'circular' | 'rectangular' | 'text'
}

interface SkeletonTypeMap<
  AdditionalProps = NonNullable<unknown>,
  RootComponentType extends ElementType = 'span',
> {
  defaultComponent: RootComponentType
  props: AdditionalProps & SkeletonOwnProps
}

type SkeletonProps<
  RootComponentType extends ElementType = SkeletonTypeMap['defaultComponent'],
> = PolymorphicProps<
  SkeletonTypeMap<NonNullable<unknown>, RootComponentType>,
  RootComponentType
>

/**
 * Display a placeholder preview of your content before the data gets loaded
 * to reduce load-time frustration
 */
export const Skeleton = forwardRef(function Skeleton(
  props: SkeletonProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  const { className, slots = {}, variant = 'text', ...rest } = props

  const Root = slots.root ?? 'span'

  return (
    <Root
      {...rest}
      className={clsx(
        'skeleton',
        variant === 'circular' && 'skeleton_circular',
        variant === 'rectangular' && 'skeleton_rectangular',
        variant === 'text' && 'skeleton_text',
        className,
      )}
      ref={ref}
    />
  )
}) as PolymorphicComponent<SkeletonTypeMap>
