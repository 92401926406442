import { type InputInputSlotProps as InputUnstyledInputSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

export const TextareaInner = forwardRef<
  HTMLTextAreaElement,
  ComponentPropsWithoutRef<'textarea'> & InputUnstyledInputSlotProps
>(function TextareaInner(props, ref) {
  const {
    className,
    // @ts-expect-error -- fix `maxRows: undefined` pass to textarea https://github.com/mui/material-ui/blob/8b9664973445552566793118480a7717b8c49c29/packages/mui-base/src/Input/Input.tsx#L158-L165
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    maxRows,
    // @ts-expect-error -- fix `minRows: undefined` pass to textarea https://github.com/mui/material-ui/blob/8b9664973445552566793118480a7717b8c49c29/packages/mui-base/src/Input/Input.tsx#L158-L165
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    minRows,
    ownerState,
    ...rest
  } = props

  const { formControlContext } = ownerState

  return (
    <textarea
      {...rest}
      className={clsx(
        'textarea-inner',
        formControlContext && 'textarea-inner_form-control',
        className,
      )}
      ref={ref}
    />
  )
})
