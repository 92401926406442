import { clsx } from 'clsx'
import { type FC } from 'react'

import { IconShoppingCart } from '@redteclab/icons'

import { useGetExperiment } from '../../../../../experiments/hooks'
import { EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST } from '../../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../../experiments/model/EXPERIMENT_VARIATION'

type HeaderCartIconAndBadgeProps = {
  entriesInCart?: number
  isMobile?: boolean
}

export const HeaderCartIconAndBadge: FC<HeaderCartIconAndBadgeProps> = ({
  entriesInCart = 0,
  isMobile = false,
}) => {
  const { variant } = useGetExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  return (
    <div className="flex">
      <IconShoppingCart
        className={clsx(
          'fill-dark-primary-max group-hover:fill-dark-hover-accent group-active:fill-dark-pressed-accent',
          !isMobile && 'mb-2 size-6',
          isMobile && variant === EXPERIMENT_VARIATION.V1 && 'size-7',
          isMobile && variant !== EXPERIMENT_VARIATION.V1 && 'size-8',
        )}
      />
      <span
        className={clsx(
          'absolute box-border flex aspect-square min-w-[20px] items-center justify-center rounded-full border border-light-primary-strong bg-light-tertiary p-1 font-mono text-xs font-medium leading-none text-dark-primary-max tablet:static tablet:-ml-2 tablet:-mt-2 tablet:self-start',
          variant === EXPERIMENT_VARIATION.V1 ? '-top-1 ml-3' : 'top-0 ml-4',
        )}
      >
        <span>{entriesInCart}</span>
      </span>
    </div>
  )
}
