import { type JSX, useEffect, useMemo } from 'react'
import { useSessionStorage } from 'usehooks-ts'

import { Dialog, DialogTitle } from 'base-ui'

import { useExpaContext } from '../../expa'
import { useExperimentsContext } from '../../experiments/components/ExperimentsContext'
import { ExperimentPageType } from '../../experiments/experimentPageType'
import { useOpenableControls } from '../../generic-hooks'
import { useGlobalConfigContext } from '../../global-config'
import { gtmDataLayerPush } from '../../gtm'
import { StaticImage } from '../../image'
import { SHOP_ENV, TENANT } from '../../tenant'

import { ErxModalContent } from './ErxModalContent'
import modalHero from './images/erx-modal-hero.webp'

const ERX_MODAL_TIMEOUT = 5000
const BLACKLISTED_EXPAS = new Set(['dvd'])
const ERX_MODAL_ID = 'ErxModal'

/**
 * The modal is automatically opened after a delay specified by ERX_MODAL_TIMEOUT.
 * When the modal is opened, a 'popin' event is pushed to the GTM data layer.
 */
const ErxModal = (): JSX.Element => {
  const { handleClose, handleOpen, isOpen } = useOpenableControls(false)
  const [erxModalShown, setErxModalShown] = useSessionStorage(
    ERX_MODAL_ID,
    false,
  )
  const expa = useExpaContext() ?? ''
  const { shopEnv, tenant } = useGlobalConfigContext()
  const { pageType } = useExperimentsContext()

  const blacklistPages = useMemo(() => {
    return [
      ExperimentPageType.Account,
      ExperimentPageType.ForgotPassword,
      ExperimentPageType.Newsletter,
      ExperimentPageType.Cart,
      ExperimentPageType.Checkout,
      ExperimentPageType.Login,
      ExperimentPageType.Search,
    ]
  }, [])

  useEffect(() => {
    if (
      tenant !== TENANT.COM ||
      erxModalShown ||
      BLACKLISTED_EXPAS.has(expa) ||
      blacklistPages.includes(pageType) ||
      shopEnv !== SHOP_ENV.PROD
    ) {
      return undefined
    }

    const showPopup = setTimeout(() => {
      gtmDataLayerPush({
        event: 'popin',
        popinName: '__eRxModal',
      })
      handleOpen()
    }, ERX_MODAL_TIMEOUT)

    return (): void => {
      clearTimeout(showPopup)
    }
  }, [
    handleOpen,
    erxModalShown,
    shopEnv,
    tenant,
    expa,
    blacklistPages,
    pageType,
  ])

  const onModalClose = (): void => {
    handleClose()
    setErxModalShown(true)
  }

  return (
    <Dialog
      onClose={onModalClose}
      open={isOpen}
      slotProps={{
        dialog: {
          className:
            'mobile-sm:w-11/12 max-w-xl bg-transparent h-auto mobile-sm:-mt-20',
        },
      }}
    >
      <div className="relative">
        <DialogTitle
          className="absolute right-3 top-3 bg-transparent p-0"
          onClose={onModalClose}
        />
      </div>

      <div className="bg-light-brand-high mobile-sm:rounded-xl mobile-sm:rounded-b-none">
        <StaticImage
          alt="redeem-recipe-hero"
          className="m-auto"
          src={modalHero}
          width={324}
        />
      </div>

      <ErxModalContent onModalClose={onModalClose} />
    </Dialog>
  )
}

export { ErxModal }
