import { clsx } from 'clsx'
import { forwardRef, type HTMLAttributes } from 'react'

export const ModalBackdrop = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(function ModalBackdrop(originalProps, ref) {
  const props = { ...originalProps }

  // @ts-expect-error Passed from root component but not needed for rendering
  delete props.open
  // @ts-expect-error Passed from root component but not needed for rendering
  delete props.ownerState

  return (
    <div
      {...props}
      className={clsx('modal__backdrop', originalProps.className)}
      ref={ref}
    />
  )
})
