import { type RouterEvent, useRouter } from 'next/router'
import { useEffect } from 'react'

export const useNextRouterEvent = (
  eventName: RouterEvent,
  callback: (url: string) => void,
): void => {
  const router = useRouter()

  useEffect(() => {
    router.events.on(eventName, callback)

    return (): void => {
      router.events.off(eventName, callback)
    }
  }, [callback, eventName, router])
}
