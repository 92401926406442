import { useCallback, useState } from 'react'

import { useNextRouterEvent } from './useNextRouterEvent'

export const useGlobalNavigationChangeListener = (): {
  isNavigating: boolean
  url: string
} => {
  const [isNavigating, setIsNavigating] = useState(false)
  const [url, setUrl] = useState<string>('')

  useNextRouterEvent(
    'routeChangeStart',
    useCallback((urlArg: string) => {
      setIsNavigating(true)
      setUrl(urlArg)
    }, []),
  )

  useNextRouterEvent(
    'routeChangeComplete',
    useCallback(() => {
      setIsNavigating(false)
      setUrl('')
    }, []),
  )

  useNextRouterEvent(
    'routeChangeError',
    useCallback(() => {
      setIsNavigating(false)
      setUrl('')
    }, []),
  )

  return {
    isNavigating,
    url,
  }
}
