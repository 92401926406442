import { type FC } from 'react'

import { AdServerBanner } from '../AdServerBanner'

export const AdServerTopHeaderBanner: FC = () => (
  <AdServerBanner
    bannerTags={['top-banner']}
    className="ad-server-top-header-banner"
    dimensions={{
      height: 100,
      width: 1920,
    }}
  />
)
