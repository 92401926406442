import { SupportedLanguages, type TenantLanguage } from '../tenant'

/**
 * project assets during deployment will have prefix
 * this func constructs the full url of the asset
 */
export const assetUrlGetPrefixed = (
  assetsPrefix: string,
  path: string,
): string => {
  if (path.startsWith('/')) {
    return `${assetsPrefix}${path}`
  }

  return `${assetsPrefix}/${path}`
}

export const assetsCountryFlagLogoMap = {
  austria: 'austria.svg',
  belgium: 'belgium.svg',
  france: 'france.svg',
  germany: 'germany.svg',
  italy: 'italy.svg',
  switzerland: 'switzerland.svg',
}

export const assetUrlGetCountryFlag = ({
  assetsPrefix,
  country,
}: {
  assetsPrefix: string
  country: keyof typeof assetsCountryFlagLogoMap
}): string => {
  const namePart = assetsCountryFlagLogoMap[country]

  if (!namePart) {
    return ''
  }

  return assetUrlGetPrefixed(assetsPrefix, `/images/flags/flag-${namePart}`)
}

export const assetUrlGetShippingCarrierLogo = ({
  assetsPrefix,
  group,
  language,
}: {
  assetsPrefix: string
  group?: string
  language: TenantLanguage
}): string => {
  const lowerCaseCarrierGroup = group?.toLowerCase()

  /** edgecase for CH FR swisspost, each lang has own logo */
  if (
    lowerCaseCarrierGroup === 'swisspost' &&
    language === SupportedLanguages.FR
  ) {
    return assetUrlGetPrefixed(
      assetsPrefix,
      `/logos/logo-${lowerCaseCarrierGroup}-fr.svg`,
    )
  }

  return assetUrlGetPrefixed(
    assetsPrefix,
    `/logos/logo-${lowerCaseCarrierGroup}.svg`,
  )
}
