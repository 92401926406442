import { clsx } from 'clsx'
import {
  Children,
  cloneElement,
  type ComponentPropsWithoutRef,
  forwardRef,
  type ReactElement,
} from 'react'

import { Accordion, type AccordionProps } from '../Accordion'

const getChildName = (child: ReactElement): string | undefined => {
  if (typeof child.type === 'string') {
    return child.type
  }

  if (
    'displayName' in child.type &&
    typeof child.type.displayName === 'string'
  ) {
    return child.type.displayName
  }

  if ('name' in child.type && typeof child.type.name === 'string') {
    return child.type.name
  }

  console.warn('Component name is not detected', child)

  return undefined
}

const isAccordionChild = (
  child: ReturnType<typeof Children.toArray>[number],
): child is ReactElement<AccordionProps, typeof Accordion> => {
  if (typeof child === 'object' && 'type' in child) {
    if (child.type === Accordion) {
      return true
    }

    if (process.env.NODE_ENV !== 'production') {
      const name = getChildName(child)

      if (name) {
        console.warn(
          `Component "${name}" is not handled by "AccordionStack" and removed from children`,
        )
      }
    }
  }

  return false
}

/**
 * The AccordionStack component manages layout of immediate children along the vertical axe
 */
export const AccordionStack = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function AccordionStack(props, ref) {
  const { children: childrenProp, className, ...rest } = props

  const children = Children.toArray(childrenProp)
    .filter(isAccordionChild)
    .map((child) =>
      cloneElement(child, {
        ...child.props,
        className: clsx(child.props.className, 'accordion-stack__accordion'),
      }),
    )

  return (
    <div {...rest} className={clsx('accordion-stack', className)} ref={ref}>
      {children}
    </div>
  )
})
