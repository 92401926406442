import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
} from 'react'

export type CounterIndicatorProps = Omit<
  ComponentPropsWithoutRef<'span'>,
  'color'
> & {
  /**
   * @default primary
   */
  color?: 'brand' | 'primary' | 'secondary'

  /**
   * @default s
   */
  size?: 's' | 'm'
}

/**
 * Numbered label
 */
export const CounterIndicator = forwardRef(function CounterIndicator(
  props: CounterIndicatorProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  const { className, color = 'primary', size = 's', ...rest } = props

  return (
    <span
      className={clsx(
        'counter-indicator',
        color === 'brand' && 'counter-indicator_color-brand',
        color === 'primary' && 'counter-indicator_color-primary',
        color === 'secondary' && 'counter-indicator_color-secondary',
        size === 's' && 'counter-indicator_size-s',
        size === 'm' && 'counter-indicator_size-m',
        className,
      )}
      ref={ref}
      {...rest}
    />
  )
})
