import { type InputInputSlotProps as InputUnstyledInputSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

export const InputInner = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'> & InputUnstyledInputSlotProps
>(function InputInner(props, ref) {
  const { className, ownerState, ...rest } = props
  const { formControlContext } = ownerState

  return (
    <input
      {...rest}
      className={clsx(
        'input-inner',
        formControlContext && 'input-inner_form-control',
        className,
      )}
      ref={ref}
    />
  )
})
