/**
 * IMPORTANT: `algoliaToken` is set by Algolia itself and not by our code
 */
export const algoliaGetTokenFromLocalStorage = (): string | undefined => {
  try {
    return localStorage.getItem('algoliaToken') ?? undefined
  } catch {
    return undefined
  }
}
