import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useGlobalConfigContext } from '../../../../../../../global-config'
import { urlResolverGetLogout } from '../../../../../../../url-handling'

export const OffCanvasHeaderMenuRootSlideLogoutItem: FC = () => {
  const globalConfig = useGlobalConfigContext()

  return (
    <form action={urlResolverGetLogout(globalConfig)} method="post">
      <button
        className="absolute bottom-0 flex w-full justify-start bg-light-primary-medium py-2.5 pl-3.5 text-s text-dark-primary-max"
        data-qa-id="offcanvas-logout-form-off-canvas-logout"
        type="submit"
      >
        <FormattedMessage id="offcanvas.link.logout" />
      </button>
    </form>
  )
}
