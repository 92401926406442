import { Input as MuiInput, type InputProps as MuiInputProps } from '@mui/base'
import { type ForwardedRef, forwardRef } from 'react'

import { InputInner } from './InputInner'
import { Root } from './Root'
import { TextareaInner } from './TextareaInner'

export { type InputProps } from '@mui/base'

/**
 * Input let users enter and edit text
 */
export const Input = forwardRef(function Input(
  props: MuiInputProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { slots, ...rest } = props

  return (
    <MuiInput
      slots={{
        input: InputInner,
        root: Root,
        textarea: TextareaInner,
        ...slots,
      }}
      {...rest}
      ref={ref}
    />
  )
}) as typeof MuiInput
