import { useDeviceDetectorContext } from '../../device-detector/context/deviceDetectorContext'
import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { DEFAULT_EXPERIMENT } from '../components/experimentComponentCompiler'
import { useExperimentsContext } from '../components/ExperimentsContext'
import {
  type ExperimentType,
  type TenantBasedExperiment,
} from '../model/Experiment.types'
import { experimentAccessorGetExperimentNamePerTenant } from '../model/experimentAccessor'

export const useGetExperimentForTenant = (
  tenantBasedExperiment: TenantBasedExperiment,
): ExperimentType => {
  const { tenant } = useGlobalConfigContext()
  const { experiments } = useExperimentsContext()
  const { isMobile } = useDeviceDetectorContext()

  const experimentName = experimentAccessorGetExperimentNamePerTenant({
    experimentName: tenantBasedExperiment,
    isMobile,
    tenant,
  })

  if (!experimentName) {
    return DEFAULT_EXPERIMENT
  }

  return (
    experiments.find(({ name }) => name === experimentName) ??
    DEFAULT_EXPERIMENT
  )
}
