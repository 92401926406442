import { type SliderThumbSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

export const SliderThumb = forwardRef<HTMLSpanElement, SliderThumbSlotProps>(
  function SliderRail(props, ref) {
    const {
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- variable is not needed for rendering
      ownerState,
      ...rest
    } = props

    return (
      <span className={clsx('slider__thumb', className)} ref={ref} {...rest} />
    )
  },
)
