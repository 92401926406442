import { isServerSide } from '../../../request-context'

const ALGOLIA_SESSION_STORAGE_TRACKING_KEY = 'dataLayerSearch'

/**
 * whenever user click on suggest item, we push to session storage data,
 * and it will be picked up from trackers.
 * https://jira.shop-apotheke.com/browse/WSAWA-2281
 */
export const headerSearchSessionStorageTrackingTrackSuggestItemClick = ({
  qSuggest,
  searchPhrase,
}: {
  qSuggest: number
  searchPhrase: string
}): void => {
  if (isServerSide()) {
    return
  }
  const item = {
    qSuggest,
    searchPhrase,
    searchType: 'Suggest',
  }
  try {
    sessionStorage.setItem(
      ALGOLIA_SESSION_STORAGE_TRACKING_KEY,
      JSON.stringify(item),
    )
  } catch {
    // void
  }
}
